/*--------------------------------------------------------------
  # Custom Cursor
  --------------------------------------------------------------*/
#cursor {
  position: absolute;
  background-color: #351c42;
  height: 10px;
  width: 10px;
  opacity: 0.3;
  border-radius: 50%;
  pointer-events: none;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: width 0.3s, height 0.3s, opacity 0.3s;
  transition: width 0.3s, height 0.3s, opacity 0.3s;
  z-index: 999991;
}

#cursor-border {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: transparent;
  border: 1px solid #351c42;
  border-radius: 50%;
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 999991;
}

/*--------------------------------------------------------------
  # Loader
  --------------------------------------------------------------*/
@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }

  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }

  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
}

.preloader {
  position: fixed;
  background-color: var(--cleenhearts-dark, #24102f);
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9991;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
}

.preloader__image {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 200px auto;
  width: 100%;
  height: 100%;
}

.hover-underline-animation::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #f6d469;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after,
.hover-underline-animation-footer:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.hover-underline-animation-footer::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #351c42;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.main-header__sidebar-btn span {
  border: 1px solid #965995;
  border-radius: 50%;
  background-color: #965995;
  height: 6px;
  width: 6px;
  color: inherit;
  transition: all 0.4s ease-in-out;
}

.main-header__sidebar-btn:hover {
  color: var(--cleenhearts-secondary, #f6d469);
}

.cleenhearts-btn__icon-box {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  text-align: left;
  width: 52px;
  height: 52px;
  border-radius: 100px;
  background-color: var(--cleenhearts-base, #351c42);
  z-index: 1;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.076, 1);
}

.cleenhearts-btn__icon-box__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: transparent;
}

.cleenhearts-btn__icon-box__inner span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 13px;
  color: var(--cleenhearts-white, #fff);
}

.cleenhearts-btn__icon-box__inner .icon-duble-arrow-back {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(55%, 50%);
  rotate: 180deg;
  font-size: 13px;
  color: var(--cleenhearts-white, #fff);
}

.icon-donate::before {
  content: "\e909";
}

.nine-span-btn {
  color: var(--cleenhearts-purple, #965995);
}
.nine-span-btn :hover {
  color: var(--cleenhearts-secondary, #f6d469);
  transition: all 0.4s ease-in-out;
}

/*--------------------------------------------------------------
  # Sidebar
  --------------------------------------------------------------*/
.sidebar-one {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  transform: translateX(-100%);
  transform-origin: left center;
  transition: transform 500ms ease 500ms, visibility 500ms ease 500ms,
    -webkit-transform 500ms ease 500ms;
}

.sidebar-one.active {
  opacity: 1;
  transform: translateX(0%);
  visibility: visible;
  transition: transform 500ms ease 0ms, visibility 500ms ease 0ms,
    -webkit-transform 500ms ease 0ms;
}

.sidebar-one__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: var(--cleenhearts-dark, #24102f);
  opacity: 0.7;
  cursor: url(./components/assets/images/common/close.png), auto;
}

.sidebar-one__content {
  width: 360px;
  background-color: var(--cleenhearts-dark, #24102f);
  z-index: 10;
  position: absolute;
  top: 0;
  right: 15px;
  height: 100%;
  overflow-y: auto;
  padding-top: 45px;
  padding-bottom: 30px;
  padding-right: 35px;
  padding-left: 35px;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-100%);
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms,
    transform 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  /* hide scrollbar but allow scrolling */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.sidebar-one.active .sidebar-one__content {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms,
    transform 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
}

.sidebar-one__content::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}

/*--------------------------------------------------------------
  # Mobile Nav
  --------------------------------------------------------------*/
.mobile-nav__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  transform: translateX(100%);
  transform-origin: right center;
  transition: transform 500ms ease 500ms, visibility 500ms ease 500ms;
  visibility: hidden;
}

.mobile-nav__wrapper.expanded {
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
  transition: transform 500ms ease 0ms, visibility 500ms ease 0ms;
}

.mobile-nav__wrapper.expanded .mobile-nav__content {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms,
    transform 500ms ease 500ms;
}

.mobile-nav__content {
  width: 300px;
  background-color: var(--cleenhearts-dark2, #200c2a);
  z-index: 10;
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 15px;
  padding-left: 25px;
  opacity: 0;
  visibility: hidden;
  transform: translateX(100%);
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms,
    transform 500ms ease 0ms;
}

/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
.footer-widget__title,
.footer-widget__title__Links,
.footer-widget__title__Gallary {
  display: table;
  position: relative;
  font-size: 20px;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-base, #351c42);
  text-transform: capitalize;
  font-weight: 700;
  line-height: 1;
  padding-bottom: 21px;
  margin-top: 15px;
  margin-bottom: 34px;
}

.footer-widget__title::before,
.footer-widget__title::after,
.footer-widget__title__Links::before,
.footer-widget__title__Links::after,
.footer-widget__title__Gallary::before,
.footer-widget__title__Gallary::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: var(--cleenhearts-purple, #965995);
}

.footer-widget__title::before {
  right: 95px;
  width: 32px;
}

.footer-widget__title::after {
  right: 0;
  width: 89px;
}

.footer-widget__title__Links::before {
  right: 83px;
  width: 32px;
}

.footer-widget__title__Links::after {
  right: 0px;
  width: 77px;
}

.footer-widget__title__Gallary::before {
  right: 78px;
  width: 32px;
}

.footer-widget__title__Gallary::after {
  right: 0px;
  width: 70px;
}

.footer-widget__gallery__link::after {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background-color: rgba(var(--cleenhearts-dark2-rgb, 32, 12, 42), 0.7);
  transition: all 700ms ease;
  transform: translateY(-110%);
  border-radius: 10px;
  z-index: 1;
  opacity: 0;
  cursor: auto;
}

.footer-widget__gallery__link:hover::after {
  opacity: 1;
  transform: translateY(0);
}

.footer-widget__gallery__icon {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  color: var(--cleenhearts-white, #fff);
  font-size: 14px;
  z-index: 11;
  transition: all 700ms ease;
  transform: translate(50%, 350%);
}

.footer-widget__gallery__link:hover .footer-widget__gallery__icon {
  transform: translate(50%, -50%);
  font-size: 2rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  /* opacity: 0; */
}

.footer-widget__gallery__link:hover .icon-plus::before {
  content: "+";
  opacity: 1;
  z-index: 1;
}

.main-footer__top__shape-one {
  position: absolute;
  top: 10px;
  left: 10px;
  /* z-index: 1; */
  animation: slowXmove 6s ease infinite;
}

@media (max-width: 1799px) {
  .main-footer__top__shape-one {
    width: 200px;
  }
}

@media (max-width: 1599px) {
  .main-footer__top__shape-one {
    top: -20px;
    left: -20px;
  }
}

.main-footer__top__shape-two {
  position: absolute;
  right: -7px;
  bottom: 0;
  z-index: 1;
  animation: slowXmove 6s ease infinite;
}

@media (max-width: 1650px) {
  .main-footer__top__shape-two {
    width: 300px;
  }
}

@keyframes slowXmove {
  0%,
  100% {
    -webkit-transform: rotate(0deg) translateX(0);
    transform: rotate(0deg) translateX(0);
  }
  25%,
  75% {
    -webkit-transform: rotate(-5deg) translateX(-15px);
    transform: rotate(-5deg) translateX(-15px);
  }
  50% {
    -webkit-transform: rotate(-10deg) translateX(-30px);
    transform: rotate(-10deg) translateX(-30px);
  }
}

.content-text {
  position: absolute;
  top: 30%;
}

.slide-one-subtitle {
  display: inline-block;
  color: var(--cleenhearts-secondary, #f6d469);
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  border: 1px solid rgba(var(--cleenhearts-secondary-rgb, 246, 212, 105), 0.4);
  border-radius: 100px;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  padding: 8.5px 14px;
  margin: 0 0 22px;
  transition: transform 1000ms ease, opacity 1000ms ease;
  transform: translateX(100px);
}
.slide-one-title-text {
  font-size: 3rem;
  font-weight: bolder;
  color: white;
  left: 10%;
}

.button-container {
  opacity: 0;
  transform: translateY(20px);
  animation: popUp 0.5s ease-out forwards 1s;
}

@keyframes popUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.slider-img1 {
  background: url(./components/assets/images/backgrounds/mainSlider/slider-1.jpg);
  border-radius: 2rem;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: auto;
  background-attachment: fixed;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-animation: zoomin 10s ease-in infinite;
  animation: zoomin 10s ease-in infinite;
  transition: all 10s ease-in-out;
  overflow: hidden;
}
.slick-slider:hover .slider-img1 {
  -webkit-animation: none;
  animation: none;
  transition: none;
}

.slider-img2 {
  background: url(./components/assets/images/backgrounds/mainSlider/slider-3.jpg);
  border-radius: 2rem;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: auto;
  background-attachment: fixed;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-animation: zoomin 10s ease-in infinite;
  animation: zoomin 10s ease-in infinite;
  transition: all 10s ease-in-out;
  overflow: hidden;
}

.slick-slider:hover .slider-img2 {
  -webkit-animation: none;
  animation: none;
  transition: none;
}

.slider-img3 {
  background: url(./components/assets/images/backgrounds/mainSlider/slider-2.jpg);
  border-radius: 2rem;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: auto;
  background-attachment: fixed;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-animation: zoomin 10s ease-in infinite;
  animation: zoomin 10s ease-in infinite;
  transition: all 10s ease-in-out;
  overflow: hidden;
}

.slick-slider:hover .slider-img3 {
  -webkit-animation: none;
  animation: none;
  transition: none;
}

@keyframes zoomin {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
} /*End of Zoom in Keyframes */

.slick-list {
  height: 44rem !important;
  border-radius: 2rem;
}

@media screen and (max-width: 768px) and (min-width: 350px) {
  .slick-list {
    height: 600px !important;
  }
}

.details-style {
  display: block;
  height: 68px;
  width: 68px;
  text-align: center;
  margin: 0 auto;
  background: #f5f6f7;
  border: 2px solid var(--cleenhearts-secondary);
  line-height: 65px;
  font-size: 15px;
  border-radius: 50%;
  font-family: "Font Awesome 6 Free" !important;
}

.design-process-content {
  border: 1px solid var(--cleenhearts-base);
  position: relative;
  padding: 30px 30px 30px 30px;
  border-radius: 10px;
}
.design-process-content img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  max-height: 100%;
}
.design-process-content h3 {
  margin-bottom: 16px;
}
.design-process-content p {
  line-height: 26px;
  margin-bottom: 12px;
}

/*.process-model.contact-us-tab li.visited a, .process-model.contact-us-tab li.visited p {
    color: #606060!important;
    font-weight: normal
  }
  .process-model.contact-us-tab li::after  {
    display: none; 
  }
  .process-model.contact-us-tab li.visited i {
    border-color: #e5e5e5; 
  }*/

@media screen and (max-width: 560px) {
  .more-icon-preocess.process-model li span {
    font-size: 23px;
    height: 50px;
    line-height: 46px;
    width: 50px;
  }
  .more-icon-preocess.process-model li::after {
    top: 24px;
  }
}
@media screen and (max-width: 380px) {
  .process-model.more-icon-preocess li {
    width: 16%;
  }
  .more-icon-preocess.process-model li span {
    font-size: 16px;
    height: 35px;
    line-height: 32px;
    width: 35px;
  }
  .more-icon-preocess.process-model li p {
    font-size: 8px;
  }
  .more-icon-preocess.process-model li::after {
    top: 18px;
  }
  .process-model.more-icon-preocess {
    text-align: center;
  }
}

.process-model {
  display: none;
}

.process-model {
  list-style: none;
  padding: 0;
  position: relative;
  max-width: 100%;
  margin: auto;
  border: none;
  z-index: 0;
  display: block;
}

.process-model li::after {
  background: var(--cleenhearts-secondary) none repeat scroll 0 0;
  bottom: 0;
  content: "";
  display: block;
  height: 4px;
  margin: 0 auto;
  position: absolute;
  right: -72px;
  top: 31px;
  width: 90%;
  z-index: -1;
}

@media screen and (max-width: 640px) {
  .process-model li::after {
    right: -35px;
    top: 15px !important;
  }
}

.process-model li.visited::after {
  background: var(--cleenhearts-base);
}

.process-model li:last-child::after {
  width: 0;
}

.process-model li {
  display: inline-block;
  width: 18%;
  text-align: center;
  float: none;
  position: relative;
}

.nav-tabs.process-model > li.active > a,
.nav-tabs.process-model > li.active > a:hover,
.nav-tabs.process-model > li.active > a:focus,
.process-model li a:hover,
.process-model li a:focus {
  border: none;
  background: transparent;
}

.tabs-nav li {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  line-height: 1.75;
}

.process-model li a {
  padding: 0;
  border: none;
  color: #606060;
}

.process-model li.active,
.process-model li.visited {
  color: var(--cleenhearts-base);
}

.process-model li.active a,
.process-model li.active a:hover,
.process-model li.active a:focus,
.process-model li.visited a,
.process-model li.visited a:hover,
.process-model li.visited a:focus {
  color: var(--cleenhearts-base);
}

.process-model li.active p,
.process-model li.visited p {
  color: var(--cleenhearts-base);
  font-weight: 600;
}

.process-model li p {
  display: block;
  height: 68px;
  width: 68px;
  text-align: center;
  margin: 0 auto;
  background: #f5f6f7;
  border: 2px solid var(--cleenhearts-secondary);
  line-height: 65px;
  font-size: 30px !important;
  border-radius: 50%;
}

@media screen and (max-width: 640px) {
  .process-model li p {
    height: 35px;
    width: 35px;
    line-height: 30px;
    font-size: 20px !important;
  }
}

.process-model li.active p,
.process-model li.visited p {
  background: #fff;
  border-color: var(--cleenhearts-base);
}

.process-model li p {
  font-size: 14px;
  margin-top: 11px;
}

.p-dropdown-filter {
  border-radius: 20px !important;
}

.p-inputtext {
  padding: 0 !important;
  font-size: 14px;
  /* width: auto; */
}

.p-dropdown-trigger {
  width: auto;
  margin-left: 8px;
}

.p-autocomplete-input {
  border: none;
  background-color: transparent !important;
  width: 100%;
}

.p-autocomplete-input.p-inputtext.p-component:focus {
  box-shadow: none !important;
  border: none !important;
}

input.p-dropdown-filter.p-inputtext.p-component {
  padding-left: 12px !important;
}

.connected_data .p-inputtext.p-component {
  padding: 6px 8px 6px 45px !important;
  border-radius: 30px !important;
  border: 1px solid #dee2e6 !important;
  min-height: 55px !important;
}

.connected_data,
.dob_calender {
  width: 100%;
}

.dob_calender .p-inputtext.p-component {
  border: 1px solid #dee2e6 !important;
  min-height: 45px !important;
  font-size: 16px !important;
  border-radius: 30px !important;
  min-width: 100%;
  padding: 0 30px 0 60px !important;
}

.dob_calender .p-inputtext.p-component:focus-visible {
  outline: 0 !important;
}

.p-datepicker table td {
  padding: 0 !important;
}

.p-datepicker td > span {
  margin: 0 !important;
}

.connected_data
  button.p-datepicker-trigger.p-button.p-component.p-button-icon-only {
  position: absolute;
  top: 12px;
  left: 5px;
}

.dob_calender
  button.p-datepicker-trigger.p-button.p-component.p-button-icon-only {
  position: absolute;
  top: 8px;
  left: 15px;
  color: #606060;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: var(--cleenhearts-white, #24102f);
  background: var(--cleenhearts-base, #965995);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.3rem 1rem !important;
}

.p-slider .p-slider-range {
  background-color: var(--cleenhearts-purple, #965995);
}

.p-slider .p-slider-handle:focus {
  box-shadow: 0 0 0 0.2rem #965995;
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background-color: #965995;
}
/* multiselector dropdown */
.css-13cymwt-control {
  border-radius: 40px !important;
  padding: 0rem;
  min-height: 45px !important;
  height: auto !important;
  padding-left: 15px;
}

.css-t3ipsp-control {
  border-radius: 40px !important;
  min-height: 45px !important;
  height: auto !important;
  box-shadow: none !important;
  border-color: initial !important;
  padding-left: 15px;
}

.multiselect-custom-css .css-13cymwt-control {
  border-radius: 40px !important;
  padding: 0rem;
  min-height: 30px !important;
  height: auto !important;
  padding-left: 15px;
}

.multiselect-custom-css .css-qbdosj-Input {
  margin: 0 !important;
  padding: 0 !important;
}

.multiselect-custom-css .css-wsp0cs-MultiValueGeneric {
  font-size: 70% !important;
}

.multiselect-custom-css .css-t3ipsp-control {
  border-radius: 40px !important;
  min-height: 30px !important;
  height: auto !important;
  box-shadow: none !important;
  border-color: initial !important;
  padding-left: 15px;
}

/* .css-15lsz6c-indicatorContainer{
    padding-bottom: 20px !important;
  } */
.select__value-container {
  /* margin-bottom: 15px !important; */
  bottom: 15%;
}

.select__indicators {
  position: relative;
  bottom: 15%;
}

/* .css-1xc3v61-indicatorContainer{
    padding-bottom: 20px !important;
  }
  .css-1u9des2-indicatorSeparator{
    margin-bottom: 22px !important;
  } */

.select__input {
  border: none !important;
  --tw-ring-shadow: none !important;
}

.select__input:focus {
  border: none !important;
  --tw-ring-shadow: none !important;
}

.css-1jqq78o-placeholder {
  /* Edge 12 -18 */
  display: none !important;
}

.file-input::-webkit-file-upload-button {
  /* margin-left: 30px; */
  margin-top: 0.6rem;
}

/* Profile page */
@media screen and (max-width: 400px) {
  .need-for-text {
    font-size: 23px !important;
  }
}

.about-drop-down {
  position: relative;
  display: inline-block;
}

.about-drop-down:hover .dropdown-content,
.about-drop-down:hover .user-dropdown-content {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown-content {
  position: absolute;
  padding-top: 21px;
  min-width: 200px;
  z-index: 100;
  background-color: var(--cleenhearts-white, #fff);
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms ease, visibility 500ms ease, transform 300ms ease;
}

.user-dropdown-content {
  position: absolute;
  right: 0;
  top: 51px;
  min-width: 150px;
  z-index: 100;
  background-color: var(--cleenhearts-white, #fff);
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms ease, visibility 500ms ease, transform 300ms ease;
  border: 1px solid lightgray;
  border-radius: 8px;
}

.drop-item {
  font-size: 14px;
  line-height: 26px;
  color: var(--cleenhearts-base, #351c42);
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  font-weight: 700;
  display: flex;
  text-transform: capitalize;
  padding: 8px 20px;
  transition: 400ms;
}

.drop-item:hover {
  background-color: var(--cleenhearts-secondary, #f6d469);
  color: var(--cleenhearts-base, #351c42);
}

/* team carousel */
.our-team-carousel .slick-list,
.statistics-slider .slick-list {
  height: auto !important;
  border-radius: 0;
}

.statistics-slider .slick-prev:before,
.statistics-slider .slick-next:before {
  color: #000 !important;
}

.ngo-chart-slider .slick-list,
.company-chart-slider .slick-list {
  height: 450px !important;
  border-radius: 0 !important;
}

.initiatives-title {
  font-size: 35px !important;
}

.contact-role-dropdown .p-inputtext {
  padding-bottom: 15px !important;
  font-size: 15px;
  font-weight: 300 !important;
}

.contact-role-dropdown .p-inputtext div {
  padding-top: 15px !important;
  color: black;
  font-weight: 400 !important;
}
@media screen and (max-width: 400px) {
  .need-for-text {
    font-size: 23px !important;
  }
}

.slick-track {
  background-color: white;
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: 0 0 0 0.05rem black;
}

.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0.05rem black !important;
}

.p-button:focus {
  box-shadow: 0 0 0 0.05rem black !important;
  border: 1px solid black;
}
.p-datepicker-prev:focus-visible {
  box-shadow: 0 0 0 0.05rem black !important;
  border: 1px solid black;
}
.p-datepicker-month:focus-visible {
  box-shadow: 0 0 0 0.05rem black !important;
  border: 1px solid black;
}
.p-datepicker-year:focus-visible {
  box-shadow: 0 0 0 0.05rem black !important;
  border: 1px solid black;
}
.p-datepicker-next:focus-visible {
  box-shadow: 0 0 0 0.05rem black !important;
  border: 1px solid black;
}

.p-datepicker:not(.p-disabled)
  table
  td
  span:not(.p-highlight):not(.p-disabled):focus {
  box-shadow: 0 0 0 0.05rem black !important;
  border: 1px solid black;
}

@media screen and (min-width: 1200px) {
  .about-akarma-image2 {
    display: none;
  }
}

@media screen and (max-width: 1199px) {
  .about-akarma-image1 {
    display: none;
  }
}

.active-form-tab {
  border: 2px solid var(--cleenhearts-base) !important;
}

.visited-form-tab .process-model li ::after {
  background: var(--cleenhearts-base) none repeat scroll 0 0;
}

.search-container input[type="search"] {
  border: none;
  background-color: #f3eef6;
  margin: 0;
  padding: 7px 8px 7px 30px;
  font-size: 16px;
  color: inherit;
  border-radius: 5px;
}

.search-container input.no-submit {
  width: 100%;
  /* padding: 9px 4px 9px 4px; */
  background-image: url(https://upload.wikimedia.org/wikipedia/commons/5/55/Magnifying_glass_icon.svg);
  background-size: 13px;
  background-repeat: no-repeat;
  background-position: 10px center;
}
.ui-widget-content {
  border: 1px solid #dddddd;
  background: #ffffff;
  color: #333333;
}
.ui-menu-item {
  color: rgba(var(--cleenhearts-dark2-rgb));
}
.ui-menu-item:hover {
  background-color: #351c42;
  color: white;
}

/* Prme Data Table */
.p-datatable .p-datatable-header {
  background-color: transparent;
  padding: 0.5rem 0.5rem;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td {
  padding: 5px 15px;
  text-wrap: nowrap;
}

.p-datatable-thead > tr {
  background-color: white;
}

.p-datatable .p-datatable-tbody > tr {
  background-color: transparent;
}

.p-component {
  font-size: 14px;
}

/* tr:nth-child(even) {
  background-color: #ffffff !important;
}

tr:nth-child(odd) {
  background-color: #f2f2f2 !important;
} */
/* Prme Data Table */

.wrapper {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  padding: 5px;
  height: 50px;
}

.tooltip-none-div .react-tooltip {
  z-index: 100 !important;
}

.tooltip-div .react-tooltip {
  z-index: 100 !important;
  width: 25rem !important;
  font-size: 10px;
}

.tooltip-entity-name-div .react-tooltip {
  z-index: 999 !important;
  width: 20rem !important;
  font-size: 10px;
}

.tooltip-auth .react-tooltip {
  z-index: 100 !important;
}

/* loader */
.loader-parent {
  height: calc(100vh - 62px);
  width: 100vw;
  background-color: rgb(36, 16, 47, 0.3);
  position: absolute;
  z-index: 1;
}

.loader {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 40%;
  border: 5px solid;
  border-color: #965995 #965995 transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 5px solid;
  border-color: transparent transparent #351c42 #351c42;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.55s linear infinite;
  transform-origin: center center;
}

.loader::before {
  width: 50px;
  height: 50px;
  border: 5px solid;
  border-color: #f6d469 #f6d469 transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

/* loader-textbox */
.loader-textbox {
  width: 60px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side, #351c42 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 100%;
  animation: l7 1s infinite linear;
}

@keyframes l7 {
  33% {
    background-size: calc(100% / 3) 0%, calc(100% / 3) 100%, calc(100% / 3) 100%;
  }

  50% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 0%, calc(100% / 3) 100%;
  }

  66% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 100%, calc(100% / 3) 0%;
  }
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  margin-right: 4px !important;
  padding: 0 !important;
  color: #6b7280 !important;
}

.css-hyxlzm svg {
  height: 20px !important;
  width: 20px !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked,
.Mui-checked.MuiRadio-root {
  border-color: var(--cleenhearts-base) !important;
  color: var(--cleenhearts-base) !important;
  --tw-ring-color: var(--cleenhearts-base) !important;
}

/* blinking div */
@keyframes blink {
  50% {
    /* opacity: 0; */
    /* border: 1px solid #351c42; */
    /* box-shadow: 10px 10px; */
  }
}

.blinking {
  animation: blink 1s step-start infinite;
}

.MuiSlider-root {
  color: #351c42 !important;
}

.MuiSlider-thumb {
  height: 15px !important;
  width: 15px !important;
}

.multiselect-custom-css .select__menu {
  font-size: 14px !important;
}

/* hide two arrows in number type input */
/* Assuming you have a class no-arrows-input */
.no-arrows-input::-webkit-inner-spin-button,
.no-arrows-input::-webkit-outer-spin-button {
  /* -webkit-appearance: none; */
  margin: 0;
}

.no-arrows-input {
  /* -moz-appearance: textfield; */
}

/* Additional styles if needed */
.no-arrows-input {
  /* Your custom styles */
}

.dropdown-arrow-cutome-css .p-dropdown-trigger svg {
  width: 10px;
  height: 10px;
}

.p-autocomplete-item.p-highlight {
  background-color: #351c42 !important;
  color: white !important;
}

.sort-by-custom-css .p-dropdown-item {
  background-color: #351c42;
  color: white;
}

.p-datatable .p-sortable-column.p-highlight {
  background-color: #f3f4f6;
  color: #374151;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #351c42;
}

/* input::-ms-reveal,
input::-ms-clear {
  display: none;
} */

.form-floating input[type="number"]::-webkit-inner-spin-button,
.form-floating input[type="number"]::-webkit-outer-spin-button,
.number-input input[type="number"]::-webkit-inner-spin-button,
.number-input input[type="number"]::-webkit-outer-spin-button {
  display: none;
}

.event-details__content .slick-list {
  height: 100% !important;
  /* width: 70%;
  margin: 0 auto; */
}

.ytp-cued-thumbnail-overlay-image {
  background-size: contain !important;
  -webkit-background-size: contain !important;
}

/* crop image */
.crop-container {
  position: relative;
  width: 236px;
  height: 236px;

  background: linear-gradient(to right, #cbd4e1 8px, transparent 8px) 0 0,
    linear-gradient(to right, #cbd4e1 8px, transparent 8px) 0 100%,
    linear-gradient(to left, #cbd4e1 8px, transparent 8px) 100% 0,
    linear-gradient(to left, #cbd4e1 8px, transparent 8px) 100% 100%,
    linear-gradient(to bottom, #cbd4e1 8px, transparent 8px) 0 0,
    linear-gradient(to bottom, #cbd4e1 8px, transparent 8px) 100% 0,
    linear-gradient(to top, #cbd4e1 8px, transparent 8px) 0 100%,
    linear-gradient(to top, #cbd4e1 8px, transparent 8px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 70px 70px;
}
.reactEasyCrop_CropArea {
  color: rgba(255, 255, 255, 0.8) !important;
}

.box-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 5;
  border: 0;
  border-radius: 50%;
  box-sizing: border-box;
  box-shadow: 1px 1px 18px 1px #ddd;
  padding: 1em 2em;
  width: 300px !important;
  height: 300px !important;
  color: #fff;
  font-size: inherit;
  font-weight: 700;
  position: relative;
  vertical-align: middle;
  background: #351c42 url(./components/assets/images/backgrounds/basketball.png);
  animation: animate 30s linear infinite;
}

@media (max-width: 767px) {
  .box-2 {
    height: 200px !important;
    width: 200px !important;
  }
}

@keyframes animate {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 -196px;
  }
}

/* chart */
.chart-custome-css .dxc-legend {
  display: none;
}
