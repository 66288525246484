@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --cleenhearts-font: "DM Sans", sans-serif;
  --cleenhearts-heading-font: "Quicksand", sans-serif;
  --cleenhearts-special-font: "Schoolbell", cursive;
  --cleenhearts-text: #757277;
  --cleenhearts-text-rgb: 117, 114, 119;
  --cleenhearts-text-gray: #aa96b4;
  --cleenhearts-text-gray-rgb: 170, 150, 180;
  --cleenhearts-text-gray2: #888888;
  --cleenhearts-text-gray2-rgb: 136, 136, 136;
  --cleenhearts-base: #351c42;
  --cleenhearts-base-rgb: 53, 28, 66;
  --cleenhearts-secondary: #f6d469;
  --cleenhearts-secondary-rgb: 246, 212, 105;
  --cleenhearts-white: #fff;
  --cleenhearts-white-rgb: 255, 255, 255;
  --cleenhearts-white2: #efece7;
  --cleenhearts-white2-rgb: 239, 236, 231;
  --cleenhearts-white3: #d7d3cb;
  --cleenhearts-white3-rgb: 215, 211, 203;
  --cleenhearts-white4: #fffaea;
  --cleenhearts-white4-rgb: 255, 250, 234;
  --cleenhearts-purple: #965995;
  --cleenhearts-purple-rgb: 150, 89, 149;
  --cleenhearts-gray: #888888;
  --cleenhearts-gray-rgb: 136, 136, 136;
  --cleenhearts-black: #000000;
  --cleenhearts-black-rgb: 0, 0, 0;
  --cleenhearts-dark: #24102f;
  --cleenhearts-dark-rgb: 36, 16, 47;
  --cleenhearts-dark2: #200c2a;
  --cleenhearts-dark2-rgb: 32, 12, 42;
  --cleenhearts-dark3: #200c2b;
  --cleenhearts-dark3-rgb: 32, 12, 43;
  --cleenhearts-dark4: #414141;
  --cleenhearts-dark4-rgb: 65, 65, 65;
  --cleenhearts-orange: #e76100;
  --cleenhearts-orange-rgb: 231, 97, 0;
  --cleenhearts-fuchsia: #ff1c74;
  --cleenhearts-fuchsia-rgb: 255, 28, 116;
  --cleenhearts-blue: #6c1cd1;
  --cleenhearts-blue-rgb: 108, 28, 209;
  --cleenhearts-green: #59c36a;
  --cleenhearts-green-rgb: 89, 195, 106;
  --cleenhearts-yellow: #fcb720;
  --cleenhearts-yellow-rgb: 252, 183, 32;
  --cleenhearts-border-color: #d7d3cb;
  --cleenhearts-border-color-rgb: 215, 211, 203;
  --cleenhearts-border-color2: #414141;
  --cleenhearts-border-color2-rgb: 65, 65, 65;
  --cleenhearts-letter-space: -0.72px;
  --cleenhearts-letter-space-xl: -1.5px;
}
