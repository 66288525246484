@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@500&display=swap");
@import url(https://fonts.googleapis.com/css?family=Quicksand:200,300,400,600,700,800,900);

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Utility
# Cards
# Common
# Form
# Navigations
# Animations
# Mobile Nav
# Search Popup
# Page Header
# Google Map
# Client Carousel
--------------------------------------------------------------*/
:root {
  --cleenhearts-font: "DM Sans", sans-serif;
  --cleenhearts-heading-font: "Quicksand", sans-serif;
  --cleenhearts-special-font: "Schoolbell", cursive;
  --cleenhearts-text: #757277;
  --cleenhearts-text-rgb: 117, 114, 119;
  --cleenhearts-text-gray: #aa96b4;
  --cleenhearts-text-gray-rgb: 170, 150, 180;
  --cleenhearts-text-gray2: #888888;
  --cleenhearts-text-gray2-rgb: 136, 136, 136;
  --cleenhearts-base: #351c42;
  --cleenhearts-base-rgb: 53, 28, 66;
  --cleenhearts-secondary: #f6d469;
  --cleenhearts-secondary-rgb: 246, 212, 105;
  --cleenhearts-white: #fff;
  --cleenhearts-white-rgb: 255, 255, 255;
  --cleenhearts-white2: #efece7;
  --cleenhearts-white2-rgb: 239, 236, 231;
  --cleenhearts-white3: #d7d3cb;
  --cleenhearts-white3-rgb: 215, 211, 203;
  --cleenhearts-white4: #fffaea;
  --cleenhearts-white4-rgb: 255, 250, 234;
  --cleenhearts-purple: #965995;
  --cleenhearts-purple-rgb: 150, 89, 149;
  --cleenhearts-gray: #888888;
  --cleenhearts-gray-rgb: 136, 136, 136;
  --cleenhearts-black: #000000;
  --cleenhearts-black-rgb: 0, 0, 0;
  --cleenhearts-dark: #24102f;
  --cleenhearts-dark-rgb: 36, 16, 47;
  --cleenhearts-dark2: #200c2a;
  --cleenhearts-dark2-rgb: 32, 12, 42;
  --cleenhearts-dark3: #200c2b;
  --cleenhearts-dark3-rgb: 32, 12, 43;
  --cleenhearts-dark4: #414141;
  --cleenhearts-dark4-rgb: 65, 65, 65;
  --cleenhearts-orange: #e76100;
  --cleenhearts-orange-rgb: 231, 97, 0;
  --cleenhearts-fuchsia: #ff1c74;
  --cleenhearts-fuchsia-rgb: 255, 28, 116;
  --cleenhearts-blue: #6c1cd1;
  --cleenhearts-blue-rgb: 108, 28, 209;
  --cleenhearts-green: #59c36a;
  --cleenhearts-green-rgb: 89, 195, 106;
  --cleenhearts-yellow: #fcb720;
  --cleenhearts-yellow-rgb: 252, 183, 32;
  --cleenhearts-border-color: #d7d3cb;
  --cleenhearts-border-color-rgb: 215, 211, 203;
  --cleenhearts-border-color2: #414141;
  --cleenhearts-border-color2-rgb: 65, 65, 65;
  --cleenhearts-letter-space: -0.72px;
  --cleenhearts-letter-space-xl: -1.5px;
}

/*--------------------------------------------------------------
# Common
--------------------------------------------------------------*/
body {
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  font-size: 16px;
  line-height: 1.75;
  font-weight: 400;
}

body.locked {
  overflow: hidden;
}

a {
  color: var(--cleenhearts-base, #351c42);
  transition: all 400ms ease;
}

a,
a:hover,
a:focus,
a:visited {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  color: var(--cleenhearts-base, #351c42);
  font-weight: 700;
}
@media (max-width: 575px) {
  h1 br,
  h2 br,
  h3 br,
  h4 br,
  h5 br,
  h6 br {
    display: none;
  }
}

p {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  color: var(--cleenhearts-text, #757277);
  font-weight: 400;
  line-height: 1.75;
}
@media (max-width: 575px) {
  p br {
    display: none;
  }
}

::placeholder {
  color: inherit;
  opacity: 1;
}

input,
textarea,
input::placeholder,
textarea::placeholder {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  color: var(--cleenhearts-text, #757277);
  line-height: 1;
}

input::placeholder,
textarea::placeholder {
  text-transform: capitalize;
}

input,
textarea {
  color: var(--cleenhearts-base, #351c42);
}

.section-space {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media (max-width: 767px) {
  .section-space {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}
@media (max-width: 575px) {
  .section-space {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.section-space-top {
  padding-top: 130px;
}
@media (max-width: 767px) {
  .section-space-top {
    padding-top: 110px;
  }
}
@media (max-width: 575px) {
  .section-space-top {
    padding-top: 80px;
  }
}

.section-space-bottom {
  padding-bottom: 130px;
}
@media (max-width: 767px) {
  .section-space-bottom {
    padding-bottom: 110px;
  }
}
@media (max-width: 575px) {
  .section-space-bottom {
    padding-bottom: 80px;
  }
}

.background-base {
  background-color: var(--cleenhearts-base, #351c42);
}

.background-gray {
  background-color: var(--cleenhearts-gray, #888888);
}

.background-white {
  background-color: var(--cleenhearts-white, #fff);
}

.background-white2 {
  background-color: var(--cleenhearts-white2, #efece7);
}

.background-white3 {
  background-color: var(--cleenhearts-white3, #d7d3cb);
}

.background-dark {
  background-color: var(--cleenhearts-dark, #24102f);
}

.background-dark-2 {
  background-color: var(--cleenhearts-dark2, #200c2a);
}

.background-dark-3 {
  background-color: var(--cleenhearts-dark3, #200c2b);
}

.cleenhearts-dark {
  color: var(--cleenhearts-dark, #24102f);
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  overflow: hidden;
}

.container-fluid,
.container {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
.row {
  --bs-gutter-x: 30px;
}

.gutter-y-10 {
  --bs-gutter-y: 10px;
}

.gutter-y-15 {
  --bs-gutter-y: 15px;
}

.gutter-y-17 {
  --bs-gutter-y: 17px;
}

.gutter-y-20 {
  --bs-gutter-y: 20px;
}

.gutter-y-25 {
  --bs-gutter-y: 25px;
}

.gutter-y-30 {
  --bs-gutter-y: 30px;
}

.gutter-y-40 {
  --bs-gutter-y: 40px;
}

.gutter-y-50 {
  --bs-gutter-y: 50px;
}

.gutter-y-60 {
  --bs-gutter-y: 60px;
}

.cleenhearts-btn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  appearance: none;
  border: none;
  outline: none;
  background-color: var(--cleenhearts-secondary, #f6d469);
  padding: 16px 22px 16px 19px;
  transition: 500ms;
  border-radius: 100px;
  min-height: 54px;
}

.cleenhearts-btn__icon-box {
  position: relative;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  text-align: left;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: var(--cleenhearts-base, #351c42);
  z-index: 1;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.076, 1);
}
.cleenhearts-btn__icon-box__inner {
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: transparent;
}
.cleenhearts-btn__icon-box__inner span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 13px;
  color: var(--cleenhearts-white, #fff);
}
.cleenhearts-btn__icon-box__inner span.icon-trolley {
  font-size: 16px;
  color: var(--cleenhearts-base, #351c42);
}
.cleenhearts-btn__icon-box__inner span.icon-heart {
  font-size: 16px;
}
.cleenhearts-btn__icon-box__inner span.icon-donate {
  font-size: 18px;
}
.cleenhearts-btn__text {
  position: relative;
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  font-size: 16px;
  color: inherit;
  margin-left: 40px;
  font-weight: 700;
  line-height: 1;
  text-transform: capitalize;
  z-index: 11;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 412px) {
  .cleenhearts-btn__text {
    font-size: 14px;
  }
}
.cleenhearts-btn:hover .cleenhearts-btn__icon-box {
  width: calc(100% - 18px);
}
.cleenhearts-btn:hover .cleenhearts-btn__text {
  color: var(--cleenhearts-white, #fff);
}
.cleenhearts-btn--white {
  background-color: var(--cleenhearts-white2, #efece7);
}
.cleenhearts-btn--white .cleenhearts-btn__icon-box {
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.cleenhearts-btn--white .cleenhearts-btn__icon-box__inner span {
  color: var(--cleenhearts-base, #351c42);
}
.cleenhearts-btn--white:hover .cleenhearts-btn__text {
  color: var(--cleenhearts-base, #351c42);
}
.cleenhearts-btn--border {
  background-color: transparent;
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
.cleenhearts-btn--border .cleenhearts-btn__icon-box {
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.cleenhearts-btn--border .cleenhearts-btn__icon-box__inner span {
  color: var(--cleenhearts-base, #351c42);
}
.cleenhearts-btn--border .cleenhearts-btn__text {
  color: var(--cleenhearts-base, #351c42);
}
.cleenhearts-btn--border:hover .cleenhearts-btn__text {
  color: var(--cleenhearts-base, #351c42);
}
.cleenhearts-btn--border-base {
  background-color: transparent;
  border: 1px solid var(--cleenhearts-base, #351c42);
}
.cleenhearts-btn--border-base .cleenhearts-btn__icon-box {
  background-color: var(--cleenhearts-base, #351c42);
}

.volunteer-profile {
  margin-top: 54px;
  display: flex;
  align-items: center;
  gap: 30px 0;
}
@media (max-width: 575px) {
  .volunteer-profile {
    flex-direction: column;
    align-items: flex-start;
  }
}
.volunteer-profile__inner {
  display: flex;
  align-items: center;
  gap: 21px;
  border-right: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  padding-right: 34px;
}
@media (max-width: 575px) {
  .volunteer-profile__inner {
    padding-right: 0;
    border: 0;
  }
}
.volunteer-profile__image {
  width: 82px;
  height: 82px;
  border-radius: 50%;
}
.volunteer-profile__name {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  color: var(--cleenhearts-base, #351c42);
  font-weight: 500;
  line-height: 1.75;
  margin-bottom: 5px;
  text-transform: capitalize;
}
.volunteer-profile__name a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.volunteer-profile__name a:hover {
  background-size: 100% 1px;
}
.volunteer-profile__designation {
  font-size: 14px;
  color: var(--cleenhearts-purple, #965995);
  font-weight: 500;
  line-height: 1.142;
  margin-bottom: 5px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.volunteer-profile__signature {
  margin-left: 34px;
}
@media (max-width: 575px) {
  .volunteer-profile__signature {
    margin-left: 0;
  }
}

.progress-box__title {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-base, #351c42);
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 19px;
}
.progress-box__bar {
  width: 100%;
  height: 7px;
  border: 1px solid var(--cleenhearts-purple, #965995);
  border-radius: 10px;
  position: relative;
}
.progress-box__bar__inner {
  position: relative;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  background-color: var(--cleenhearts-purple, #965995);
  transition: all 1500ms linear;
  border-radius: 10px;
  width: 0px;
  z-index: 1;
}
.progress-box__bar__inner::before {
  content: "";
  position: absolute;
  top: 50%;
  right: -1px;
  transform: translateY(-50%);
  background-color: var(--cleenhearts-purple, #965995);
  border: 0;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  z-index: 11;
}
.progress-box__bar__inner::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: var(--cleenhearts-white, #fff);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  z-index: 11;
}
.progress-box__number {
  position: absolute;
  bottom: calc(100% + 20px);
  right: 0;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 12px;
  font-weight: 700;
  line-height: 1.166;
  text-transform: capitalize;
  color: var(--cleenhearts-purple, #965995);
}

.contact-information {
  display: flex;
  align-items: center;
  gap: 30px 0;
}
@media (max-width: 575px) {
  .contact-information {
    flex-direction: column;
    align-items: flex-start;
  }
}
.contact-information__btn {
  position: relative;
  margin-right: 21px;
}
.contact-information__btn::after {
  content: "";
  width: 1px;
  height: calc(100% - 6px);
  background-color: var(--cleenhearts-white2, #efece7);
  position: absolute;
  top: 50%;
  right: -21px;
  transform: translateY(-50%);
}
@media (max-width: 575px) {
  .contact-information__btn {
    margin-right: 0;
  }
  .contact-information__btn::after {
    display: none;
  }
}
.contact-information__phone {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 21px;
}
@media (max-width: 575px) {
  .contact-information__phone {
    padding-left: 0;
    border: 0;
  }
}
.contact-information__phone__icon {
  position: relative;
  top: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 1px solid var(--cleenhearts-purple, #965995);
  z-index: 1;
}
.contact-information__phone__icon::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform: scale(0);
  opacity: 0;
  transition: transform 300ms ease, opacity 300ms ease;
  background-color: var(--cleenhearts-purple, #965995);
}
.contact-information__phone__icon span {
  font-size: 20px;
  color: var(--cleenhearts-purple, #965995);
}
.contact-information__phone:hover .contact-information__phone__icon span {
  color: var(--cleenhearts-white, #fff);
}
.contact-information__phone:hover .contact-information__phone__icon::after {
  opacity: 1;
  transform: scale(1);
}
.contact-information__phone__text {
  position: relative;
  top: -2px;
}
.contact-information__phone__text span {
  font-size: 16px;
  line-height: 1;
  text-transform: capitalize;
}
.contact-information__phone__text h5 {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  line-height: 1;
  text-transform: capitalize;
  margin-top: 6px;
  margin-bottom: 0;
}
.contact-information__phone__text h5:hover {
  color: var(--cleenhearts-purple, #965995);
}
.contact-information__phone__text h5 a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.contact-information__phone__text h5 a:hover {
  background-size: 100% 1px;
}

.video-button {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  background-color: var(--cleenhearts-base, #351c42);
  border-radius: 50%;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}
.video-button span {
  transition: all 0.4s ease-in-out;
  font-size: 14px;
  color: var(--cleenhearts-white, #fff);
}
.video-button:hover {
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.video-button:hover span {
  color: var(--cleenhearts-base, #351c42);
}
.video-button__ripple::before,
.video-button__ripple::after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 56px;
  height: 56px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 0 0 0 rgba(var(--cleenhearts-base-rgb, 53, 28, 66), 0.6);
  -webkit-animation: productVideo 3s infinite;
  animation: productVideo 3s infinite;
  border-radius: 50%;
}
.video-button__ripple::before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}
.video-button__ripple::after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}
@keyframes productVideo {
  70% {
    box-shadow: 0 0 0 40px rgba(10, 165, 205, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(10, 165, 205, 0);
  }
}

.custom-radio__input {
  display: none;
}
.custom-radio__title {
  position: relative;
  cursor: pointer;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-base, #351c42);
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.custom-radio__title::before {
  content: "";
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: -5px 16px;
  margin-left: 0;
  border-radius: 50%;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.custom-radio__title::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #aa96b4;
  left: 7px;
  top: 13px;
  margin: -8px 20px;
  margin-left: 0;
  border-radius: 50%;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}
.custom-radio__input:checked + .custom-radio__title::before {
  border-color: var(--cleenhearts-purple, #965995);
}
.custom-radio__input:checked + .custom-radio__title::after {
  background-color: var(--cleenhearts-purple, #965995);
}
.custom-radio__input:checked + .custom-radio__title {
  text-shadow: 0 0 0.5px currentColor;
}

.tabs-box .tabs-content .tab:not(.active-tab) {
  display: none;
}

.bootstrap-select .dropdown-menu {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0;
}
.bootstrap-select .dropdown-item.active,
.bootstrap-select .dropdown-item:active {
  background-color: var(--cleenhearts-base, #351c42);
}

.tns-outer .tns-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.tns-outer .tns-controls button {
  width: 45px;
  height: 45px;
  border: 2px solid #f4f4f4;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--cleenhearts-text, #757277);
  border-radius: 50%;
  margin-left: 5px;
  margin-right: 5px;
}

.block-title {
  margin-top: -8px;
  margin-bottom: 50px;
}
.block-title__decor {
  width: 21px;
  height: 14px;
  background-repeat: no-repeat;
  background-position: top center;
  display: inline-block;
  line-height: 1;
  margin-bottom: -5px;
  position: relative;
  top: -7px;
}
.block-title p {
  margin: 0;
  color: var(--cleenhearts-text, #757277);
  font-size: 16px;
  line-height: 1;
  margin-bottom: 7px;
}
@media (min-width: 768px) {
  .block-title p {
    font-size: 18px;
  }
}
@media (min-width: 992px) {
  .block-title p {
    font-size: 20px;
  }
}
.block-title h3 {
  margin: 0;
  font-size: 35px;
  color: var(--cleenhearts-dark, #24102f);
  font-family: var(--cleenhearts-special-font, "Schoolbell", cursive);
}
@media (min-width: 768px) {
  .block-title h3 {
    font-size: 42px;
  }
}
@media (min-width: 992px) {
  .block-title h3 {
    font-size: 50px;
  }
}

.ul-list-one {
  margin-bottom: 0;
}
.ul-list-one li {
  position: relative;
  padding-left: 45px;
  font-size: 16px;
  font-weight: 500;
  color: var(--cleenhearts-dark, #24102f);
}
@media (min-width: 481px) {
  .ul-list-one li {
    font-size: 20px;
  }
}
.ul-list-one li::before {
  content: "\e907";
  color: var(--cleenhearts-base, #351c42);
  font-size: 26px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-family: "azino-icon";
}

.preloader {
  position: fixed;
  background-color: rgb(36, 16, 47);
  background-position: center center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9991;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
}
.preloader__image {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 200px auto;
  width: 100%;
  height: 100%;
}

/* scroll to top */
.scroll-to-top {
  display: flex;
  align-items: center;
  width: auto;
  height: 35px;
  background: transparent;
  position: fixed;
  bottom: 60px;
  right: -12px;
  z-index: 99;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transform: rotate(-90deg);
  cursor: pointer;
  transition: all 0.2s ease;
}
.scroll-to-top__text {
  display: inline;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--cleenhearts-base, #351c42);
  letter-spacing: 0.1em;
  font-weight: 700;
  margin-right: 8px;
}
.scroll-to-top__wrapper {
  display: inline-block;
  width: 30px;
  height: 4px;
  background-color: var(--cleenhearts-base, #351c42);
  position: relative;
  overflow: hidden;
}
.scroll-to-top__inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.scroll-to-top.show {
  opacity: 1;
  visibility: visible;
  bottom: 70px;
}

/* social links */
.social-link {
  display: flex;
  align-items: center;
  gap: 20px 32px;
}
.social-link a {
  position: relative;
  font-size: 16px;
  color: var(--cleenhearts-text, #757277);
  transition: all 500ms ease;
  line-height: 1;
}
.social-link a:hover {
  color: var(--cleenhearts-base, #351c42);
}
.social-link a + a::after {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -16px;
  background-color: var(--cleenhearts-white3, #d7d3cb);
}

.social-link-two {
  display: flex;
  align-items: center;
  gap: 7px;
}
.social-link-two a {
  font-size: 14px;
  color: var(--cleenhearts-base, #351c42);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid var(--cleenhearts-base, #351c42);
  transition: all 400ms ease;
}
.social-link-two a:hover {
  color: var(--cleenhearts-white, #fff);
  background-color: var(--cleenhearts-purple, #965995);
  border-color: transparent;
}

.person-social {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}
.person-social li a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid var(--cleenhearts-base, #351c42);
  font-size: 14px;
  color: var(--cleenhearts-base, #351c42);
  z-index: 1;
}
.person-social li a::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform: scale(0);
  opacity: 0;
  transition: transform 300ms ease, opacity 300ms ease;
  background-color: var(--cleenhearts-base, #351c42);
}
.person-social li a:hover {
  color: var(--cleenhearts-secondary, #f6d469);
}
.person-social li a:hover::after {
  opacity: 1;
  transform: scale(1);
}
.person-social li a span {
  color: inherit;
}

/* post paginations */
.post-pagination {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 11px;
}
.post-pagination a {
  display: flex;
  width: 40px;
  height: 40px;
  background-color: var(--cleenhearts-white2, #efece7);
  align-items: center;
  justify-content: center;
  color: var(--cleenhearts-text, #757277);
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 700;
  border-radius: 50%;
  line-height: 1;
  border: 1px solid transparent;
  transition: 500ms ease;
}
.post-pagination a span {
  font-size: 14px;
  color: var(--cleenhearts-base, #351c42);
}
.post-pagination a:hover {
  border-color: var(--cleenhearts-purple, #965995);
  color: var(--cleenhearts-purple, #965995);
  background-color: transparent;
}
.post-pagination a:hover span {
  color: var(--cleenhearts-purple, #965995);
}
.post-pagination li:last-child a {
  background-color: var(--cleenhearts-purple, #965995);
  color: var(--cleenhearts-white, #fff);
}
.post-pagination li:last-child a span {
  color: var(--cleenhearts-white, #fff);
}

.cleenhearts-owl__carousel--with-shadow .owl-stage-outer {
  overflow: visible;
}
.cleenhearts-owl__carousel--with-shadow .owl-item {
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms ease, visibility 500ms ease;
}
.cleenhearts-owl__carousel--with-shadow .owl-item.active {
  opacity: 1;
  visibility: visible;
}

.cleenhearts-owl__carousel--basic-nav .owl-nav {
  position: relative;
  display: flex;
  justify-content: center;
  gap: 0px;
  margin-top: 35px;
}
.cleenhearts-owl__carousel--basic-nav .owl-nav::before,
.cleenhearts-owl__carousel--basic-nav .owl-nav::after {
  content: "";
  position: absolute;
  top: 50%;
  width: calc(50% - 75px);
  height: 1px;
  background-color: var(--cleenhearts-border-color, #d7d3cb);
}
.cleenhearts-owl__carousel--basic-nav .owl-nav::before {
  left: 0;
}
.cleenhearts-owl__carousel--basic-nav .owl-nav::after {
  right: 0;
}
.cleenhearts-owl__carousel--basic-nav .owl-nav button:hover {
  background-color: transparent !important;
}
.cleenhearts-owl__carousel--basic-nav .owl-nav button span {
  border: 0;
  outline: 0;
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: var(--cleenhearts-white2, #efece7);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--cleenhearts-text, #757277);
  border-radius: 50%;
  font-size: 15px;
  color: var(--cleenhearts-base, #351c42);
  transition: all 500ms ease;
}
.cleenhearts-owl__carousel--basic-nav .owl-nav button span:hover {
  background-color: var(--cleenhearts-purple, #965995);
  color: var(--cleenhearts-white, #fff);
}
.cleenhearts-owl__carousel--basic-nav .owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  margin-top: 60px;
}
.cleenhearts-owl__carousel--basic-nav .owl-dots .owl-dot {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  transition: all 0.4s ease-in-out;
}
.cleenhearts-owl__carousel--basic-nav .owl-dots .owl-dot::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid var(--cleenhearts-purple, #965995);
  opacity: 0;
  transition: all 0.4s ease-in-out;
}
.cleenhearts-owl__carousel--basic-nav .owl-dots .owl-dot span {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #dcdcdc;
  margin: 0;
  transition: all 0.4s ease-in-out;
}
.cleenhearts-owl__carousel--basic-nav .owl-dots .owl-dot:hover span {
  background-color: var(--cleenhearts-purple, #965995);
}
.cleenhearts-owl__carousel--basic-nav .owl-dots .owl-dot.active::after {
  opacity: 1;
}
.cleenhearts-owl__carousel--basic-nav .owl-dots .owl-dot.active span {
  background-color: var(--cleenhearts-purple, #965995);
}
.cleenhearts-owl__carousel--basic-nav .owl-nav.disabled + .owl-dots {
  margin-top: 60px;
}

.sec-title {
  margin-bottom: 24px;
}
.sec-title__tagline {
  display: table;
  position: relative;
  margin: 0;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-purple, #965995);
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 21px;
  margin-left: 24px;
}
.sec-title__tagline::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -24px;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--cleenhearts-purple, #965995);
  z-index: 1;
}
.sec-title__tagline::after {
  content: "";
  position: absolute;
  top: 50%;
  left: -18px;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  border-radius: 50px;
  border: 1px solid var(--cleenhearts-purple, #965995);
  z-index: 1;
}
.sec-title__tagline--center {
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
}
.sec-title__tagline--center::before {
  left: 0;
}
.sec-title__tagline--center::after {
  left: 5.5px;
}
.sec-title__title {
  position: relative;
  z-index: 2;
  margin: 0;
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  font-size: 50px;
  color: var(--cleenhearts-base, #351c42);
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: var(--cleenhearts-letter-space-xl, -1.5px);
}
@media (max-width: 1199px) {
  .sec-title__title {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .sec-title__title {
    font-size: 40px;
  }
}
@media (max-width: 575px) {
  .sec-title__title {
    font-size: 35px;
  }
}
@media (max-width: 425px) {
  .sec-title__title {
    font-size: 33px;
  }
}
.sec-title__title__inner {
  position: relative;
}
.sec-title__title__inner::after {
  content: "";
  background-image: url(../images/common/span_underline.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 22.3px;
  position: absolute;
  left: 2px;
  bottom: -8px;
  width: 100%;
  height: 22.3px;
  z-index: -1;
  animation: smartMove 5s infinite;
}

.volunteer-group {
  display: flex;
  align-items: center;
}
.volunteer-group__link {
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 50%;
}
.volunteer-group__link + .volunteer-group__link {
  margin-left: -16px;
}
.volunteer-group__image {
  width: 50px;
  height: 50px;
  border: 3px solid var(--cleenhearts-white, #fff);
  border-radius: 50%;
}

.cleenhearts-ratings {
  display: flex;
  align-items: center;
  gap: 10px;
}
.cleenhearts-ratings span {
  font-size: 15px;
  color: var(--cleenhearts-orange, #e76100);
}

.ui-datepicker .ui-datepicker-header {
  background-image: none;
  background-color: var(--cleenhearts-dark, #24102f);
  color: var(--cleenhearts-white, #fff);
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
}

.ui-datepicker-calendar th span {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
}
.ui-datepicker-calendar td {
  background-color: var(--cleenhearts-white, #fff);
  background-image: none;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-text, #757277);
}
.ui-datepicker-calendar td a {
  border-color: var(--cleenhearts-border-color, #d7d3cb);
  background-color: var(--cleenhearts-gray, #888888);
  background-image: none;
}
.ui-datepicker-calendar .ui-state-default,
.ui-datepicker-calendar .ui-widget-content .ui-state-default,
.ui-datepicker-calendar .ui-widget-header .ui-state-default {
  border-color: var(--cleenhearts-border-color, #d7d3cb);
  background-color: var(--cleenhearts-white2, #efece7);
  background-image: none;
  color: var(--cleenhearts-base, #351c42);
  padding: 10px 5px;
  text-align: center;
  line-height: 1em;
}
.ui-datepicker-calendar .ui-state-default:hover,
.ui-datepicker-calendar .ui-widget-content .ui-state-default:hover,
.ui-datepicker-calendar .ui-widget-header .ui-state-default:hover {
  color: var(--cleenhearts-white, #fff);
  background-color: var(--cleenhearts-base, #351c42);
}
.ui-datepicker-calendar .ui-state-highlight,
.ui-datepicker-calendar .ui-widget-content .ui-state-highlight,
.ui-datepicker-calendar .ui-widget-header .ui-state-highlight {
  color: var(--cleenhearts-white, #fff);
  background-color: var(--cleenhearts-base, #351c42);
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  background-image: none;
  background-color: var(--cleenhearts-white, #fff);
  color: var(--cleenhearts-dark, #24102f);
}
.ui-datepicker .ui-datepicker-prev:hover,
.ui-datepicker .ui-datepicker-next:hover {
  background-color: var(--cleenhearts-base, #351c42);
  color: var(--cleenhearts-white, #fff);
  top: 2px;
}

.ui-datepicker .ui-datepicker-prev:hover {
  left: 2px;
}

.ui-datepicker .ui-datepicker-next:hover {
  right: 2px;
}

/*--------------------------------------------------------------
# Cards
--------------------------------------------------------------*/
.testimonials-card {
  padding: 40px 40px 34px;
  position: relative;
  border-radius: 20px;
}
@media (max-width: 1199px) {
  .testimonials-card {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 575px) {
  .testimonials-card {
    padding-bottom: 40px;
  }
}
.testimonials-card__bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--cleenhearts-white, #fff);
  mix-blend-mode: multiply;
  border-radius: 20px;
}
.testimonials-card__top {
  position: relative;
  z-index: 11;
  display: flex;
  justify-content: space-between;
}
.testimonials-card__quote {
  margin-top: 6px;
  margin-bottom: 31px;
}
.testimonials-card__quote span {
  font-size: 46px;
  color: var(--cleenhearts-purple, #965995);
}
.testimonials-card__content {
  position: relative;
  z-index: 11;
}
.testimonials-card__text {
  padding-right: 5px;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.59;
  text-transform: capitalize;
  margin-bottom: 36px;
}
@media (max-width: 1199px) {
  .testimonials-card__text {
    font-size: 20px;
  }
}
@media (max-width: 575px) {
  .testimonials-card__text {
    font-size: 18px;
  }
}
@media (max-width: 425px) {
  .testimonials-card__text {
    font-size: 16px;
  }
}
.testimonials-card__info {
  display: flex;
  align-items: center;
  gap: 25px 0;
}
@media (max-width: 575px) {
  .testimonials-card__info {
    flex-direction: column;
    align-items: flex-start;
  }
}
.testimonials-card__info__left {
  position: relative;
  padding-right: 65px;
}
.testimonials-card__info__left::after {
  content: "";
  width: 1px;
  height: 57px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: var(--cleenhearts-border-color, #d7d3cb);
}
@media (max-width: 1199px) {
  .testimonials-card__info__left {
    padding-right: 30px;
  }
}
@media (max-width: 575px) {
  .testimonials-card__info__left {
    padding-right: 0;
  }
  .testimonials-card__info__left::after {
    display: none;
  }
}
.testimonials-card__name {
  font-size: 24px;
  line-height: 1.458;
  text-transform: capitalize;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  margin-bottom: 7px;
}
@media (max-width: 1199px) {
  .testimonials-card__name {
    font-size: 21px;
  }
}
.testimonials-card__designation {
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  color: var(--cleenhearts-purple, #965995);
  text-transform: capitalize;
  margin-bottom: 0;
}
.testimonials-card__rattings {
  margin-left: 45px;
  gap: 9px;
}
@media (max-width: 1199px) {
  .testimonials-card__rattings {
    margin-left: 30px;
  }
}
@media (max-width: 575px) {
  .testimonials-card__rattings {
    margin-left: 0;
  }
}
.testimonials-card__rattings span {
  font-size: 16px;
}

.donation-information {
  position: relative;
  padding: 40px 32px 40px 40px;
  border-radius: 20px;
}
@media (max-width: 1199px) {
  .donation-information {
    padding-right: 40px;
  }
}
@media (max-width: 575px) {
  .donation-information {
    padding: 30px;
  }
}
.donation-information__bg {
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  z-index: 1;
}
.donation-information__bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: linear-gradient(
    108deg,
    #000 0.75%,
    rgba(53, 28, 66, 0.08) 97.97%
  );
}
.donation-information__content {
  position: relative;
  z-index: 11;
}
.donation-information__title {
  font-size: 30px;
  color: var(--cleenhearts-white2, #efece7);
  line-height: 1;
  letter-spacing: -0.9px;
  text-transform: capitalize;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .donation-information__title {
    font-size: 27px;
  }
}
@media (max-width: 575px) {
  .donation-information__title {
    font-size: 24px;
  }
}
.donation-information__text {
  color: var(--cleenhearts-white2, #efece7);
  text-transform: capitalize;
  margin-bottom: 31px;
  padding-right: 10px;
}
.donation-information__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;
}
.donation-information__list li {
  display: flex;
  align-items: center;
}
.donation-information__list li i {
  font-size: 17px;
  color: var(--cleenhearts-secondary, #f6d469);
}
.donation-information__list li span {
  font-size: 16px;
  color: var(--cleenhearts-white, #fff);
  font-weight: 700;
  line-height: 1.75;
  text-transform: capitalize;
  margin-left: 14px;
}
.donation-information__bottom {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.donation-information .cleenhearts-btn {
  border-color: var(--cleenhearts-white, #fff);
}
.donation-information .cleenhearts-btn__text {
  color: var(--cleenhearts-white, #fff);
}
.donation-information .cleenhearts-btn .cleenhearts-btn__icon-box {
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.donation-information .cleenhearts-btn .cleenhearts-btn__icon-box__inner span {
  color: var(--cleenhearts-base, #351c42);
  transition: all 0.4s ease-in-out;
}
.donation-information .cleenhearts-btn:hover .cleenhearts-btn__icon-box {
  background-color: var(--cleenhearts-secondary, #f6d469);
  width: 40px;
}
.donation-information
  .cleenhearts-btn:hover
  .cleenhearts-btn__icon-box__inner
  span {
  color: var(--cleenhearts-base, #351c42);
}
.donation-information .cleenhearts-btn:hover .cleenhearts-btn__text {
  color: var(--cleenhearts-base, #351c42);
}
.donation-information .cleenhearts-btn:hover {
  background-color: var(--cleenhearts-white2, #efece7);
  border-color: var(--cleenhearts-white2, #efece7);
}

.gift-card {
  position: relative;
  padding: 40px;
  border-radius: 20px;
}
@media (max-width: 575px) {
  .gift-card {
    padding: 30px;
  }
}
.gift-card__bg {
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  z-index: 1;
}
.gift-card__bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: linear-gradient(
    108deg,
    #000 0.75%,
    rgba(53, 28, 66, 0.08) 97.97%
  );
}
.gift-card__content {
  position: relative;
  z-index: 11;
}
.gift-card__title {
  font-size: 30px;
  color: var(--cleenhearts-white, #fff);
  line-height: 1;
  letter-spacing: -0.9px;
  text-transform: capitalize;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .gift-card__title {
    font-size: 27px;
  }
}
@media (max-width: 575px) {
  .gift-card__title {
    font-size: 24px;
  }
}
.gift-card__text {
  color: var(--cleenhearts-white2, #efece7);
  text-transform: capitalize;
  margin-bottom: 30px;
}
.gift-card__amount {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 29px;
}
.gift-card__btn {
  min-width: 77px;
  height: 60px;
  padding: 0 10px;
  text-align: center;
  outline: 0;
  border: 2px solid var(--cleenhearts-white, #fff);
  border-radius: 100px;
  background-color: transparent;
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  color: var(--cleenhearts-white, #fff);
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  text-transform: capitalize;
  transition: all 400ms ease;
}
.gift-card__btn.active {
  background-color: var(--cleenhearts-purple, #965995);
  color: var(--cleenhearts-white, #fff);
  border-color: transparent;
}
@media (max-width: 1399px) {
  .gift-card__btn {
    height: 50px;
  }
}
@media (max-width: 575px) {
  .gift-card__btn {
    height: 45px;
  }
}
.gift-card__btn:hover {
  background-color: var(--cleenhearts-purple, #965995);
  color: var(--cleenhearts-white, #fff);
  border-color: transparent;
}
.gift-card__form {
  width: 170px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 20px;
  border: 2px solid var(--cleenhearts-white, #fff);
  border-radius: 100px;
}
@media (max-width: 1399px) {
  .gift-card__form {
    height: 50px;
  }
}
@media (max-width: 575px) {
  .gift-card__form {
    height: 45px;
  }
}
.gift-card__form__input {
  position: relative;
  width: calc(100% - 66px);
  height: 60px;
  padding: 18px 10px;
  text-align: center;
  outline: 0;
  border: 2px solid var(--cleenhearts-white, #fff);
  border-right: none;
  border-radius: 100px;
  background-color: transparent;
}
@media (max-width: 1399px) {
  .gift-card__form__input {
    height: 50px;
  }
}
@media (max-width: 575px) {
  .gift-card__form__input {
    height: 45px;
  }
}
.gift-card__btn,
.gift-card__form__title,
.gift-card__form__input,
.gift-card__form__input::placeholder {
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  color: var(--cleenhearts-white2, #efece7);
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
}
.gift-card .cleenhearts-btn {
  border-color: var(--cleenhearts-white, #fff);
}
.gift-card .cleenhearts-btn__text {
  color: var(--cleenhearts-white, #fff);
}
.gift-card .cleenhearts-btn .cleenhearts-btn__icon-box {
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.gift-card .cleenhearts-btn .cleenhearts-btn__icon-box__inner span {
  color: var(--cleenhearts-base, #351c42);
  transition: all 0.4s ease-in-out;
}
.gift-card .cleenhearts-btn:hover .cleenhearts-btn__icon-box {
  background-color: var(--cleenhearts-secondary, #f6d469);
  width: 40px;
}
.gift-card .cleenhearts-btn:hover .cleenhearts-btn__icon-box__inner span {
  color: var(--cleenhearts-base, #351c42);
}
.gift-card .cleenhearts-btn:hover .cleenhearts-btn__text {
  color: var(--cleenhearts-base, #351c42);
}
.gift-card .cleenhearts-btn:hover {
  background-color: var(--cleenhearts-white2, #efece7);
  border-color: var(--cleenhearts-white2, #efece7);
}

.event-card-two {
  padding: 29px;
  display: flex;
  border-radius: 20px;
  position: relative;
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  background-color: var(--cleenhearts-white, #fff);
}
@media (max-width: 767px) {
  .event-card-two {
    display: block;
  }
}
.event-card-two__image {
  position: relative;
  border-radius: 20px;
  width: 314px;
  flex-shrink: 0;
  overflow: hidden;
  display: block;
}
@media (max-width: 1199px) {
  .event-card-two__image {
    width: 300px;
  }
}
.event-card-two__image::after {
  display: none;
}
.event-card-two__image img {
  width: 100%;
  height: 100%;
}
.event-card-two__time {
  bottom: 30px;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  padding: 0 8px;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 62px);
  border-radius: 100px;
  background-color: var(--cleenhearts-white, #fff);
  z-index: 11;
  font-size: 16px;
  font-weight: 500;
  color: var(--cleenhearts-gray, #888888);
}
@media (max-width: 1199px) {
  .event-card-two__time {
    width: calc(100% - 40px);
  }
}
.event-card-two__time__icon {
  font-size: 24px;
  line-height: inherit;
  margin-right: 11px;
  color: var(--cleenhearts-purple, #965995);
}
.event-card-two__content {
  position: relative;
  margin-top: 15px;
  padding-left: 30px;
}
@media (max-width: 1199px) {
  .event-card-two__content {
    margin-top: 7px;
    padding-left: 25px;
  }
}
@media (max-width: 767px) {
  .event-card-two__content {
    margin: 0;
    padding: 20px 0 0;
  }
}
.event-card-two__title {
  text-transform: capitalize;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 25px;
}
.event-card-two__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.event-card-two__title a:hover {
  background-size: 100% 1px;
}
.event-card-two__title a:hover {
  color: var(--cleenhearts-purple, #965995);
}
.event-card-two__text {
  text-transform: capitalize;
  color: var(--cleenhearts-text, #757277);
  padding-bottom: 29px;
  margin-bottom: 32px;
  border-bottom: 1px solid var(--cleenhearts-white2, #efece7);
}
@media (max-width: 1199px) {
  .event-card-two__text {
    padding-bottom: 24px;
    margin-bottom: 25px;
  }
}
.event-card-two__meta {
  position: relative;
  color: var(--cleenhearts-text, #757277);
  line-height: 14px;
  text-transform: capitalize;
}
.event-card-two__meta__title {
  color: var(--cleenhearts-purple, #965995);
  display: block;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
}

.event-card-three {
  border-radius: 20px;
  position: relative;
  background-color: var(--cleenhearts-white, #fff);
}
.event-card-three__date-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.event-card-three__time {
  padding: 0 19px 0 30px;
  line-height: 36px;
  display: inline-block;
  border-radius: 0px 100px 100px 0px;
  background-color: var(--cleenhearts-purple, #965995);
  font-size: 16px;
  font-weight: 500;
  color: var(--cleenhearts-white, #fff);
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .event-card-three__time {
    padding: 0 11px 0 15px;
    font-size: 15px;
  }
}
.event-card-three__time__icon {
  font-size: 24px;
  line-height: inherit;
  margin-right: 9px;
  position: relative;
  top: 2px;
  color: var(--cleenhearts-secondary, #f6d469);
}
@media (max-width: 767px) {
  .event-card-three__time__icon {
    font-size: 20px;
    margin-right: 5px;
  }
}
.event-card-three__date {
  padding: 0 31px 0 13px;
  line-height: 36px;
  display: inline-block;
  border-radius: 100px 0px 0px 100px;
  background-color: var(--cleenhearts-secondary, #f6d469);
  font-size: 16px;
  font-weight: 700;
  color: var(--cleenhearts-base, #351c42);
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .event-card-three__date {
    padding: 0 20px 0 13px;
    font-size: 15px;
  }
}
.event-card-three__content {
  position: relative;
  padding: 21px 30px 30px;
}
@media (max-width: 767px) {
  .event-card-three__content {
    padding: 20px;
  }
}
.event-card-three__title {
  text-transform: capitalize;
  font-size: 22px;
  line-height: 33px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .event-card-three__title {
    font-size: 20px;
    line-height: 28px;
  }
}
.event-card-three__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.event-card-three__title a:hover {
  background-size: 100% 1px;
}
.event-card-three__title a:hover {
  color: var(--cleenhearts-purple, #965995);
}
.event-card-three__text {
  text-transform: capitalize;
  margin-bottom: 35px;
}
.event-card-three__meta {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px 0;
}
@media (max-width: 575px) {
  .event-card-three__meta {
    flex-direction: column;
    align-items: flex-start;
  }
}
.event-card-three__meta li + li {
  padding-left: 32px;
  margin-left: 32px;
  position: relative;
}
@media (max-width: 1199px) {
  .event-card-three__meta li + li {
    margin-left: 20px;
    padding-left: 20px;
  }
}
@media (max-width: 575px) {
  .event-card-three__meta li + li {
    padding-left: 0;
    margin-left: 0;
  }
}
.event-card-three__meta li + li::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 41px;
  background-color: var(--cleenhearts-white2, #efece7);
}
@media (max-width: 575px) {
  .event-card-three__meta li + li::before {
    display: none;
  }
}
.event-card-three__meta li {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  text-transform: capitalize;
}
.event-card-three__meta__title {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-purple, #965995);
  font-size: 16px;
  line-height: 1.75;
  margin-bottom: 0;
  text-transform: capitalize;
}
.event-card-three__meta__title span {
  margin-right: 6px;
}

.event-card-four {
  padding: 29px;
  display: flex;
  border-radius: 20px;
  position: relative;
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  background-color: var(--cleenhearts-white, #fff);
}
@media (max-width: 991px) {
  .event-card-four {
    display: block;
  }
}
.event-card-four__image {
  position: relative;
  border-radius: 20px;
  width: 37.3%;
  flex-shrink: 0;
  overflow: hidden;
  display: block;
}
@media (max-width: 991px) {
  .event-card-four__image {
    width: 100%;
  }
}
.event-card-four__image img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
@media (max-width: 991px) {
  .event-card-four__image img {
    width: auto;
    max-width: 100%;
    height: auto;
  }
}
.event-card-four__date {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 64px;
  height: 64px;
  padding: 12px 10px;
  font-size: 16px;
  color: var(--cleenhearts-base, #351c42);
  line-height: 1.3;
  font-weight: 700;
  text-transform: uppercase;
  background-color: var(--cleenhearts-white2, #efece7);
  border-radius: 50%;
  text-align: center;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 767px) {
  .event-card-four__date {
    font-size: 14px;
  }
}
.event-card-four__date span {
  color: inherit;
  display: block;
}
.event-card-four:hover .event-card-four__date {
  color: var(--cleenhearts-white, #fff);
  background-color: var(--cleenhearts-purple, #965995);
}
.event-card-four__content {
  padding: 5px 0 0 30px;
  position: relative;
}
@media (max-width: 991px) {
  .event-card-four__content {
    padding: 40px 0 0;
  }
}
.event-card-four__time {
  position: relative;
  padding: 5px 10px;
  display: inline-block;
  align-items: center;
  color: var(--cleenhearts-white, #fff);
  border-radius: 100px;
  font-size: 16px;
  color: var(--cleenhearts-text-gray2, #888888);
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  border: 1px solid #d9d9d9;
  transition: all 0.4s ease-in-out;
  margin: 0px 0 31px;
}
.event-card-four__time__icon {
  position: relative;
  top: 2px;
  font-size: 24px;
  color: var(--cleenhearts-purple, #965995);
  margin-right: 10px;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 767px) {
  .event-card-four__time__icon {
    font-size: 20px;
  }
}
.event-card-four:hover .event-card-four__time {
  color: var(--cleenhearts-white, #fff);
  background-color: var(--cleenhearts-purple, #965995);
  border-color: var(--cleenhearts-purple, #965995);
}
.event-card-four:hover .event-card-four__time .event-card-four__time__icon {
  color: var(--cleenhearts-white, #fff);
}
.event-card-four__title {
  font-size: 22px;
  text-transform: capitalize;
  line-height: 33px;
  margin-bottom: 19px;
}
@media (max-width: 767px) {
  .event-card-four__title {
    font-size: 20px;
  }
}
.event-card-four__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.event-card-four__title a:hover {
  background-size: 100% 1px;
}
.event-card-four__title a:hover {
  color: var(--cleenhearts-purple, #965995);
}
.event-card-four__text {
  text-transform: capitalize;
  padding-bottom: 28px;
  margin-bottom: 29px;
  border-bottom: 1px solid var(--cleenhearts-white2, #efece7);
}
.event-card-four__meta {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: start;
  gap: 20px 0;
}
@media (max-width: 575px) {
  .event-card-four__meta {
    flex-direction: column;
    align-items: flex-start;
  }
}
.event-card-four__meta li + li {
  padding-left: 32px;
  margin-left: 32px;
  position: relative;
}
@media (max-width: 1199px) {
  .event-card-four__meta li + li {
    margin-left: 20px;
    padding-left: 20px;
  }
}
@media (max-width: 575px) {
  .event-card-four__meta li + li {
    padding-left: 0;
    margin-left: 0;
  }
}
.event-card-four__meta li + li::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 1px;
  height: calc(100% - 10px);
  background-color: var(--cleenhearts-white2, #efece7);
}
@media (max-width: 575px) {
  .event-card-four__meta li + li::before {
    display: none;
  }
}
.event-card-four__meta li {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  text-transform: capitalize;
}
.event-card-four__meta__title {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-purple, #965995);
  font-size: 16px;
  line-height: 1.75;
  margin-bottom: 0;
  text-transform: capitalize;
}
.event-card-four__meta__title span {
  margin-right: 6px;
}

.event-card-grid {
  position: relative;
}
.event-card-grid--reverse {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
}
.event-card-grid--reverse .event-card-grid__content {
  border-top: 1px solid var(--cleenhearts-white3, #d7d3cb);
  border-bottom: none;
  border-radius: 20px 20px 0 0;
}
.event-card-grid--reverse .event-card-grid__image {
  border-radius: 0 0 20px 20px;
  width: 100%;
}
.event-card-grid--reverse .event-card-grid__date-wrapper {
  top: auto;
  bottom: 30px;
}
.event-card-grid__image {
  position: relative;
  display: block;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
}
.event-card-grid__image img {
  width: 100%;
  height: auto;
  transition: transform 500ms ease, opacity 500ms ease;
}
.event-card-grid:hover .event-card-grid__image img {
  transform: scale(1.05) rotate(1.1deg);
}
.event-card-grid__date-wrapper {
  position: absolute;
  left: 0;
  top: 30px;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.event-card-grid__time {
  padding: 0 19px 0 30px;
  line-height: 36px;
  display: inline-block;
  border-radius: 0px 100px 100px 0px;
  background-color: var(--cleenhearts-purple, #965995);
  font-size: 16px;
  font-weight: 500;
  color: var(--cleenhearts-white, #fff);
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .event-card-grid__time {
    padding: 0 11px 0 15px;
    font-size: 15px;
  }
}
.event-card-grid__time__icon {
  font-size: 24px;
  line-height: inherit;
  margin-right: 9px;
  position: relative;
  top: 2px;
  color: var(--cleenhearts-white2, #efece7);
}
@media (max-width: 767px) {
  .event-card-grid__time__icon {
    font-size: 20px;
    margin-right: 5px;
  }
}
.event-card-grid__date {
  padding: 0 31px 0 13px;
  line-height: 36px;
  display: inline-block;
  border-radius: 100px 0px 0px 100px;
  background-color: var(--cleenhearts-secondary, #f6d469);
  font-size: 16px;
  font-weight: 700;
  color: var(--cleenhearts-base, #351c42);
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .event-card-grid__date {
    padding: 0 20px 0 13px;
    font-size: 15px;
  }
}
.event-card-grid__content {
  position: relative;
  border: 1px solid var(--cleenhearts-white3, #d7d3cb);
  border-top: none;
  padding: 31px 30px 29px;
  border-radius: 0 0 20px 20px;
}
.event-card-grid__title {
  text-transform: capitalize;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .event-card-grid__title {
    font-size: 22px;
    line-height: 30px;
  }
}
.event-card-grid__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.event-card-grid__title a:hover {
  background-size: 100% 1px;
}
.event-card-grid__title a:hover {
  color: var(--cleenhearts-purple, #965995);
}
.event-card-grid__meta {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  gap: 20px 0;
}
@media (max-width: 575px) {
  .event-card-grid__meta {
    flex-direction: column;
    align-items: flex-start;
  }
}
.event-card-grid__meta li + li {
  padding-left: 32px;
  margin-left: 32px;
  position: relative;
}
@media (max-width: 1199px) {
  .event-card-grid__meta li + li {
    margin-left: 20px;
    padding-left: 20px;
  }
}
@media (max-width: 575px) {
  .event-card-grid__meta li + li {
    padding-left: 0;
    margin-left: 0;
  }
}
.event-card-grid__meta li + li::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 41px;
  background-color: var(--cleenhearts-white3, #d7d3cb);
}
@media (max-width: 575px) {
  .event-card-grid__meta li + li::before {
    display: none;
  }
}
.event-card-grid__meta li {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  text-transform: capitalize;
  color: var(--cleenhearts-base, #351c42);
}
.event-card-grid__meta__title {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-base, #351c42);
  font-size: 16px;
  line-height: 1.75;
  margin-bottom: 0;
  text-transform: capitalize;
}
.event-card-grid__meta__title span {
  margin-right: 6px;
  color: var(--cleenhearts-purple, #965995);
}

.donation-card {
  padding: 22px;
  position: relative;
  background-color: var(--cleenhearts-white2, #efece7);
  border-radius: 20px;
}
.donation-card__bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
  z-index: 1;
}
.donation-card__image {
  display: block;
  position: relative;
  z-index: 11;
  overflow: hidden;
  border-radius: 20px;
}
.donation-card__image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-80%);
  width: 100%;
  height: 50%;
  border-radius: 20px 20px 0 0;
  background-color: RGBA(var(--cleenhearts-dark2-rgb, 32, 12, 42), 0.7);
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  transition: transform 400ms ease, visibility 400ms ease, opacity 400ms ease;
}
.donation-card__image::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(80%);
  width: 100%;
  height: 50%;
  border-radius: 0 0 20px 20px;
  background-color: RGBA(var(--cleenhearts-dark2-rgb, 32, 12, 42), 0.7);
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  transition: transform 400ms ease, visibility 400ms ease, opacity 400ms ease;
}
.donation-card__image img {
  transition: 0.5s;
  display: block;
  width: 100%;
  border-radius: 20px;
}
.donation-card:hover .donation-card__image::before,
.donation-card:hover .donation-card__image::after {
  transform: translateX(0px);
  opacity: 1;
  visibility: visible;
}
.donation-card:hover .donation-card__image img {
  transform: scale(1.1);
}
.donation-card__category {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: var(--cleenhearts-white, #fff);
  border-radius: 100px;
  padding: 6.5px 11px;
  font-size: 14px;
  color: var(--cleenhearts-purple, #965995);
  font-weight: 500;
  line-height: 1.142;
  text-transform: capitalize;
  z-index: 11;
  transition: all 0.4s ease-in-out;
}
.donation-card:hover .donation-card__category {
  background-color: var(--cleenhearts-secondary, #f6d469);
  color: var(--cleenhearts-base, #351c42);
}
.donation-card__content {
  padding-top: 65px;
  position: relative;
  z-index: 11;
}
.donation-card__progress {
  padding: 38px 20px 11px;
  position: absolute;
  top: -34px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 40px);
  background-color: var(--cleenhearts-white, #fff);
  border-radius: 10px;
}
@media (max-width: 425px) {
  .donation-card__progress {
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% - 30px);
  }
}
.donation-card__progress__bottom {
  margin-top: 11px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.donation-card__progress__title {
  font-size: 12px;
  color: var(--cleenhearts-base, #351c42);
  font-weight: 600;
  line-height: 14px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.donation-card__progress__title span {
  color: inherit;
}
.donation-card .progress-box__number {
  bottom: calc(100% + 13px);
}
.donation-card__title {
  font-size: 22px;
  line-height: 1.5;
  letter-spacing: -0.66px;
  text-transform: capitalize;
  margin-bottom: 29px;
}
@media (max-width: 991px) {
  .donation-card__title {
    font-size: 20px;
  }
}
.donation-card__title:hover {
  color: var(--cleenhearts-purple, #965995);
}
.donation-card__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.donation-card__title a:hover {
  background-size: 100% 1px;
}
.donation-card__btn {
  padding: 14px 17px;
  border: 2px solid var(--cleenhearts-base, #351c42);
}
.donation-card__btn .cleenhearts-btn__icon-box {
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.donation-card__btn .cleenhearts-btn__icon-box__inner span {
  color: var(--cleenhearts-base, #351c42);
  transition: all 0.4s ease-in-out;
}
.donation-card__btn:hover .cleenhearts-btn__icon-box {
  background-color: var(--cleenhearts-base, #351c42);
  width: 40px;
}
.donation-card__btn:hover .cleenhearts-btn__icon-box__inner span {
  color: var(--cleenhearts-white, #fff);
}
.donation-card__btn:hover .cleenhearts-btn__text {
  color: var(--cleenhearts-base, #351c42);
}
.donation-card__btn:hover {
  background-color: var(--cleenhearts-secondary, #f6d469);
  border-color: var(--cleenhearts-secondary, #f6d469);
}

.donation-card-two {
  padding-bottom: 30px;
}
.donation-card-two .donation-card__title {
  position: relative;
  z-index: 1;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: -0.72px;
}
.donation-card-two .donation-card__content {
  padding-top: 20px;
}
.donation-card-two .donation-card__progress {
  position: static;
  width: 100%;
  transform: translate(0);
  margin-bottom: 20px;
}
.donation-card-two .donation-card__progress__title {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
}
.donation-card-two__text {
  text-transform: capitalize;
  margin-bottom: 30px;
}

.donation-card-three {
  padding: 30px 35px 30px 32px;
}
@media (max-width: 991px) {
  .donation-card-three {
    padding: 30px;
  }
}
@media (max-width: 425px) {
  .donation-card-three {
    padding: 25px;
  }
}
.donation-card-three .donation-card__content {
  padding-top: 0;
}
.donation-card-three .donation-card__title {
  margin-bottom: 30px;
}
.donation-card-three__text {
  margin-bottom: 29px;
}
.donation-card-three__text__inner {
  text-transform: capitalize;
  margin-bottom: 0;
}
.donation-card-three .donation-card__progress {
  position: static;
  position: relative;
  top: 0;
  left: 0;
  transform: translate(0);
  width: 100%;
}

.video-one {
  padding: 192px 0;
  position: relative;
}
@media (max-width: 767px) {
  .video-one {
    padding: 115px 0;
  }
}
.video-one__bg {
  position: relative;
  position: absolute;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.video-one__bg::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: RGBA(var(--cleenhearts-base-rgb, 53, 28, 66), 0.8);
}
.video-one .container {
  position: relative;
  z-index: 2;
}
.video-one__title {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  font-size: 150px;
  text-align: center;
  line-height: 0.666;
  letter-spacing: -6px;
  text-transform: capitalize;
  color: RGBA(var(--cleenhearts-base-rgb, 53, 28, 66), 1);
  text-shadow: 1px 1px 0 var(--cleenhearts-white, #fff),
    -1px -1px 0 var(--cleenhearts-white, #fff),
    1px -1px 0 var(--cleenhearts-white, #fff),
    -1px 1px 0 var(--cleenhearts-white, #fff),
    1px 1px 0 var(--cleenhearts-white, #fff);
  z-index: 1;
}
.video-one__title__one {
  color: RGBA(var(--cleenhearts-purple-rgb, 150, 89, 149), 1);
}
@media (max-width: 1199px) {
  .video-one__title {
    font-size: 126px;
  }
}
@media (max-width: 991px) {
  .video-one__title {
    font-size: 80px;
  }
  .video-one__title__two {
    color: RGBA(var(--cleenhearts-purple-rgb, 150, 89, 149), 1);
  }
}
@media (max-width: 767px) {
  .video-one__title {
    display: none;
  }
}
.video-one__button {
  width: 231px;
  height: 231px;
  background-color: var(--cleenhearts-purple, #965995);
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
@media (max-width: 767px) {
  .video-one__button {
    width: 150px;
    height: 150px;
  }
}
.video-one__button::after {
  content: "";
  width: calc(100% + 120px);
  height: calc(100% + 120px);
  position: absolute;
  top: -60px;
  left: -60px;
  border-radius: 50%;
  border: 1px dashed RGBA(var(--cleenhearts-white-rgb, 255, 255, 255), 0.25);
  animation-duration: 1500ms;
  animation: rotated 10s infinite linear;
  transition: 500ms all ease;
  animation-play-state: running;
}
.video-one__button:hover::after {
  border-color: rgba(var(--cleenhearts-secondary-rgb, 246, 212, 105), 0.7);
}
.video-one__button span {
  position: relative;
  z-index: 99;
  font-size: 65px;
  color: var(--cleenhearts-secondary, #f6d469);
}
@media (max-width: 767px) {
  .video-one__button span {
    font-size: 35px;
  }
}
.video-one__button .video-button__ripple::before,
.video-one__button .video-button__ripple::after {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 0 rgba(var(--cleenhearts-secondary-rgb, 246, 212, 105), 0.6);
}

.blog-card {
  position: relative;
  background-color: var(--cleenhearts-white, #fff);
}
.blog-card__image {
  display: block;
  position: relative;
  overflow: hidden;
  width: calc(100% - 44px);
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  z-index: 11;
  margin-bottom: -115px;
}
.blog-card__image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-80%);
  width: 100%;
  height: 50%;
  border-radius: 20px 20px 0 0;
  background-color: RGBA(var(--cleenhearts-dark2-rgb, 32, 12, 42), 0.8);
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  transition: transform 500ms ease, visibility 500ms ease, opacity 500ms ease;
}
.blog-card__image::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(80%);
  width: 100%;
  height: 50%;
  border-radius: 0 0 20px 20px;
  background-color: RGBA(var(--cleenhearts-dark2-rgb, 32, 12, 42), 0.8);
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  transition: transform 500ms ease, visibility 500ms ease, opacity 500ms ease;
}
.blog-card__image img {
  transition: 0.5s;
  display: block;
  width: 100%;
  border-radius: 20px;
}
.blog-card:hover .blog-card__image::before,
.blog-card:hover .blog-card__image::after {
  transform: translateX(0px);
  opacity: 1;
  visibility: visible;
}
.blog-card:hover .blog-card__image img {
  transform: scale(1.1);
}
.blog-card__date {
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: var(--cleenhearts-white, #fff);
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: 16px;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-base, #351c42);
  padding: 0 10px;
  line-height: 1;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
  text-transform: uppercase;
  font-weight: 700;
  flex-direction: column;
  gap: 3px;
  z-index: 11;
  transition: all 0.4s ease-in-out;
}
.blog-card__date::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform: scale(0);
  opacity: 0;
  transition: transform 400ms ease, opacity 400ms ease;
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.blog-card__date span {
  font-size: 16px;
}
.blog-card:hover .blog-card__date::after {
  opacity: 1;
  transform: scale(1);
}
.blog-card__content {
  position: relative;
  text-align: center;
  padding: 146px 33px 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: all 500ms ease;
  border-radius: 20px;
  z-index: 1;
}
@media (max-width: 1199px) {
  .blog-card__content {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.blog-card__title {
  color: var(--cleenhearts-base, #351c42);
  font-size: 24px;
  text-transform: capitalize;
  line-height: 1.375;
  margin-bottom: 29px;
  letter-spacing: var(--cleenhearts-letter-space, -0.72px);
}
.blog-card__title:hover {
  color: var(--cleenhearts-purple, #965995);
}
@media (max-width: 1199px) {
  .blog-card__title {
    font-size: 22px;
  }
}
.blog-card__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.blog-card__title a:hover {
  background-size: 100% 1px;
}
.blog-card__link {
  display: inline-block;
  font-size: 16px;
  color: var(--cleenhearts-base, #351c42);
  font-weight: 700;
  text-align: center;
  height: 40px;
  max-width: 140px;
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}
.blog-card__link::after {
  display: none;
}
.blog-card__link__front {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  background-color: var(--cleenhearts-secondary, #f6d469);
  perspective: 300px;
  transition: transform 500ms ease;
  transform-origin: top center;
  transform: translateY(0%) translateZ(0px) scaleY(1) rotateX(0deg);
}
.blog-card__link:hover .blog-card__link__front {
  transform-origin: bottom center;
  transform: translateY(-100%) translateZ(150px) scaleY(0) rotateX(90deg);
}
.blog-card__link__back {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 700ms ease, top 700ms ease;
  transform: translateY(0%) translateZ(150px) scaleY(0) rotateX(90deg);
  perspective: 300px;
  transform-origin: bottom center;
  width: 100%;
}
.blog-card__link__back span {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  background-color: var(--cleenhearts-secondary, #f6d469);
  margin-right: 11px;
  transition: all 500ms ease;
}
.blog-card__link:hover .blog-card__link__back {
  transition: transform 500ms ease, top 500ms ease;
  transform: translateY(0%) translateZ(0px) scaleY(1) rotateX(0deg);
}

.blog-card-two {
  position: relative;
  padding: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
}
@media (max-width: 425px) {
  .blog-card-two {
    padding: 25px;
  }
}
.blog-card-two__image {
  display: flex;
  gap: 30px;
  align-items: stretch;
  margin-bottom: 30px;
}
@media (max-width: 575px) {
  .blog-card-two__image {
    flex-direction: column;
  }
}
.blog-card-two__image__link {
  position: relative;
  overflow: hidden;
  display: block;
  width: calc(100% - 64px);
  border-radius: 20px;
  overflow: hidden;
}
@media (max-width: 1199px) {
  .blog-card-two__image__link {
    width: calc(100% - 55px);
  }
}
@media (max-width: 575px) {
  .blog-card-two__image__link {
    width: 100%;
  }
}
.blog-card-two__image__link::after {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background-color: rgba(var(--cleenhearts-dark2-rgb, 32, 12, 42), 0.8);
  transition: all 700ms ease;
  transform: translateY(-110%);
  border-radius: 10px;
  z-index: 1;
}
.blog-card-two__image__link img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
  transition: all 700ms ease;
}
.blog-card-two__image__icon {
  position: relative;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  visibility: hidden;
  z-index: 11;
  transition: all 700ms ease;
  transform: translate(-50%, 350%);
  width: 20px;
  height: 20px;
}
.blog-card-two__image__icon::after,
.blog-card-two__image__icon::before {
  width: 1px;
  height: 20px;
  position: absolute;
  background-color: var(--cleenhearts-secondary, #f6d469);
  top: 50%;
  left: 50%;
  content: "";
  transform: translate(-50%, -50%);
  transition: all 500ms ease;
  border-radius: 50px;
}
.blog-card-two__image__icon::after {
  width: 20px;
  height: 1px;
  border-radius: 50px;
}
.blog-card-two:hover .blog-card-two__image__link::after {
  transform: translateY(0);
}
.blog-card-two:hover .blog-card-two__image__link img {
  transform: scale(1.1) rotate(3deg);
}
.blog-card-two:hover .blog-card-two__image__icon {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%);
}
.blog-card-two__date {
  padding-bottom: 26px;
  width: 64px;
  background-color: var(--cleenhearts-white, #fff);
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
@media (max-width: 1199px) {
  .blog-card-two__date {
    width: 55px;
  }
}
@media (max-width: 575px) {
  .blog-card-two__date {
    padding-bottom: 0;
    padding-right: 26px;
    width: 100%;
    flex-direction: row;
  }
}
.blog-card-two__date__text {
  font-size: 22px;
  line-height: 16px;
  text-transform: capitalize;
  margin-bottom: 0;
}
@media (max-width: 1199px) {
  .blog-card-two__date__text {
    font-size: 18px;
  }
}
@media (max-width: 425px) {
  .blog-card-two__date__text {
    font-size: 16px;
  }
}
.blog-card-two__date__day {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--cleenhearts-white, #fff);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: var(--cleenhearts-purple, #965995);
  transition: all 400ms ease;
}
@media (max-width: 1199px) {
  .blog-card-two__date__day {
    width: 55px;
    height: 55px;
  }
}
.blog-card-two__date__month {
  margin: 0;
  text-align: center;
  color: var(--cleenhearts-purple, #965995);
  transition: all 500ms ease;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}
@media (max-width: 575px) {
  .blog-card-two__date__month {
    writing-mode: horizontal-tb;
    transform: rotate(0deg);
  }
}
.blog-card-two:hover .blog-card-two__date__day {
  color: var(--cleenhearts-base, #351c42);
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.blog-card-two__title {
  position: relative;
  font-size: 22px;
  line-height: 1.5;
  letter-spacing: -0.66px;
  text-transform: capitalize;
  margin-bottom: 30px;
  padding-bottom: 29px;
  transition: all 400ms ease;
  border-bottom: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
@media (max-width: 1199px) {
  .blog-card-two__title {
    font-size: 20px;
  }
}
.blog-card-two__title:hover {
  color: var(--cleenhearts-purple, #965995);
}
.blog-card-two__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.blog-card-two__title a:hover {
  background-size: 100% 1px;
}
.blog-card-two__text {
  text-transform: capitalize;
  margin-bottom: 30px;
}
.blog-card-two__link {
  display: inline-block;
}
.blog-card-two__link i {
  display: inline-block;
  font-size: 13px;
  color: var(--cleenhearts-base, #351c42);
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--cleenhearts-secondary, #f6d469);
  line-height: 42px;
  position: relative;
  z-index: 1;
}
.blog-card-two__link i::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform: scale(0);
  opacity: 0;
  transition: transform 400ms ease, opacity 400ms ease;
  background-color: var(--cleenhearts-base, #351c42);
}
.blog-card-two__link span {
  display: inline-block;
  margin-left: 6px;
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  font-size: 16px;
  color: var(--cleenhearts-base, #351c42);
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  transform: translateX(-50px);
  opacity: 0;
  transition: transform 400ms ease, opacity 400ms ease;
}
.blog-card-two__link:hover i {
  color: var(--cleenhearts-secondary, #f6d469);
}
.blog-card-two__link:hover i::after {
  transform: scale(1);
  opacity: 1;
}
.blog-card-two__link:hover span {
  opacity: 1;
  transform: translateX(0px);
}

.blog-card-three {
  display: flex;
  align-items: center;
  position: relative;
  gap: 30px;
  background-color: var(--cleenhearts-white, #fff);
  border-radius: 20px;
}
@media (max-width: 1199px) {
  .blog-card-three {
    padding: 30px;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 991px) {
  .blog-card-three {
    padding: 0px;
    flex-direction: row;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .blog-card-three {
    padding: 30px;
    flex-direction: column;
    align-items: flex-start;
  }
}
.blog-card-three__bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
}
.blog-card-three__image {
  display: block;
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  width: 100%;
  max-width: 240px;
  border-radius: 20px;
}
.blog-card-three__image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-80%);
  width: 100%;
  height: 50%;
  border-radius: 20px 20px 0 0;
  background-color: RGBA(var(--cleenhearts-dark2-rgb, 32, 12, 42), 0.8);
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  transition: transform 500ms ease, visibility 500ms ease, opacity 500ms ease;
}
.blog-card-three__image::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateX(80%);
  width: 100%;
  height: 50%;
  border-radius: 0 0 20px 20px;
  background-color: RGBA(var(--cleenhearts-dark2-rgb, 32, 12, 42), 0.8);
  opacity: 0;
  visibility: hidden;
  z-index: 1;
  transition: transform 500ms ease, visibility 500ms ease, opacity 500ms ease;
}
.blog-card-three__image img {
  transition: 0.5s;
  display: block;
  width: 100%;
  border-radius: 20px;
}
.blog-card-three:hover .blog-card-three__image::before,
.blog-card-three:hover .blog-card-three__image::after {
  transform: translateX(0px);
  opacity: 1;
  visibility: visible;
}
.blog-card-three:hover .blog-card-three__image img {
  transform: scale(1.1);
}
.blog-card-three__date {
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: var(--cleenhearts-white2, #efece7);
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 0 10px;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
  flex-direction: column;
  gap: 3px;
  z-index: 11;
  transition: all 0.4s ease-in-out;
}
.blog-card-three__date::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform: scale(0);
  opacity: 0;
  transition: transform 400ms ease, opacity 400ms ease;
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.blog-card-three__date span {
  font-size: 16px;
  color: var(--cleenhearts-base, #351c42);
  line-height: 1;
  text-transform: uppercase;
  font-weight: 700;
}
.blog-card-three:hover .blog-card-three__date::after {
  opacity: 1;
  transform: scale(1);
}
.blog-card-three__content {
  padding: 20px 30px 20px 0;
  position: relative;
  z-index: 1;
}
@media (max-width: 1199px) {
  .blog-card-three__content {
    padding: 0;
  }
}
@media (max-width: 991px) {
  .blog-card-three__content {
    padding: 20px 30px 20px 0;
  }
}
@media (max-width: 767px) {
  .blog-card-three__content {
    padding: 0;
  }
}
.blog-card-three__category {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 21px;
}
.blog-card-three__category__link {
  padding: 6px 10.5px;
  background-color: var(--cleenhearts-white, #fff);
  font-size: 16px;
  color: var(--cleenhearts-purple, #965995);
  border-radius: 100px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: -0.48px;
  text-transform: capitalize;
  transition: all 300ms ease;
}
.blog-card-three__category__link:hover {
  background-color: var(--cleenhearts-purple, #965995);
  color: var(--cleenhearts-white, #fff);
}
.blog-card-three__title {
  font-size: 22px;
  line-height: 1.5;
  letter-spacing: -0.66px;
  text-transform: capitalize;
  margin-bottom: 41px;
}
.blog-card-three__title:hover {
  color: var(--cleenhearts-purple, #965995);
}
@media (max-width: 575px) {
  .blog-card-three__title {
    font-size: 20px;
  }
}
.blog-card-three__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.blog-card-three__title a:hover {
  background-size: 100% 1px;
}
.blog-card-three .cleenhearts-btn {
  padding-top: 7px;
  padding-bottom: 7px;
  position: relative;
  left: -10px;
  background-color: transparent;
}
.blog-card-three .cleenhearts-btn__icon-box {
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.blog-card-three .cleenhearts-btn__icon-box__inner span {
  color: var(--cleenhearts-base, #351c42);
}
.blog-card-three .cleenhearts-btn:hover .cleenhearts-btn__text {
  color: var(--cleenhearts-base, #351c42);
}

.blog-card-four {
  background-color: var(--cleenhearts-white, #fff);
  border-bottom: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  padding-bottom: 40px;
}
.blog-card-four .blog-card__image {
  width: 100%;
  margin-bottom: 33px;
}
.blog-card-four .blog-card__date {
  top: 30px;
  left: 30px;
}
@media (max-width: 575px) {
  .blog-card-four .blog-card__date {
    top: 20px;
    left: 20px;
  }
}
@media (max-width: 425px) {
  .blog-card-four .blog-card__date {
    top: 15px;
    left: 15px;
  }
}
.blog-card-four__content {
  padding: 0;
  border-radius: 0;
}
@media (max-width: 1199px) {
  .blog-card-four__content {
    padding-left: 0;
    padding-right: 0;
  }
}
.blog-card-four__meta {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 35px;
}
.blog-card-four__meta li a {
  border-radius: 100px;
  padding: 8.5px 14px 8.5px 8px;
  font-size: 16px;
  color: var(--cleenhearts-gray, #888888);
  font-weight: 500;
  line-height: 1;
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
.blog-card-four__meta li a span {
  position: relative;
  top: 4.5px;
  margin-right: 7px;
  font-size: 24px;
  color: var(--cleenhearts-purple, #965995);
  transition: all 0.4s ease-in-out;
}
.blog-card-four__meta li a:hover {
  color: var(--cleenhearts-base, #351c42);
  border-color: var(--cleenhearts-secondary, #f6d469);
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.blog-card-four__meta li a:hover span {
  color: var(--cleenhearts-base, #351c42);
}
.blog-card-four .blog-card__title {
  text-align: left;
  line-height: 1.541;
  letter-spacing: normal;
  margin-bottom: 18px;
}
.blog-card-four__text {
  margin-bottom: 30px;
  text-transform: capitalize;
}
.blog-card-four .blog-card__link {
  display: inline-block;
  margin-left: 0;
  margin-right: 0;
}

/*--------------------------------------------------------------
# Form
--------------------------------------------------------------*/
.form-one__control__input {
  padding: 0;
  border: none;
  outline: none;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  height: 65px;
  background-color: var(--cleenhearts-white2, #efece7);
  border-radius: 10px;
}
@media (max-width: 425px) {
  .form-one__control__input {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.form-one__control__input,
.form-one__control__input::placeholder {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  color: var(--cleenhearts-text, #757277);
  line-height: 1;
}
.form-one__control__input {
  color: var(--cleenhearts-base, #351c42);
}
.form-one__control__message {
  padding-top: 25px;
  padding-bottom: 25px;
  height: 165px;
  margin-bottom: -9px;
}
.form-one__control
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  ) {
  position: relative;
  display: block;
  width: 100% !important;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
}
.form-one__control .bootstrap-select > .dropdown-toggle::after {
  display: none;
}
.form-one__control .bootstrap-select > .dropdown-toggle {
  position: relative;
  height: 65px;
  outline: none !important;
  border-radius: 100px;
  border: 0;
  background-color: var(--cleenhearts-white, #fff) !important;
  margin: 0;
  padding: 0;
  padding-left: 30px;
  padding-right: 30px;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-text, #757277) !important;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 65px;
  font-weight: 400;
  box-shadow: none !important;
  background-repeat: no-repeat;
  background-size: 14px 12px;
  background-position: right 25.75px center;
}
@media (max-width: 425px) {
  .form-one__control .bootstrap-select > .dropdown-toggle {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.form-one__control .bootstrap-select > .dropdown-toggle:before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
  font-family: "Font Awesome 5 Free";
  content: "\f107";
  font-weight: 900;
  font-size: 16px;
  color: var(--cleenhearts-text, #757277);
}
@media (max-width: 425px) {
  .form-one__control .bootstrap-select > .dropdown-toggle:before {
    right: 20px;
  }
}
.form-one__control .bootstrap-select .dropdown-menu > li + li > a {
  border-top: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
.form-one__control .bootstrap-select .dropdown-menu {
  border: none;
}
.form-one__control .bootstrap-select .dropdown-menu > li > a {
  font-size: 16px;
  font-weight: 400;
  padding: 10px 30px;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-weight: normal;
  color: var(--cleenhearts-base, #351c42);
  background-color: var(--cleenhearts-white, #fff);
  text-transform: capitalize;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.form-one__control .bootstrap-select .dropdown-menu > li:hover > a,
.form-one__control .bootstrap-select .dropdown-menu > li.selected > a {
  background: var(--cleenhearts-secondary, #f6d469);
  color: var(--cleenhearts-base, #351c42);
  border-color: var(--cleenhearts-secondary, #f6d469);
}

/*--------------------------------------------------------------
# Custom Cursor
--------------------------------------------------------------*/
.custom-cursor__cursor {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  border: 1px solid var(--cleenhearts-base, #351c42);
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  position: fixed;
  pointer-events: none;
  left: 0;
  top: 0;
  -webkit-transform: translate(calc(-50% + 5px), -50%);
  transform: translate(calc(-50% + 5px), -50%);
  z-index: 999991;
}
.custom-cursor__cursor-two {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: var(--cleenhearts-base, #351c42);
  opacity: 0.3;
  position: fixed;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  pointer-events: none;
  -webkit-transition: width 0.3s, height 0.3s, opacity 0.3s;
  transition: width 0.3s, height 0.3s, opacity 0.3s;
  z-index: 999991;
}
.custom-cursor__hover {
  background-color: var(--cleenhearts-base, #351c42);
  opacity: 0.4;
}
.custom-cursor__innerhover {
  width: 25px;
  height: 25px;
  opacity: 0.4;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
.main-footer {
  position: relative;
  overflow: hidden;
}
.main-footer__top {
  position: relative;
  padding-top: 66px;
  padding-bottom: 66px;
  z-index: 1;
}
.main-footer__top__shape-one {
  position: absolute;
  top: 0;
  left: 0;
  animation: slowXmove 6s ease infinite;
}
@media (max-width: 1799px) {
  .main-footer__top__shape-one {
    width: 200px;
  }
}
@media (max-width: 1599px) {
  .main-footer__top__shape-one {
    top: -20px;
    right: -20px;
  }
}
.main-footer__top__shape-two {
  position: absolute;
  right: -7px;
  bottom: 0;
  z-index: 1;
  animation: slowXmove 6s ease infinite;
}
@media (max-width: 1650px) {
  .main-footer__top__shape-two {
    width: 300px;
  }
}
.main-footer .container {
  position: relative;
  z-index: 11;
}
.main-footer__bottom {
  position: relative;
  padding-top: 25.5px;
  padding-bottom: 24.5px;
  background-color: var(--cleenhearts-secondary, #f6d469);
  z-index: 1;
}
.main-footer__bottom__bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: var(--cleenhearts-secondary, #f6d469);
  mix-blend-mode: multiply;
}
.main-footer__bottom__inner {
  text-align: center;
}
.main-footer__copyright {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: var(--cleenhearts-base, #351c42);
  line-height: 1.5;
}

@media (max-width: 1199px) {
  .footer-widget--about {
    width: 50%;
  }
}
@media (max-width: 991px) {
  .footer-widget--about {
    width: 60%;
  }
}
@media (max-width: 767px) {
  .footer-widget--about {
    width: 100%;
  }
}
.footer-widget__logo {
  display: inline-flex;
  margin-bottom: 40px;
}
.footer-widget__title {
  display: table;
  position: relative;
  font-size: 20px;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-base, #351c42);
  text-transform: capitalize;
  font-weight: 700;
  line-height: 1;
  padding-bottom: 21px;
  margin-top: 15px;
  margin-bottom: 34px;
}
.footer-widget__title::before,
.footer-widget__title::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: var(--cleenhearts-purple, #965995);
}
.footer-widget__title::before {
  left: 0;
  width: 32px;
}
.footer-widget__title::after {
  left: 38px;
  width: 89px;
}
.footer-widget__info li + li {
  margin-top: 20px;
}
.footer-widget__links li + li {
  margin-top: 10px;
}
.footer-widget__info,
.footer-widget__links {
  margin-bottom: 0;
}
.footer-widget__info li,
.footer-widget__links li {
  display: flex;
  align-items: flex-start;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  color: var(--cleenhearts-text, #757277);
  font-weight: 400;
  line-height: 1.562;
}
.footer-widget__info li span,
.footer-widget__links li span {
  display: inline-block;
  position: relative;
  top: 4.5px;
  font-size: 16px;
  color: var(--cleenhearts-purple, #965995);
  margin-right: 10px;
}
.footer-widget__info li span.icon-envelope,
.footer-widget__links li span.icon-envelope {
  top: 6px;
  font-size: 14px;
}
.footer-widget__info li address,
.footer-widget__links li address {
  color: inherit;
  margin: 0;
}
.footer-widget__info li a,
.footer-widget__links li a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.footer-widget__info li a:hover,
.footer-widget__links li a:hover {
  background-size: 100% 1px;
}
.footer-widget__info li a:hover,
.footer-widget__links li a:hover {
  color: var(--cleenhearts-base, #351c42);
}
.footer-widget__about-text {
  text-transform: capitalize;
  padding-right: 10px;
  margin-bottom: 30px;
}
.footer-widget__map {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-purple, #965995);
  font-weight: 500;
  line-height: 1.285;
}
.footer-widget__map:hover {
  color: var(--cleenhearts-base, #351c42);
}
.footer-widget__map span {
  font-size: inherit;
  color: inherit;
  transition: all 0.4s ease-in-out;
}
.footer-widget__map__text {
  margin-right: 12px;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.footer-widget__map__text:hover {
  background-size: 100% 1px;
}
.footer-widget__gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 11px;
}
.footer-widget__gallery__link {
  position: relative;
  display: block;
  border-radius: 10px;
  overflow: hidden;
}
.footer-widget__gallery__link::after {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background-color: rgba(var(--cleenhearts-dark2-rgb, 32, 12, 42), 0.7);
  transition: all 700ms ease;
  transform: translateY(-110%);
  border-radius: 10px;
  z-index: 1;
}
.footer-widget__gallery__link:hover::after {
  transform: translateY(0);
}
.footer-widget__gallery__link img {
  width: 100%;
  display: block;
  border-radius: 10px;
}
.footer-widget__gallery__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--cleenhearts-white, #fff);
  font-size: 14px;
  z-index: 11;
  transition: all 700ms ease;
  transform: translate(-50%, 350%);
}
.footer-widget__gallery__link:hover .footer-widget__gallery__icon {
  transform: translate(-50%, -50%);
}
.footer-widget__subscribe__text {
  color: var(--cleenhearts-white3, #d7d3cb);
  text-transform: capitalize;
  margin-bottom: 30px;
}
.footer-widget__subscribe__form__inner {
  padding: 0 15px 0 18px;
  display: flex;
  align-items: center;
  height: 51px;
  background-color: var(--cleenhearts-white2, #efece7);
  border-radius: 8px;
  margin-bottom: 14px;
}
.footer-widget__subscribe input[type="email"] {
  width: calc(100% - 20px);
  padding: 0;
  border: none;
  outline: none;
  padding-right: 3px;
  background-color: transparent;
}
.footer-widget__subscribe button[type="submit"] {
  font-size: 20px;
  padding: 0;
  border: none;
  outline: none;
  color: var(--cleenhearts-purple, #965995);
  transition: all 400ms ease;
}
.footer-widget__subscribe button[type="submit"]:hover {
  color: var(--cleenhearts-base, #351c42);
}
.footer-widget__subscribe__checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  display: none;
  cursor: pointer;
}
.footer-widget__subscribe__checkbox label {
  position: relative;
  cursor: pointer;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-white3, #d7d3cb);
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}
.footer-widget__subscribe__checkbox label::before {
  content: "";
  border: 1px solid var(--cleenhearts-white, #fff);
  outline: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: -1px;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 11px;
}
.footer-widget__subscribe__checkbox input:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.footer-widget__news__single + .footer-widget__news__single {
  margin-top: 25px;
}
.footer-widget__news__single {
  display: flex;
  gap: 20px;
}
.footer-widget__news__image {
  flex-shrink: 0;
  width: 70px;
}
.footer-widget__news__image img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.footer-widget__news__content {
  position: relative;
  top: -10px;
}
@media (max-width: 767px) {
  .footer-widget__news__content {
    top: -6px;
  }
}
.footer-widget__news__title {
  font-size: 18px;
  color: var(--cleenhearts-white3, #d7d3cb);
  line-height: 1.666;
  letter-spacing: -0.54px;
  text-transform: capitalize;
  margin-bottom: 10px;
}
.footer-widget__news__title:hover {
  color: var(--cleenhearts-secondary, #f6d469);
}
.footer-widget__news__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.footer-widget__news__title a:hover {
  background-size: 100% 1px;
}
.footer-widget__news__date {
  font-size: 14px;
  color: var(--cleenhearts-secondary, #f6d469);
  font-weight: 500;
  line-height: 16px;
  text-transform: capitalize;
}

.footer-two {
  background-position: top center;
  background-size: auto;
  background-repeat: no-repeat;
  background-color: var(--cleenhearts-base, #351c42);
}
.footer-two .main-footer__top {
  padding-top: 50px;
  padding-bottom: 61px;
}
.footer-two .footer-widget__title {
  color: var(--cleenhearts-white2, #efece7);
}
.footer-two .footer-widget__title::before,
.footer-two .footer-widget__title::after {
  background-color: var(--cleenhearts-text-gray, #aa96b4);
}
.footer-two .footer-widget__info,
.footer-two .footer-widget__links {
  margin-bottom: 0;
}
.footer-two .footer-widget__info li,
.footer-two .footer-widget__links li {
  color: var(--cleenhearts-white3, #d7d3cb);
}
.footer-two .footer-widget__info li span,
.footer-two .footer-widget__links li span {
  color: var(--cleenhearts-secondary, #f6d469);
  margin-right: 10px;
}
.footer-two .footer-widget__info li a:hover,
.footer-two .footer-widget__links li a:hover {
  color: var(--cleenhearts-secondary, #f6d469);
}
.footer-two .footer-widget__about-text {
  color: var(--cleenhearts-white3, #d7d3cb);
}
.footer-two .footer-widget__map {
  color: var(--cleenhearts-white3, #d7d3cb);
}
.footer-two .footer-widget__map__icon {
  color: var(--cleenhearts-secondary, #f6d469);
}
.footer-two .footer-widget__map:hover {
  color: var(--cleenhearts-secondary, #f6d469);
}
.footer-two .main-footer__bottom {
  padding-top: 27px;
  padding-bottom: 28px;
}
.footer-two .main-footer__bottom__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
@media (max-width: 1199px) {
  .footer-two .main-footer__bottom__inner {
    flex-direction: column;
  }
}
@media (max-width: 1199px) {
  .footer-two__logo {
    display: none;
  }
}

.footer-three .main-footer__top {
  padding-top: 48px;
  padding-bottom: 49px;
}
.footer-three__bottom {
  position: relative;
  padding-top: 31px;
  padding-bottom: 30px;
  background-color: var(--cleenhearts-white, #fff);
  z-index: 1;
}
.footer-three__bottom__inner {
  text-align: center;
}
.footer-three__copyright {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: var(--cleenhearts-text, #757277);
  line-height: 1.5;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact-one__map {
  position: relative;
}
.contact-one__google__map iframe {
  border-radius: 20px;
  height: 717.98px !important;
}
.contact-one__info {
  padding: 30px 30px 28px;
  width: calc(100% - 96px);
  position: absolute;
  left: 50%;
  bottom: 58px;
  transform: translateX(-50%);
  background-color: var(--cleenhearts-white, #fff);
  border-radius: 20px;
}
@media (max-width: 575px) {
  .contact-one__info {
    padding: 25px;
    width: calc(100% - 60px);
    bottom: 40px;
  }
}
@media (max-width: 375px) {
  .contact-one__info {
    width: calc(100% - 40px);
    bottom: 30px;
  }
}
.contact-one__info__item + .contact-one__info__item {
  margin-top: 28px;
}
.contact-one__info__item {
  display: flex;
  align-items: center;
  gap: 16px;
}
@media (max-width: 450px) {
  .contact-one__info__item {
    flex-direction: column;
    text-align: center;
  }
}
.contact-one__info__icon {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border: 1px solid var(--cleenhearts-purple, #965995);
  z-index: 1;
  transition: all 0.4s ease-in-out;
}
.contact-one__info__icon::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform: scale(0);
  opacity: 0;
  transition: transform 400ms ease, opacity 400ms ease;
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.contact-one__info__icon span {
  font-size: 18px;
  color: var(--cleenhearts-purple, #965995);
  transition: all 0.4s ease-in-out;
}
.contact-one__info__icon span.icon-envelope {
  font-size: 15px;
}
.contact-one__info__item:hover .contact-one__info__icon {
  border-color: transparent;
}
.contact-one__info__item:hover .contact-one__info__icon::after {
  transform: scale(1);
  opacity: 1;
}
.contact-one__info__item:hover .contact-one__info__icon span {
  color: var(--cleenhearts-base, #351c42);
}
.contact-one__info__title {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 11px;
}
.contact-one__info__text {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-text, #757277);
  font-weight: 400;
  line-height: 1.562;
  margin-bottom: 0;
  transition: all 0.4s ease-in-out;
}
.contact-one__info__text--link {
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.contact-one__info__text--link:hover {
  background-size: 100% 1px;
}
.contact-one__info__text--link:hover {
  color: var(--cleenhearts-base, #351c42);
}
.contact-one__form {
  position: relative;
  padding: 43px 40px 57px;
  background-color: var(--cleenhearts-white2, #efece7);
  border-radius: 20px;
}
@media (max-width: 500px) {
  .contact-one__form {
    padding: 33px 30px 47px;
  }
}
@media (max-width: 375px) {
  .contact-one__form {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.contact-one__form__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  mix-blend-mode: multiply;
  z-index: 1;
  border-radius: 20px;
}
.contact-one__form__inner {
  position: relative;
  z-index: 11;
}
.contact-one__form__inner .form-one__control__input {
  border-radius: 100px;
  background-color: var(--cleenhearts-white, #fff);
}
.contact-one__form__inner .form-one__control__message {
  border-radius: 20px;
  height: 138px;
}
.contact-one__title {
  position: relative;
  z-index: 11;
  font-size: 24px;
  line-height: 1.333;
  text-transform: capitalize;
  margin-bottom: 30px;
}

/*--------------------------------------------------------------
# Topbar
--------------------------------------------------------------*/
.topbar-one {
  display: none;
  background-color: var(--cleenhearts-base, #351c42);
  padding: 4.6px 85px;
}
@media (max-width: 1599px) {
  .topbar-one {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 768px) {
  .topbar-one {
    display: block;
  }
}
.topbar-one__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 18px;
  padding-bottom: 18px;
}
.topbar-one__info {
  display: flex;
  align-items: center;
  margin: 0;
}
.topbar-one__info__item {
  display: flex;
  align-items: center;
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  color: var(--cleenhearts-text-gray, #aa96b4);
  font-size: 16px;
  font-weight: 500;
  line-height: 1.187;
}
.topbar-one__info__item a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.topbar-one__info__item a:hover {
  background-size: 100% 1px;
}
.topbar-one__info__item a:hover {
  color: var(--cleenhearts-secondary, #f6d469);
}
@media (max-width: 991px) {
  .topbar-one__info__item:last-child {
    display: none;
  }
}
.topbar-one__info__item + .topbar-one__info__item {
  margin-left: 32px;
}
.topbar-one__info__icon {
  font-size: 18px;
  color: var(--cleenhearts-secondary, #f6d469);
  position: relative;
  top: -1px;
  margin-right: 15px;
}
.topbar-one__right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.topbar-one__social a {
  color: var(--cleenhearts-white, #fff);
}
.topbar-one__social a:hover {
  color: var(--cleenhearts-secondary, #f6d469);
}
.topbar-one__social a + a::after {
  background-color: var(--cleenhearts-dark4, #414141);
}

.topbar-two {
  background-color: var(--cleenhearts-white, #fff);
  padding-top: 0;
  padding-bottom: 0;
}
.topbar-two .topbar-one__inner {
  padding-top: 31px;
  padding-bottom: 30px;
  justify-content: space-between;
}
.topbar-two .topbar-one__info__item {
  color: var(--cleenhearts-base, #351c42);
  display: flex;
}
.topbar-two .topbar-one__info__icon {
  color: var(--cleenhearts-purple, #965995);
}
.topbar-two .topbar-one__info__item a:hover {
  color: var(--cleenhearts-purple, #965995);
}
@media (max-width: 1199px) {
  .topbar-two__logo {
    display: none;
  }
}
.topbar-two .topbar-one__right {
  display: flex;
}
.topbar-two .main-header__cart {
  margin: 0;
  margin-right: 66px;
}
@media (max-width: 1199px) {
  .topbar-two .main-header__cart {
    margin-right: 40px;
  }
}
.topbar-two .topbar-one__social a {
  color: var(--cleenhearts-text, #757277);
}
.topbar-two .topbar-one__social a:hover {
  color: var(--cleenhearts-purple, #965995);
}
.topbar-two .topbar-one__social a + a::after {
  background-color: var(--cleenhearts-white3, #d7d3cb);
}

/*--------------------------------------------------------------
# Navigations
--------------------------------------------------------------*/
.main-header {
  position: relative;
  background-color: var(--cleenhearts-white, #fff);
  z-index: 11;
  padding: 0 85px;
}
@media (max-width: 1599px) {
  .main-header {
    padding-left: 0;
    padding-right: 0;
  }
}
.main-header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
@media (max-width: 1199px) {
  .main-header__inner {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
.main-header__logo {
  display: flex;
  align-items: center;
}
.main-header__logo img {
  max-width: 100%;
  height: auto;
}
.main-header__sidebar-btn {
  margin-left: 117px;
  padding: 0;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: var(--cleenhearts-purple, #965995);
}
@media (max-width: 1850px) {
  .main-header__sidebar-btn {
    margin-left: 70px;
  }
}
@media (max-width: 1399px) {
  .main-header__sidebar-btn {
    margin-left: 50px;
  }
}
@media (max-width: 767px) {
  .main-header__sidebar-btn {
    display: none;
  }
}
.main-header__sidebar-btn span {
  font-size: 30px;
  color: inherit;
  transition: all 0.4s ease-in-out;
}
.main-header__sidebar-btn:hover {
  color: var(--cleenhearts-secondary, #f6d469);
}
.main-header__right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media (max-width: 1199px) {
  .main-header__right {
    gap: 25px;
  }
}
@media (max-width: 1199px) and (max-width: 412px) {
  .main-header__right {
    gap: 20px;
  }
}
.main-header__cart {
  display: flex;
  align-items: center;
  margin-left: 83px;
  margin-right: 65px;
}
@media (max-width: 1850px) {
  .main-header__cart {
    margin-left: 50px;
    margin-right: 50px;
  }
}
@media (max-width: 1599px) {
  .main-header__cart {
    margin-left: 30px;
    margin-right: 20px;
  }
}
@media (max-width: 1399px) {
  .main-header__cart {
    margin-left: 35px;
  }
}
@media (max-width: 1199px) {
  .main-header__cart {
    margin-left: 0;
    padding-left: 20px;
    border-left: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  }
}
@media (max-width: 1299px) {
  .main-header__cart {
    margin-right: 0;
  }
}
.main-header__cart__link {
  color: var(--cleenhearts-base, #351c42);
}
.main-header__cart__link span {
  font-size: 24px;
  color: inherit;
  transition: all 0.4s ease-in-out;
}
.main-header__cart__link:hover {
  color: var(--cleenhearts-purple, #965995);
}
.main-header__cart__quantity {
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  font-size: 16px;
  font-weight: 700;
  color: var(--cleenhearts-base, #351c42);
  line-height: 1;
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background-color: var(--cleenhearts-white2, #efece7);
}
@media (max-width: 412px) {
  .main-header__cart__quantity {
    width: 32px;
    height: 32px;
  }
}
.main-header__btn {
  padding-top: 21px;
  padding-bottom: 21px;
  padding-right: 21px;
}
@media (max-width: 1299px) {
  .main-header__btn {
    display: none;
  }
}
.main-header__btn .cleenhearts-btn__icon-box {
  width: 52px;
  height: 52px;
}
.main-header__btn .cleenhearts-btn__icon-box__inner {
  width: 52px;
  height: 52px;
}
.main-header__btn .cleenhearts-btn__text {
  margin-left: 56px;
}

.main-header-two {
  position: relative;
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.main-header-two__bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  mix-blend-mode: multiply;
}
.main-header-two .container-fluid {
  position: relative;
  z-index: 11;
}
.main-header-two__left {
  display: flex;
  align-items: center;
}
@media (min-width: 1200px) {
  .main-header-two__logo {
    display: none;
  }
}
.main-header-two__sidebar-btn {
  padding: 0;
  border: 0;
  outline: 0;
  background-color: transparent;
  width: 27px;
}
@media (max-width: 1199px) {
  .main-header-two__sidebar-btn {
    display: none;
  }
}
.main-header-two__sidebar-btn__line {
  display: block;
  width: 100%;
  height: 3px;
  background-color: var(--cleenhearts-base, #351c42);
  margin: 5px 0;
  transition: all 400ms ease;
}
.main-header-two__sidebar-btn__line:nth-child(2) {
  width: 20px;
}
.main-header-two__sidebar-btn:hover .main-header-two__sidebar-btn__line {
  width: 100%;
}
.main-header-two .main-header__right {
  display: flex;
  align-items: center;
}
@media (max-width: 1199px) {
  .main-header-two .main-header__right {
    gap: 18px;
  }
}
.main-header-two .main-header__right .main-header-two__sidebar-btn {
  display: none;
}
@media (max-width: 1199px) {
  .main-header-two .main-header__right .main-header-two__sidebar-btn {
    display: block;
    margin-right: 18px;
    position: relative;
  }
  .main-header-two .main-header__right .main-header-two__sidebar-btn::after {
    content: "";
    width: 1px;
    height: 100%;
    background-color: var(--cleenhearts-base, #351c42);
    position: absolute;
    top: 50%;
    right: -18px;
    transform: translateY(-50%);
  }
}
.main-header-two .main-header__btn {
  display: inline-block !important;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
}
@media (max-width: 1199px) {
  .main-header-two .main-header__btn {
    display: none !important;
  }
}
.main-header-two .main-header__btn .cleenhearts-btn__icon-box {
  left: 6px;
  width: 46px;
  height: 46px;
}
.main-header-two .main-header__btn:hover .cleenhearts-btn__icon-box {
  width: calc(100% - 12px);
}
.main-header-two .main-header__btn .cleenhearts-btn__icon-box__inner {
  width: 46px;
  height: 46px;
}
.main-header-two .main-header__btn .cleenhearts-btn__text {
  margin-left: 42px;
}

.main-header-three {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  padding: 0 65px;
}
@media (max-width: 1599px) {
  .main-header-three {
    padding-left: 0;
    padding-right: 0;
  }
}
.main-header-three .home-showcase__inner {
  position: relative;
  top: -1px;
  border-radius: 0 0 20px 20px;
}
.main-header-three .main-header__inner {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 30px;
}
@media (max-width: 1199px) {
  .main-header-three .main-header__inner {
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 575px) {
  .main-header-three .main-header__inner {
    gap: 15px;
  }
}
.main-header-three__logo {
  padding: 31px 30px;
  width: 100%;
  max-width: 220px;
  background-color: var(--cleenhearts-white, #fff);
  border-radius: 0 0 20px 20px;
}
@media (max-width: 575px) {
  .main-header-three__logo {
    display: flex;
    align-items: center;
    max-width: 190px;
    padding: 15px;
    height: 80px;
  }
}
@media (max-width: 500px) {
  .main-header-three__logo {
    max-width: 150px;
  }
  .main-header-three__logo img {
    width: 120px;
  }
}
@media (max-width: 340px) {
  .main-header-three__logo {
    max-width: 130px;
  }
  .main-header-three__logo img {
    width: 100px;
  }
}
.main-header-three .main-header__right {
  justify-content: space-between;
  flex-grow: 1;
  padding-left: 40px;
  padding-right: 30px;
  background-color: var(--cleenhearts-white2, #efece7);
  border-radius: 0 0 20px 20px;
}
@media (max-width: 1599px) {
  .main-header-three .main-header__right {
    padding-left: 30px;
  }
}
@media (max-width: 1199px) {
  .main-header-three .main-header__right {
    padding-top: 36px;
    padding-bottom: 36px;
  }
}
@media (max-width: 575px) {
  .main-header-three .main-header__right {
    padding: 15px;
    height: 80px;
  }
}
.main-header-three .main-header__right__right {
  display: flex;
  align-items: center;
}
@media (max-width: 1199px) {
  .main-header-three .main-header__right__right {
    gap: 20px;
  }
}
.main-header-three .main-header__right__sidebar-btn::after {
  top: 0;
  transform: translateY(0);
}
@media (max-width: 450px) {
  .main-header-three .main-header__right__sidebar-btn {
    padding-right: 0;
    padding-left: 20px;
  }
  .main-header-three .main-header__right__sidebar-btn::after {
    right: 0;
    left: 0px;
  }
}
.main-header-three .main-header__cart {
  margin: 0;
  margin-right: 30px;
}
@media (max-width: 1399px) {
  .main-header-three .main-header__cart {
    margin-right: 0;
  }
}
@media (min-width: 1400px) and (max-width: 1799px) {
  .main-header-three .main-header__cart {
    margin-right: 20px;
    padding-right: 20px;
    position: relative;
  }
  .main-header-three .main-header__cart::after {
    content: "";
    width: 1px;
    height: 100%;
    background-color: var(--cleenhearts-white3, #d7d3cb);
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
  }
}
.main-header-three .main-header__cart__link {
  color: var(--cleenhearts-purple, #965995);
}
.main-header-three .main-header__cart__link:hover {
  color: var(--cleenhearts-base, #351c42);
}
.main-header-three .main-header__cart__quantity {
  background-color: var(--cleenhearts-white3, #d7d3cb);
}
.main-header-three .main-header__btn {
  position: relative;
  margin-right: 60px;
  padding: 11px 11px 11px 0;
}
@media (max-width: 1700px) {
  .main-header-three .main-header__btn {
    margin-right: 0;
    margin-left: 20px;
  }
}
@media (max-width: 1300px) {
  .main-header-three .main-header__btn {
    display: none;
  }
}
.main-header-three .main-header__btn::after {
  content: "";
  position: absolute;
  top: 0;
  right: -30px;
  width: 1px;
  height: 100%;
  background-color: var(--cleenhearts-white3, #d7d3cb);
}
.main-header-three .main-header__btn .cleenhearts-btn__icon-box {
  width: 30px;
  height: 30px;
}
.main-header-three .main-header__btn:hover .cleenhearts-btn__icon-box {
  border-radius: 100px;
  width: calc(100% - 17px);
}
.main-header-three .main-header__btn .cleenhearts-btn__icon-box__inner {
  width: 30px;
  height: 30px;
}
.main-header-three .main-header__btn .cleenhearts-btn__icon-box__inner span {
  font-size: 12px;
  color: var(--cleenhearts-white2, #efece7);
}
.main-header-three .main-header__btn .cleenhearts-btn__text {
  margin-left: 50px;
}
.main-header-three__social {
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 1399px) {
  .main-header-three__social {
    display: none;
  }
}
.main-header-three__social__title {
  font-family: var(--cleenhearts-special-font, "Schoolbell", cursive);
  font-weight: 400;
  font-size: 16px;
  line-height: 1;
  color: var(--cleenhearts-purple, #965995);
  text-transform: capitalize;
}
@media (max-width: 1599px) {
  .main-header-three__social__title {
    display: none;
  }
}
.main-header-three .social-link-two a {
  color: var(--cleenhearts-text, #757277);
  border-color: var(--cleenhearts-border-color, #d7d3cb);
}
.main-header-three .social-link-two a:hover {
  color: var(--cleenhearts-white, #fff);
  border-color: var(--cleenhearts-purple, #965995);
}

.sticky-header--cloned {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  top: 0;
  background-color: var(--cleenhearts-white, #fff);
  transform: translateY(-100%);
  box-shadow: 0px 3px 18px rgba(var(--cleenhearts-dark-rgb, 36, 16, 47), 0.07);
  transition: 0.6s cubic-bezier(0.24, 0.74, 0.58, 1);
  visibility: hidden;
  transition: transform 500ms ease, visibility 500ms ease;
}
.sticky-header--cloned.sticky-header--two {
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.sticky-header--cloned.sticky-header--three {
  background-color: transparent;
  box-shadow: 0px 0px 0px transparent;
}
.sticky-header--cloned.sticky-header--three .main-header-three__logo {
  box-shadow: 0px 3px 18px rgba(var(--cleenhearts-dark-rgb, 36, 16, 47), 0.07);
  padding-top: 26px;
  padding-bottom: 26px;
}
.sticky-header--cloned.sticky-header--three .main-header__right {
  box-shadow: 0px 3px 18px rgba(var(--cleenhearts-dark-rgb, 36, 16, 47), 0.07);
}
.sticky-header--cloned.active {
  transform: translateY(0%);
  visibility: visible;
}
.sticky-header--cloned .main-menu .main-menu__list > li,
.sticky-header--cloned .main-menu-two .main-menu__list > li {
  padding-top: 36px;
  padding-bottom: 36px;
}

.mobile-nav__btn {
  width: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  cursor: pointer;
  z-index: 3;
}
@media (min-width: 1200px) {
  .mobile-nav__btn {
    display: none;
  }
}
.mobile-nav__btn span {
  width: 100%;
  height: 2px;
  background-color: var(--cleenhearts-dark, #24102f);
}
.mobile-nav__btn span:nth-child(2) {
  margin-top: 4px;
  margin-bottom: 4px;
}

.main-menu .main-menu__list,
.main-menu .main-menu__list ul,
.main-menu-two .main-menu__list,
.main-menu-two .main-menu__list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  align-items: center;
  display: none;
}
@media (min-width: 1200px) {
  .main-menu .main-menu__list,
  .main-menu .main-menu__list ul,
  .main-menu-two .main-menu__list,
  .main-menu-two .main-menu__list ul {
    display: flex;
  }
}
.main-menu .main-menu__list > li,
.main-menu-two .main-menu__list > li {
  padding-top: 51px;
  padding-bottom: 51px;
  position: relative;
}
.main-menu .main-menu__list > li.dropdown > a,
.main-menu-two .main-menu__list > li.dropdown > a {
  position: relative;
}
.main-menu--two .main-menu__list > li,
.main-menu-two--two .main-menu__list > li {
  padding-top: 23.5px;
  padding-bottom: 23.5px;
}
.main-menu--three .main-menu__list > li,
.main-menu-two--three .main-menu__list > li {
  padding-top: 41px;
  padding-bottom: 41px;
}
.main-menu .main-menu__list > li + li,
.main-menu-two .main-menu__list > li + li {
  margin-left: 43px;
}
@media (max-width: 1400px) {
  .main-menu .main-menu__list > li + li,
  .main-menu-two .main-menu__list > li + li {
    margin-left: 40px;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .main-menu .main-menu__list > li + li,
  .main-menu-two .main-menu__list > li + li {
    margin-left: 35px;
  }
}
@media (max-width: 1599px) {
  .main-menu--three .main-menu__list > li + li,
  .main-menu-two--three .main-menu__list > li + li {
    margin-left: 37px;
  }
}
.main-menu .main-menu__list > li > a,
.main-menu-two .main-menu__list > li > a {
  font-size: 16px;
  display: flex;
  align-items: center;
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  color: var(--cleenhearts-base, #351c42);
  font-weight: 700;
  text-transform: capitalize;
  position: relative;
  transition: all 500ms ease;
}
@media (min-width: 1200px) and (max-width: 1299px) {
  .main-menu .main-menu__list > li > a,
  .main-menu-two .main-menu__list > li > a {
    font-size: 15px;
  }
}
.main-menu .main-menu__list > li.current > a,
.main-menu .main-menu__list > li:hover > a,
.main-menu-two .main-menu__list > li.current > a,
.main-menu-two .main-menu__list > li:hover > a {
  color: var(--cleenhearts-secondary, #f6d469);
  text-shadow: 0 0 0.5px currentColor;
}
.main-menu--two .main-menu__list > li.current > a,
.main-menu--two .main-menu__list > li:hover > a,
.main-menu-two--two .main-menu__list > li.current > a,
.main-menu-two--two .main-menu__list > li:hover > a {
  color: var(--cleenhearts-purple, #965995);
}
.main-menu .main-menu__list li ul,
.main-menu-two .main-menu__list li ul {
  position: absolute;
  top: 100%;
  left: -25px;
  min-width: 270px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  opacity: 0;
  visibility: hidden;
  transform-origin: top center;
  transform: scaleY(0) translateZ(100px);
  transition: opacity 500ms ease, visibility 500ms ease, transform 700ms ease;
  z-index: 99;
  background-color: var(--cleenhearts-white, #fff);
  box-shadow: 0px 10px 60px 0px
    RGBA(var(--cleenhearts-white-rgb, 255, 255, 255), 0.07);
  padding: 15px 20px 11px;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
}
.main-menu .main-menu__list li:hover > ul,
.main-menu-two .main-menu__list li:hover > ul {
  opacity: 1;
  visibility: visible;
  transform: scaleY(1) translateZ(0px);
}
.main-menu .main-menu__list > .megamenu,
.main-menu-two .main-menu__list > .megamenu {
  position: static;
}
.main-menu .main-menu__list > .megamenu > ul,
.main-menu-two .main-menu__list > .megamenu > ul {
  top: 100% !important;
  left: 0 !important;
  right: 0 !important;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}
.main-menu .main-menu__list li ul li,
.main-menu-two .main-menu__list li ul li {
  flex: 1 1 100%;
  width: 100%;
  position: relative;
}
.main-menu .main-menu__list li ul li > a,
.main-menu-two .main-menu__list li ul li > a {
  font-size: 14px;
  line-height: 26px;
  color: var(--cleenhearts-base, #351c42);
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  font-weight: 700;
  display: flex;
  text-transform: capitalize;
  padding: 8px 20px;
  transition: 400ms;
  margin-bottom: 4px;
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .main-menu .main-menu__list li ul li > a,
  .main-menu-two .main-menu__list li ul li > a {
    font-size: 13px;
  }
}
.main-menu .main-menu__list li ul li > a::after,
.main-menu-two .main-menu__list li ul li > a::after {
  position: absolute;
  right: 20px;
  top: 8px;
  border-radius: 0;
  font-size: 6px;
  font-weight: 700;
  font-family: "Font Awesome 5 Free";
  content: "\f111";
  color: var(--cleenhearts-base, #351c42);
  visibility: hidden;
  opacity: 0;
  transition: all 500ms ease;
  transform: scale(0);
}
.main-menu .main-menu__list li ul li.current > a,
.main-menu .main-menu__list li ul li:hover > a,
.main-menu-two .main-menu__list li ul li.current > a,
.main-menu-two .main-menu__list li ul li:hover > a {
  background-color: var(--cleenhearts-secondary, #f6d469);
  color: var(--cleenhearts-base, #351c42);
}
.main-menu .main-menu__list li ul li.current > a::after,
.main-menu .main-menu__list li ul li:hover > a::after,
.main-menu-two .main-menu__list li ul li.current > a::after,
.main-menu-two .main-menu__list li ul li:hover > a::after {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
.main-menu .main-menu__list li ul li > ul,
.main-menu-two .main-menu__list li ul li > ul {
  top: 0;
  left: calc(100% + 20px);
}
.main-menu .main-menu__list li ul li > ul.right-align,
.main-menu-two .main-menu__list li ul li > ul.right-align {
  top: 0;
  left: auto;
  right: 100%;
}
.main-menu .main-menu__list li ul li > ul ul,
.main-menu-two .main-menu__list li ul li > ul ul {
  display: none;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .main-menu__list li:nth-last-child(1) ul li > ul,
  .main-menu__list li:nth-last-child(2) ul li > ul {
    left: auto;
    right: calc(100% + 20px);
  }
}

/*--------------------------------------------------------------
# Megamenu Popup
--------------------------------------------------------------*/
.mobile-nav__container .main-menu__list > .megamenu.megamenu-clickable > ul,
.main-menu .main-menu__list > .megamenu.megamenu-clickable > ul,
.stricky-header .main-menu__list > .megamenu.megamenu-clickable > ul {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100vw;
  height: 100vh;
  visibility: visible;
  overflow-y: scroll;
  visibility: hidden;
  opacity: 0;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  transition: transform 0.7s ease, opacity 0.7s ease, visibility 0.7s ease;
  z-index: 999999;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
  padding: 0;
  background-color: var(--cleenhearts-white, #fff);
  display: block !important;
  margin: 0;
}

.main-menu__list > li.megamenu-clickable > ul::-webkit-scrollbar {
  display: none;
}

.mobile-nav__container
  .main-menu__list
  > .megamenu.megamenu-clickable
  > ul.megamenu-clickable--active,
.main-menu
  .main-menu__list
  > .megamenu.megamenu-clickable
  > ul.megamenu-clickable--active,
.stricky-header
  .main-menu__list
  > .megamenu.megamenu-clickable
  > ul.megamenu-clickable--active {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  opacity: 1;
  visibility: visible;
  transition: transform 0.7s ease, opacity 0.7s ease, visibility 0.7s ease;
}

body.megamenu-popup-active {
  overflow: hidden;
}

body.megamenu-popup-active .stricky-header {
  bottom: 0;
}

body.megamenu-popup-active .mobile-nav__content {
  overflow: unset;
}

.mobile-nav__content .demo-one .container {
  padding-left: 15px;
  padding-right: 15px;
}

.megamenu-popup {
  position: relative;
}
.megamenu-popup .megamenu-clickable--close {
  position: absolute;
  top: 18px;
  right: 20px;
  display: block;
  color: var(--cleenhearts-base, #351c42);
}
@media (min-width: 1300px) {
  .megamenu-popup .megamenu-clickable--close {
    top: 38px;
    right: 40px;
  }
}
.megamenu-popup .megamenu-clickable--close:hover {
  color: var(--cleenhearts-secondary, #f6d469);
}
.megamenu-popup .megamenu-clickable--close span {
  width: 24px;
  height: 24px;
  display: block;
  position: relative;
  color: currentColor;
  transition: all 500ms ease;
}
.megamenu-popup .megamenu-clickable--close span::before,
.megamenu-popup .megamenu-clickable--close span::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: currentColor;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
}
.megamenu-popup .megamenu-clickable--close span::after {
  transform: translate(-50%, -50%) rotate(45deg);
}

/*--------------------------------------------------------------
# Home Showcase
--------------------------------------------------------------*/
.demo-one {
  padding-top: 120px;
  padding-bottom: 120px;
}
.demo-one .row {
  --bs-gutter-y: 30px;
}
.demo-one__card {
  background-color: var(--cleenhearts-white, #fff);
  box-shadow: 0px 10px 60px 0px
    rgba(var(--cleenhearts-dark3-rgb, 32, 12, 43), 0.1);
  text-align: center;
  transition: 500ms ease;
  transform: translateY(0px);
}
.demo-one__card:hover {
  transform: translateY(-10px);
}
.demo-one__title {
  margin: 0;
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  color: var(--cleenhearts-base, #351c42);
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
}
.demo-one__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.demo-one__title a:hover {
  background-size: 100% 1px;
}
.demo-one__image {
  position: relative;
  overflow: hidden;
}
.demo-one__image img {
  max-width: 100%;
  transition: filter 500ms ease;
  filter: blur(0px);
}
.demo-one__card:hover .demo-one__image img {
  filter: blur(2px);
}
.demo-one__btns {
  background-color: rgba(var(--cleenhearts-dark-rgb, 36, 16, 47), 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  transform: scale(1, 0);
  transition: transform 500ms ease, opacity 600ms linear;
  transform-origin: bottom center;
  opacity: 0;
}
.demo-one .cleenhearts-btn {
  padding: 10px 17px 10px 18px;
}
.demo-one .cleenhearts-btn__icon-box {
  width: 30px;
  height: 30px;
}
.demo-one .cleenhearts-btn__icon-box__inner {
  width: 30px;
  height: 30px;
}
.demo-one .cleenhearts-btn__icon-box__inner span {
  font-size: 10px;
}
.demo-one .cleenhearts-btn__text {
  font-size: 14px;
  margin-left: 28px;
}
.demo-one__card:hover .demo-one__btns {
  transform: scale(1, 1);
  opacity: 1;
  transform-origin: top center;
}
.demo-one__title {
  padding-top: 20.5px;
  padding-bottom: 20.5px;
}

.home-showcase .row {
  --bs-gutter-x: 42px;
  --bs-gutter-y: 20px;
}
.home-showcase__inner {
  padding: 40px 40px 21px;
  background-color: var(--cleenhearts-white, #fff);
  box-shadow: 0px 10px 60px 0px
    rgba(var(--cleenhearts-dark3-rgb, 32, 12, 43), 0.07);
}
.home-showcase .demo-one__card {
  box-shadow: none;
}
.home-showcase .demo-one__btns {
  flex-direction: column;
}
.home-showcase .cleenhearts-btn {
  padding: 10px 17px 10px 18px;
}
.home-showcase .cleenhearts-btn__icon-box {
  width: 30px;
  height: 30px;
}
.home-showcase .cleenhearts-btn__icon-box__inner {
  width: 30px;
  height: 30px;
}
.home-showcase .cleenhearts-btn__icon-box__inner span {
  font-size: 10px;
}
.home-showcase .cleenhearts-btn__text {
  font-size: 14px;
  margin-left: 28px;
}
.home-showcase .demo-one__title {
  padding: 0;
  font-size: 16px;
  margin-top: 15px;
  padding-bottom: 15px;
}

/*--------------------------------------------------------------
# Accordion
--------------------------------------------------------------*/
.cleenhearts-accordion .accordion {
  background-color: var(--cleenhearts-white2, #efece7);
  border-radius: 10px;
  position: relative;
}
.cleenhearts-accordion .accordion + .accordion {
  margin-top: 10px;
}
.cleenhearts-accordion .accordion-title {
  padding: 26px;
  padding-right: 50.72px;
  cursor: pointer;
}
.cleenhearts-accordion .accordion-title h4 {
  font-size: 16px;
  text-transform: capitalize;
  line-height: 1;
  margin: 0;
  transition: all 500ms ease;
  position: relative;
  letter-spacing: -0.48px;
}
.cleenhearts-accordion .accordion-title__icon {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 50%;
  right: -24px;
  transform: translateY(-50%);
}
.cleenhearts-accordion .accordion-title__icon::after,
.cleenhearts-accordion .accordion-title__icon::before {
  width: 2px;
  height: 14px;
  position: absolute;
  background-color: var(--cleenhearts-base, #351c42);
  top: 50%;
  left: 50%;
  content: "";
  transform: translate(-50%, -50%);
  transition: all 500ms ease;
  border-radius: 50px;
}
.cleenhearts-accordion .accordion-title__icon::after {
  width: 14px;
  height: 2px;
  border-radius: 50px;
}
.cleenhearts-accordion .active .accordion-title h4 {
  color: var(--cleenhearts-purple, #965995);
}
.cleenhearts-accordion .active .accordion-title__icon::after,
.cleenhearts-accordion .active .accordion-title__icon::before {
  background-color: var(--cleenhearts-purple, #965995);
  opacity: 0;
}
.cleenhearts-accordion .active .accordion-title__icon::after {
  opacity: 1;
}
.cleenhearts-accordion .accordion-content .inner {
  margin-top: -6px;
  padding: 0 27px 26px 21px;
}
.cleenhearts-accordion .accordion-content p {
  margin: 0;
  text-transform: capitalize;
}

/*--------------------------------------------------------------
# Why choose
--------------------------------------------------------------*/
.why-choose-one {
  position: relative;
  background-color: var(--cleenhearts-white, #fff);
}
.why-choose-one--volunteer__bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--cleenhearts-white2, #efece7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.why-choose-one .container {
  position: relative;
  z-index: 11;
}
.why-choose-one__image {
  display: flex;
  align-items: baseline;
  gap: 30px;
}
@media (max-width: 425px) {
  .why-choose-one__image {
    gap: 20px;
  }
}
.why-choose-one__image__inner {
  width: 50%;
  height: 100%;
}
.why-choose-one__image__inner img {
  display: block;
  width: 100%;
  border-radius: 20px;
}
.why-choose-one__image__inner img:not(:last-child) {
  margin-bottom: 30px;
}
@media (max-width: 425px) {
  .why-choose-one__image__inner img:not(:last-child) {
    margin-bottom: 20px;
  }
}
.why-choose-one__content {
  margin-top: 2px;
}
.why-choose-one .sec-title {
  margin-bottom: 29px;
}
.why-choose-one .sec-title__tagline {
  margin-bottom: 23px;
}
.why-choose-one__text {
  text-transform: capitalize;
  margin-bottom: 30px;
}
.why-choose-one__hand__one {
  position: absolute;
  left: 0;
  bottom: 176px;
  animation: slowXmove 6s ease infinite;
}
@media (max-width: 1680px) {
  .why-choose-one__hand__one {
    width: 230px;
  }
}
@media (max-width: 1599px) {
  .why-choose-one__hand__one {
    display: none;
  }
}
.why-choose-one__hand__two {
  position: absolute;
  top: 298px;
  right: 0;
  animation: slowXmove 6s ease infinite;
}
@media (max-width: 1680px) {
  .why-choose-one__hand__two {
    width: 190px;
  }
}
@media (max-width: 1599px) {
  .why-choose-one__hand__two {
    top: 10px;
    bottom: auto;
    width: 190px;
  }
}
@media (max-width: 767px) {
  .why-choose-one__hand__two {
    display: none;
  }
}

/*--------------------------------------------------------------
# Funfact
--------------------------------------------------------------*/
.funfact-one {
  background-color: var(--cleenhearts-base, #351c42);
  /* background-image: url(../images/shapes/funfact-bg-1-1.jpg); */
  background-size: cover;
  background-position: center center;
  padding-top: 80px;
  padding-bottom: 80px;
}
@media (min-width: 1200px) {
  .funfact-one {
    padding-top: 73.5px;
    padding-bottom: 73.5px;
  }
}
.funfact-one__list {
  margin: 0;
}
@media (min-width: 768px) {
  .funfact-one__list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
  }
}
@media (min-width: 1200px) {
  .funfact-one__list {
    justify-content: space-between;
    gap: 0;
  }
}
.funfact-one__item {
  display: flex;
  align-items: center;
  margin-top: -6px;
  position: relative;
}
.funfact-one__item:not(:first-of-type)::before {
  content: "";
  width: 1px;
  height: 67px;
  background-color: var(--cleenhearts-white, #fff);
  position: absolute;
  top: 50%;
  left: -57px;
  transform: translateY(-50%);
  opacity: 0.3;
  display: none;
}
@media (min-width: 1200px) {
  .funfact-one__item:not(:first-of-type)::before {
    display: block;
  }
}
@media (min-width: 768px) {
  .funfact-one__item {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 1200px) {
  .funfact-one__item {
    flex: 0 0 auto;
    max-width: none;
  }
}
.funfact-one__item:hover .funfact-one__icon {
  transform: rotateY(360deg);
}
.funfact-one__item + .funfact-one__item {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .funfact-one__item + .funfact-one__item {
    margin-top: 0;
  }
}
.funfact-one__icon {
  font-size: 60px;
  color: var(--cleenhearts-white, #fff);
  margin-right: 20px;
  flex-shrink: 0;
  -webkit-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.funfact-one__count {
  margin: 0;
  color: var(--cleenhearts-white, #fff);
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 35px;
  font-weight: 500;
  line-height: 1;
}
@media (min-width: 768px) {
  .funfact-one__count {
    font-size: 40px;
  }
}
.funfact-one__text {
  margin: 0;
  color: var(--cleenhearts-white, #fff);
  font-size: 16px;
  line-height: 36px;
  margin-top: 7px;
  margin-bottom: -6px;
}

.funfact-two {
  position: relative;
  background-color: var(--cleenhearts-base, #351c42);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 120px 0;
}
.funfact-two__shape {
  position: absolute;
  left: -10px;
  top: 0;
}
.funfact-two__shape img {
  animation: shapeMove 3s linear 0s infinite;
}
@media (max-width: 767px) {
  .funfact-two {
    padding: 80px 0;
  }
}
.funfact-two .sec-title {
  padding-bottom: 33px;
}
.funfact-two .sec-title__tagline,
.funfact-two .sec-title__title {
  color: var(--cleenhearts-white, #fff);
}
.funfact-two__list {
  position: relative;
  background-color: var(--cleenhearts-white, #fff);
  margin: 0 0 0 -7px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0;
}
@media (max-width: 991px) {
  .funfact-two__list {
    margin: 50px 0 0;
  }
}
.funfact-two__list__icon {
  width: 94px;
  height: 94px;
  background-color: var(--cleenhearts-base, #351c42);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.funfact-two__list__icon img {
  height: 65px;
  transform: scale(1);
  transition: 500ms ease;
}
@media (max-width: 767px) {
  .funfact-two__list__icon {
    display: none;
  }
}
.funfact-two__list:hover .funfact-two__list__icon img {
  transform: scale(0.9);
}
.funfact-two__item {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 42px 58px;
}
.funfact-two__item:nth-child(1),
.funfact-two__item:nth-child(3) {
  border-right: 1px solid var(--cleenhearts-base, #351c42);
}
.funfact-two__item:nth-child(1),
.funfact-two__item:nth-child(2) {
  border-bottom: 1px solid var(--cleenhearts-base, #351c42);
}
@media (max-width: 1199px) {
  .funfact-two__item {
    padding-left: 30px;
    padding-right: 25px;
  }
}
@media (max-width: 991px) {
  .funfact-two__item {
    padding-left: 50px;
  }
}
@media (max-width: 767px) {
  .funfact-two__item {
    padding-left: 50px;
    flex: 0 0 100%;
    max-width: 100%;
    border-bottom: 1px solid var(--cleenhearts-base, #351c42);
  }
}
.funfact-two__count {
  margin: 0;
  color: var(--cleenhearts-dark, #24102f);
  font-size: 40px;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0px;
}
@media (min-width: 768px) {
  .funfact-two__count {
    font-size: 50px;
  }
}
.funfact-two__text {
  margin: 0;
  font-size: 18px;
  margin: 0;
}

.funfact-three {
  position: relative;
  padding: 100px 0;
}
@media (max-width: 767px) {
  .funfact-three {
    padding: 80px 0 50px;
  }
}
.funfact-three__bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: var(--cleenhearts-gray, #888888);
}
@media (min-width: 1400px) {
  .funfact-three__bg {
    width: calc(100% - 240px);
    left: 120px;
  }
}
.funfact-three__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0;
}
@media (max-width: 767px) {
  .funfact-three__list {
    display: block;
  }
}
.funfact-three__item {
  flex: 0 0 25%;
  max-width: 25%;
  position: relative;
}
@media (max-width: 991px) {
  .funfact-three__item {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 0 30px !important;
  }
}
@media (max-width: 767px) {
  .funfact-three__item {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 0 30px !important;
    text-align: center;
  }
}
.funfact-three__item:not(:last-of-type)::before {
  content: "";
  width: 1px;
  height: 100%;
  background-color: var(--cleenhearts-border-color, #d7d3cb);
  position: absolute;
  top: 0;
  right: 54px;
}
@media (max-width: 991px) {
  .funfact-three__item:not(:last-of-type)::before {
    right: 50px !important;
  }
}
@media (max-width: 767px) {
  .funfact-three__item:not(:last-of-type)::before {
    display: none;
  }
}
.funfact-three__item:not(:first-of-type) {
  padding-left: 37px;
}
@media (max-width: 1199px) {
  .funfact-three__item:not(:first-of-type) {
    padding-left: 0;
  }
}
.funfact-three__item:nth-child(2)::before {
  right: 16px;
}
@media (max-width: 991px) {
  .funfact-three__item:nth-child(2)::before {
    display: none;
  }
}
.funfact-three__item:nth-child(3)::before {
  right: -20px;
}
.funfact-three__item:nth-child(3) {
  padding-left: 63px;
}
@media (max-width: 1199px) {
  .funfact-three__item:nth-child(3) {
    padding-left: 40px;
  }
}
.funfact-three__item:last-child {
  padding-left: 104px;
}
@media (max-width: 1199px) {
  .funfact-three__item:last-child {
    padding-left: 70px;
  }
}
.funfact-three__item:hover .funfact-three__icon::before {
  transform: scale(0.9);
}
.funfact-three__icon {
  width: 103px;
  height: 104px;
  background-color: var(--cleenhearts-base, #351c42);
  font-size: 60px;
  color: var(--cleenhearts-white, #fff);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 13px;
}
@media (max-width: 767px) {
  .funfact-three__icon {
    margin-left: auto;
    margin-right: auto;
  }
}
.funfact-three__icon::before {
  transition: all 500ms linear;
  transition-delay: 0s;
  transition-delay: 0s;
  transition-delay: 0s;
  transition-delay: 0s;
  transition-delay: 0.1s;
  transform: scale(1);
}
.funfact-three__count {
  margin: 0;
  color: var(--cleenhearts-dark, #24102f);
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 35px;
  font-weight: 500;
  line-height: 1;
}
@media (min-width: 768px) {
  .funfact-three__count {
    font-size: 40px;
  }
}
.funfact-three__text {
  margin: 0;
  color: var(--cleenhearts-text, #757277);
  font-size: 16px;
  margin-top: 9px;
  margin-bottom: -6px;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials-one {
  position: relative;
  background-color: var(--cleenhearts-base, #351c42);
}
.testimonials-one__bg {
  position: absolute;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.15;
}
.testimonials-one__wrapper {
  position: relative;
  z-index: 2;
  border-radius: 20px;
  border: 1px solid var(--cleenhearts-white, #fff);
  overflow: hidden;
}
.testimonials-one__wrapper .testimonials-card__bg {
  border-radius: 0;
}
.testimonials-one__top {
  padding: 60px 58px 0;
  margin-bottom: 58px;
}
@media (max-width: 1199px) {
  .testimonials-one__top {
    padding: 40px 40px 0;
  }
}
@media (max-width: 575px) {
  .testimonials-one__top {
    padding: 30px 30px 0;
  }
}
.testimonials-one .sec-title {
  margin-bottom: 0;
}
.testimonials-one .sec-title__tagline {
  color: var(--cleenhearts-white, #fff);
  margin-bottom: 23px;
}
.testimonials-one .sec-title__tagline::before {
  background-color: var(--cleenhearts-white, #fff);
}
.testimonials-one .sec-title__tagline::after {
  border-color: var(--cleenhearts-white, #fff);
}
.testimonials-one .sec-title__title {
  color: var(--cleenhearts-white, #fff);
}
.testimonials-one__image img {
  border-radius: 100px;
  max-width: 100%;
}
.testimonials-one__carousel .owl-dots {
  margin-top: 0 !important;
  position: absolute;
  top: 60px;
  right: 50px;
}
@media (max-width: 991px) {
  .testimonials-one__carousel .owl-dots {
    top: 49px;
    right: 40px;
  }
}
@media (max-width: 767px) {
  .testimonials-one__carousel .owl-dots {
    top: 40px;
  }
}
@media (max-width: 425px) {
  .testimonials-one__carousel .owl-dots {
    right: 30px;
  }
}
@media (max-width: 392px) {
  .testimonials-one__carousel .owl-dots {
    right: 25px;
  }
}
.testimonials-one .testimonials-card {
  padding: 60px;
}
@media (max-width: 991px) {
  .testimonials-one .testimonials-card {
    padding: 40px;
  }
}
@media (max-width: 767px) {
  .testimonials-one .testimonials-card {
    padding-top: 90px;
  }
}
@media (max-width: 425px) {
  .testimonials-one .testimonials-card {
    padding: 90px 30px 30px;
  }
}
@media (max-width: 392px) {
  .testimonials-one .testimonials-card {
    padding: 90px 25px 25px;
  }
}
.testimonials-one .testimonials-card__info__left {
  padding-right: 45px;
}
.testimonials-one .testimonials-card__content {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
  gap: 40px 50px;
}
@media (max-width: 767px) {
  .testimonials-one .testimonials-card__content {
    flex-direction: column;
  }
}
.testimonials-one .testimonials-card__text {
  width: 100%;
  max-width: 810px;
  padding-right: 0;
  margin-top: 18px;
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .testimonials-one .testimonials-card__text {
    margin-top: 8px;
  }
}
.testimonials-one .testimonials-card__quote {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 144px;
  height: 144px;
  background-color: var(--cleenhearts-white2, #efece7);
  border-radius: 50%;
  transition: all 400ms ease;
}
.testimonials-one .testimonials-card__quote span {
  transition: all 400ms ease;
}
@media (max-width: 991px) {
  .testimonials-one .testimonials-card__quote {
    width: 80px;
    height: 80px;
  }
  .testimonials-one .testimonials-card__quote span {
    font-size: 30px;
  }
}

.testimonials-one .testimonials-card:hover .testimonials-card__quote span {
  color: var(--cleenhearts-white, #fff);
}

.testimonials-one__shape__one,
.testimonials-one__shape__two {
  position: absolute;
  animation: zumpX 1500ms infinite alternate;
}
.testimonials-one__shape__one {
  top: 0;
  left: 0;
}
@media (max-width: 1599px) {
  .testimonials-one__shape__one {
    top: -50px;
  }
}
.testimonials-one__shape__two {
  right: 0;
  bottom: 0;
}
@media (max-width: 1599px) {
  .testimonials-one__shape__two {
    bottom: -50px;
  }
}

.testimonials-two {
  position: relative;
  background-color: var(--cleenhearts-white, #fff);
}
.testimonials-two__top {
  padding: 60px 60px 59px;
  border-radius: 20px;
  border: 1px solid var(--cleenhearts-white3, #d7d3cb);
  margin-bottom: 30px;
}
@media (max-width: 1199px) {
  .testimonials-two__top {
    padding: 40px 40px 39px;
  }
}
@media (max-width: 575px) {
  .testimonials-two__top {
    padding: 30px 30px 29px;
  }
}
.testimonials-two .sec-title {
  width: 100%;
  max-width: 570px;
  margin-bottom: 0;
}
.testimonials-two .sec-title__tagline {
  margin-bottom: 23px;
}
.testimonials-two__text {
  padding-left: 39px;
  text-transform: capitalize;
  margin-bottom: 0;
  margin-top: -4px;
}
@media (max-width: 1199px) {
  .testimonials-two__text {
    padding-left: 15px;
  }
}
@media (max-width: 991px) {
  .testimonials-two__text {
    padding-left: 0;
  }
}
.testimonials-two .testimonials-card__name {
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
}

.testimonials-three {
  padding: 130px 0;
  position: relative;
}
@media (max-width: 767px) {
  .testimonials-three {
    padding: 80px 0;
  }
}
.testimonials-three .sec-title {
  margin-bottom: 37px;
}
.testimonials-three__content {
  position: relative;
}
.testimonials-three__content__text {
  text-transform: capitalize;
  margin-bottom: 49px;
}
.testimonials-three__carousel .owl-stage {
  padding-left: 0 !important;
}
@media (max-width: 1199px) {
  .testimonials-three__carousel {
    padding: 0 15px;
  }
}
.testimonials-three__custome-navs {
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 1199px) {
  .testimonials-three__custome-navs {
    margin: 0 0 50px;
  }
}
.testimonials-three__custome-navs button {
  border: 0;
  outline: 0;
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: var(--cleenhearts-white2, #efece7);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--cleenhearts-base, #351c42);
  border-radius: 50%;
  font-size: 15px;
  color: var(--cleenhearts-base, #351c42);
  transition: all 500ms ease;
}
.testimonials-three__custome-navs button:hover {
  background-color: var(--cleenhearts-purple, #965995);
  color: var(--cleenhearts-white, #fff);
}
.testimonials-three .testimonials-card {
  background: var(--cleenhearts-white, #fff);
  border-radius: 20px;
  border: 3px solid var(--cleenhearts-border-color, #d7d3cb);
}
.testimonials-three .testimonials-card__bg {
  display: none;
}

/*--------------------------------------------------------------
# Video
--------------------------------------------------------------*/
.video-one {
  padding: 192px 0;
  position: relative;
}
@media (max-width: 767px) {
  .video-one {
    padding: 115px 0;
  }
}
.video-one__bg {
  position: relative;
  position: absolute;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.video-one__bg::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: RGBA(var(--cleenhearts-base-rgb, 53, 28, 66), 0.8);
}
.video-one .container {
  position: relative;
  z-index: 2;
}
.video-one__title {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  font-size: 150px;
  text-align: center;
  line-height: 0.666;
  letter-spacing: -6px;
  text-transform: capitalize;
  color: RGBA(var(--cleenhearts-base-rgb, 53, 28, 66), 1);
  text-shadow: 1px 1px 0 var(--cleenhearts-white, #fff),
    -1px -1px 0 var(--cleenhearts-white, #fff),
    1px -1px 0 var(--cleenhearts-white, #fff),
    -1px 1px 0 var(--cleenhearts-white, #fff),
    1px 1px 0 var(--cleenhearts-white, #fff);
  z-index: 1;
}
.video-one__title__one {
  color: RGBA(var(--cleenhearts-purple-rgb, 150, 89, 149), 1);
}
@media (max-width: 1199px) {
  .video-one__title {
    font-size: 126px;
  }
}
@media (max-width: 991px) {
  .video-one__title {
    font-size: 80px;
  }
  .video-one__title__two {
    color: RGBA(var(--cleenhearts-purple-rgb, 150, 89, 149), 1);
  }
}
@media (max-width: 767px) {
  .video-one__title {
    display: none;
  }
}
.video-one__button {
  width: 231px;
  height: 231px;
  background-color: var(--cleenhearts-purple, #965995);
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
@media (max-width: 767px) {
  .video-one__button {
    width: 150px;
    height: 150px;
  }
}
.video-one__button::after {
  content: "";
  width: calc(100% + 120px);
  height: calc(100% + 120px);
  position: absolute;
  top: -60px;
  left: -60px;
  border-radius: 50%;
  border: 1px dashed RGBA(var(--cleenhearts-white-rgb, 255, 255, 255), 0.25);
  animation-duration: 1500ms;
  animation: rotated 10s infinite linear;
  transition: 500ms all ease;
  animation-play-state: running;
}
.video-one__button:hover::after {
  border-color: rgba(var(--cleenhearts-secondary-rgb, 246, 212, 105), 0.7);
}
.video-one__button span {
  position: relative;
  z-index: 99;
  font-size: 65px;
  color: var(--cleenhearts-secondary, #f6d469);
}
@media (max-width: 767px) {
  .video-one__button span {
    font-size: 35px;
  }
}
.video-one__button .video-button__ripple::before,
.video-one__button .video-button__ripple::after {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 0 rgba(var(--cleenhearts-secondary-rgb, 246, 212, 105), 0.6);
}

/*--------------------------------------------------------------
# About Info
--------------------------------------------------------------*/
.about-info {
  position: relative;
  background-color: var(--cleenhearts-white, #fff);
}
.about-info__item {
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  border-radius: 10px;
  transition: all 400ms ease;
}
.about-info__item:hover {
  border-color: var(--cleenhearts-purple, #965995);
}
.about-info__icon {
  margin-top: 29px;
  margin-left: 29px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cleenhearts-white2, #efece7);
  width: 74px;
  height: 74px;
  z-index: 1;
  border-radius: 50%;
  transition: transform 400ms ease;
}
@media (max-width: 767px) {
  .about-info__icon {
    margin-top: 24px;
    margin-left: 24px;
  }
}
.about-info__icon::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform: scale(0);
  opacity: 0;
  transition: transform 400ms ease, opacity 400ms ease;
  background-color: var(--cleenhearts-purple, #965995);
}
.about-info__icon span {
  font-size: 40px;
  color: var(--cleenhearts-purple, #965995);
  transition: all 400ms ease;
}
.about-info__item:hover .about-info__icon {
  transform: scale(1.1);
}
.about-info__item:hover .about-info__icon::after {
  opacity: 1;
  transform: scale(1);
}
.about-info__item:hover .about-info__icon span {
  color: var(--cleenhearts-white, #fff);
}
.about-info__content {
  margin-top: 15px;
  padding: 6px 29px 28px;
}
@media (max-width: 767px) {
  .about-info__content {
    padding: 6px 24px 23px;
  }
}
.about-info__counter {
  margin-bottom: 10px;
  display: flex;
  align-items: flex-end;
}
.about-info__counter__text {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 22px;
  line-height: 26px;
  letter-spacing: -0.66px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.about-info__title {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  font-weight: 400;
  color: var(--cleenhearts-text, #757277);
  line-height: 26px;
  letter-spacing: -0.48px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.about-info--home-three {
  padding: 0;
  position: absolute;
  top: -119px;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: transparent;
}
@media (max-width: 1199px) {
  .about-info--home-three {
    position: static;
    padding-bottom: 160px;
  }
}
@media (max-width: 767px) {
  .about-info--home-three {
    padding-bottom: 140px;
  }
}
@media (max-width: 575px) {
  .about-info--home-three {
    padding-bottom: 110px;
  }
}
@media (max-width: 412px) {
  .about-info--home-three {
    padding-bottom: 105px;
  }
}
.about-info--home-three .about-info__icon {
  background-color: var(--cleenhearts-white, #fff);
}
@media (max-width: 1199px) {
  .about-info--home-three .about-info__icon {
    background-color: var(--cleenhearts-white2, #efece7);
  }
}
.about-info--home-three .about-info__item {
  border-color: var(--cleenhearts-white, #fff);
}
@media (max-width: 1199px) {
  .about-info--home-three .about-info__item {
    border-color: var(--cleenhearts-border-color, #d7d3cb);
  }
  .about-info--home-three .about-info__item:hover {
    border-color: var(--cleenhearts-purple, #965995);
  }
}
.about-info--home-three .about-info__content {
  padding-top: 21px;
  background-color: var(--cleenhearts-white2, #efece7);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  mix-blend-mode: multiply;
  border-radius: 0 0 10px 10px;
}

.about-info-two__wrapper {
  padding: 22px 27px;
  border-radius: 20px 20px 0px 0px;
  position: relative;
  background-color: var(--cleenhearts-secondary, #f6d469);
}
@media (max-width: 991px) {
  .about-info-two__wrapper {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.about-info-two__bg {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 20px 20px 0px 0px;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--cleenhearts-secondary, #f6d469);
  mix-blend-mode: luminosity;
}
.about-info-two .row {
  position: relative;
  z-index: 1;
  align-items: center;
}
@media (max-width: 991px) {
  .about-info-two__logo {
    text-align: center;
  }
}
.about-info-two__volunteer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}
.about-info-two .cleenhearts-btn {
  padding-top: 15px;
  padding-bottom: 15px;
}
.about-info-two .cleenhearts-btn__icon-box {
  background-color: var(--cleenhearts-purple, #965995);
}
.about-info-two__social {
  justify-content: flex-end;
}
@media (max-width: 991px) {
  .about-info-two__social {
    justify-content: center;
  }
}

/*--------------------------------------------------------------
# Service
--------------------------------------------------------------*/
.service-one {
  position: relative;
  background-color: var(--cleenhearts-white, #fff);
  padding-bottom: 115px;
  counter-reset: count;
}
@media (max-width: 767px) {
  .service-one {
    padding-bottom: 75px;
  }
}
.service-one__top {
  margin-bottom: 53px;
}
.service-one .sec-title {
  width: 100%;
  max-width: 570px;
  margin-bottom: 0;
}
.service-one__text {
  width: 100%;
  max-width: 371px;
  margin-left: auto;
}
@media (max-width: 1199px) {
  .service-one__text {
    max-width: 400px;
  }
}
@media (max-width: 991px) {
  .service-one__text {
    margin-left: 0;
  }
}
.service-one__text__inner {
  text-transform: capitalize;
  margin-bottom: 0;
  margin-top: -5px;
}
@media (max-width: 500px) {
  .service-one__row {
    --bs-gutter-y: 15px;
  }
}
.service-one__content {
  position: relative;
  display: block;
}
@media (max-width: 991px) {
  .service-one__content--left
    .service-one__content__inner:last-child
    .service-one__item {
    border-bottom: 1px solid var(--cleenhearts-border-color, #d7d3cb);
    padding-bottom: 20px;
  }
}
@media (max-width: 991px) and (max-width: 500px) {
  .service-one__content--left
    .service-one__content__inner:last-child
    .service-one__item {
    padding-bottom: 15px;
  }
}
.service-one__content__inner {
  position: relative;
  display: block;
  counter-increment: count;
}
.service-one__content__inner:not(:last-child) .service-one__item {
  border-bottom: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
.service-one__content__inner:not(:last-child) .service-one__item:hover {
  border-color: var(--cleenhearts-purple, #965995);
}
.service-one__content__inner:first-child .service-one__item {
  padding-top: 0;
}
.service-one__content__inner:last-child .service-one__item {
  padding-bottom: 0;
}
.service-one__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 17px 0;
  transition: all 400ms ease;
}
@media (max-width: 500px) {
  .service-one__item {
    padding: 15px 0;
  }
}
.service-one__item__box {
  position: absolute;
  width: 220px;
  height: 220px;
  top: 50%;
  left: 0;
  pointer-events: none;
  border-radius: 50%;
  overflow: hidden;
  opacity: 0;
  transition: all 0.5s ease-out;
  z-index: 1;
}
@media (max-width: 767px) {
  .service-one__item__box {
    width: 200px;
    height: 200px;
  }
}
@media (max-width: 500px) {
  .service-one__item__box {
    width: 150px;
    height: 150px;
  }
}
.service-one__item__box__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  transition: transform 400ms ease-out;
}
.service-one__left {
  display: flex;
  align-items: center;
}
.service-one__number {
  margin: 0;
  position: relative;
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.9px;
  display: block;
  min-width: 61px;
  min-height: 25px;
  color: RGBA(var(--cleenhearts-white-rgb, 255, 255, 255), 1);
  text-shadow: 1px 1px 0 var(--cleenhearts-white3, #d7d3cb),
    -1px -1px 0 var(--cleenhearts-white3, #d7d3cb),
    1px -1px 0 var(--cleenhearts-white3, #d7d3cb),
    -1px 1px 0 var(--cleenhearts-white3, #d7d3cb),
    1px 1px 0 var(--cleenhearts-white3, #d7d3cb);
  transition: all 400ms ease;
}
.service-one__number::before {
  content: counters(count, ".", decimal-leading-zero);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 1199px) {
  .service-one__number {
    font-size: 25px;
  }
}
@media (max-width: 500px) {
  .service-one__number {
    font-size: 20px;
    min-width: 41px;
  }
}
.service-one__item:hover .service-one__number {
  text-shadow: 1px 1px 0 var(--cleenhearts-purple, #965995),
    -1px -1px 0 var(--cleenhearts-purple, #965995),
    1px -1px 0 var(--cleenhearts-purple, #965995),
    -1px 1px 0 var(--cleenhearts-purple, #965995),
    1px 1px 0 var(--cleenhearts-purple, #965995);
}
.service-one__title {
  color: var(--cleenhearts-base, #351c42);
  font-size: 30px;
  line-height: 1;
  letter-spacing: -0.9px;
  text-transform: capitalize;
  margin-bottom: 0;
  transition: all 400ms ease;
}
@media (max-width: 1199px) {
  .service-one__title {
    font-size: 25px;
  }
}
@media (max-width: 500px) {
  .service-one__title {
    font-size: 20px;
  }
}
.service-one__item:hover .service-one__title {
  color: var(--cleenhearts-purple, #965995);
}
.service-one__right a {
  font-size: 30px;
  color: var(--cleenhearts-white3, #d7d3cb);
  transition: all 400ms ease;
}
@media (max-width: 500px) {
  .service-one__right a {
    font-size: 20px;
  }
}
.service-one__right a span {
  color: inherit;
}
.service-one__item:hover .service-one__right a {
  color: var(--cleenhearts-black, #000000);
}

/*--------------------------------------------------------------
# Support
--------------------------------------------------------------*/
.support-one {
  position: relative;
  z-index: 1;
}
.support-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--cleenhearts-white2, #efece7);
  z-index: 1;
}
.support-one .container {
  position: relative;
  z-index: 11;
}
.support-one .sec-title {
  width: 100%;
  max-width: 537px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.support-one .sec-title__tagline {
  margin-left: auto;
  margin-right: auto;
}
.support-one .sec-title__title {
  text-transform: none;
}
.support-one__shape__one {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  animation: slowXmove 6s ease infinite;
}
@media (max-width: 1399px) {
  .support-one__shape__one {
    width: 300px;
  }
}
@media (max-width: 1199px) {
  .support-one__shape__one {
    width: 220px;
  }
}
@media (max-width: 991px) {
  .support-one__shape__one {
    width: 210px;
  }
}
@media (max-width: 767px) {
  .support-one__shape__one {
    display: none;
  }
}
.support-one__shape__two {
  position: absolute;
  right: 0;
  bottom: 10px;
  z-index: -1;
  animation: slowXmove 6s ease infinite;
}
@media (max-width: 1799px) {
  .support-one__shape__two {
    width: 250px;
  }
}
@media (max-width: 767px) {
  .support-one__shape__two {
    display: none;
  }
}

.support-card {
  display: flex;
  align-items: center;
  gap: 30px;
  height: 100%;
}
@media (max-width: 575px) {
  .support-card {
    flex-direction: column;
    height: auto;
  }
  .support-card--direction .support-card__image {
    order: 0;
  }
  .support-card--direction .support-card__content {
    order: 1;
  }
}
.support-card__image {
  width: 50%;
  position: relative;
  display: block;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
}
@media (max-width: 575px) {
  .support-card__image {
    width: 100%;
    height: auto;
  }
}
.support-card__image::after {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0) 0%,
    rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0.2) 100%
  );
  background: linear-gradient(
    to right,
    rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0) 0%,
    rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0.2) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
.support-card__image img {
  width: 100%;
  display: block;
  border-radius: 20px;
  height: 100%;
  object-fit: cover;
  transition: all 500ms ease;
}
.support-card:hover .support-card__image::after {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}
.support-card:hover .support-card__image img {
  transform: scale(1.1);
}
.support-card__content {
  width: 50%;
  position: relative;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cleenhearts-white2, #efece7);
  border-radius: 20px;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 575px) {
  .support-card__content {
    width: 100%;
    min-height: 270px;
  }
}
.support-card__content::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--cleenhearts-base, #351c42);
  z-index: -1;
  position: absolute;
  top: 100%;
  left: 100%;
  border-radius: 20px;
  transition: all 400ms ease;
}
.support-card__content__inner {
  text-align: center;
}
.support-card:hover .support-card__content::after {
  top: 0%;
  left: 0%;
}
.support-card__title {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 24px;
  line-height: 1.25;
  text-transform: capitalize;
  margin-bottom: 15px;
  transition: all 0.4s ease-in-out;
}
.support-card:hover .support-card__title {
  color: var(--cleenhearts-white, #fff);
}
.support-card__collected {
  display: inline-block;
  padding: 10px;
  background-color: var(--cleenhearts-purple, #965995);
  color: var(--cleenhearts-white2, #efece7);
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  text-transform: capitalize;
  border-radius: 100px;
  transition: all 0.4s ease-in-out;
}
.support-card:hover .support-card__collected {
  color: var(--cleenhearts-base, #351c42);
  background-color: var(--cleenhearts-secondary, #f6d469);
}

.support-one--pricing .support-card__content {
  background-color: var(--cleenhearts-white, #fff);
}

/*--------------------------------------------------------------
# Gallery
--------------------------------------------------------------*/
.gallery-one__top {
  margin-bottom: 65px;
}
.gallery-one__top .owl-stage-outer {
  border-radius: 20px;
}
.gallery-one__top__image {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 465px;
  width: 100%;
  transform: scale(1);
  transition: transform 10000ms ease, -webkit-transform 10000ms ease;
  overflow: hidden;
  border-radius: 20px;
}
@media (max-width: 500px) {
  .gallery-one__top__image {
    height: 380px;
  }
}
.active .gallery-one__top__image {
  transform: scale(1.2);
}
.gallery-one__top .owl-nav {
  margin-top: 0;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 70px);
}
.gallery-one__top .owl-nav::before,
.gallery-one__top .owl-nav::after {
  display: none;
}
@media (max-width: 767px) {
  .gallery-one__top .owl-nav {
    width: calc(100% - 40px);
  }
}
@media (max-width: 500px) {
  .gallery-one__top .owl-nav {
    width: calc(100% - 20px);
  }
}
@media (min-width: 992px) {
  .gallery-one__carousel .owl-nav {
    display: none;
  }
}
.gallery-one__filter__list {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  flex-wrap: wrap;
}
.gallery-one__filter__list li + li span {
  border-left: 1px solid var(--cleenhearts-secondary, #f6d469);
}
.gallery-one__filter__list li {
  cursor: pointer;
}
.gallery-one__filter__list li span {
  display: block;
  font-size: 16px;
  background-color: var(--cleenhearts-base, #351c42);
  color: var(--cleenhearts-white, #fff);
  transition: all 500ms ease;
  text-transform: capitalize;
  font-weight: 500;
  padding: 15px 20px;
  line-height: 1.2em;
}
.gallery-one__filter__list li.active span,
.gallery-one__filter__list li:hover span {
  background-color: var(--cleenhearts-secondary, #f6d469);
  color: var(--cleenhearts-base, #351c42);
}
.gallery-one__card {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
}
.gallery-one__card img {
  display: block;
  width: 100%;
  transition: transform 500ms ease, opacity 500ms ease;
  opacity: 1;
  border-radius: 20px;
}
.gallery-one__card__hover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(var(--cleenhearts-base-rgb, 53, 28, 66), 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1, 0);
  transition: transform 500ms ease;
  transform-origin: bottom center;
  border-radius: 20px;
}
.gallery-one__card__hover .img-popup {
  position: relative;
}
.gallery-one__card:hover img {
  transform: scale(1.05);
}
.gallery-one__card:hover .gallery-one__card__hover {
  transform-origin: top center;
  transform: scale(1, 1);
}
.gallery-one__card__icon {
  width: 30px;
  height: 30px;
  display: block;
  position: relative;
}
.gallery-one__card__icon::after,
.gallery-one__card__icon::before {
  content: "";
  width: 2px;
  height: 100%;
  background-color: var(--cleenhearts-white, #fff);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50px;
}
.gallery-one__card__icon::after {
  transform: translate(-50%, -50%) rotate(90deg);
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/
.sidebar-one {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  transform: translateX(100%);
  transform-origin: right center;
  transition: transform 500ms ease 500ms, visibility 500ms ease 500ms,
    -webkit-transform 500ms ease 500ms;
}
.sidebar-one.active {
  opacity: 1;
  transform: translateX(0%);
  visibility: visible;
  transition: transform 500ms ease 0ms, visibility 500ms ease 0ms,
    -webkit-transform 500ms ease 0ms;
}
.sidebar-one__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--cleenhearts-dark, #24102f);
  opacity: 0.7;
}
.sidebar-one__content {
  width: 360px;
  background-color: var(--cleenhearts-dark, #24102f);
  z-index: 10;
  position: relative;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  padding-top: 45px;
  padding-bottom: 30px;
  padding-left: 35px;
  padding-right: 35px;
  opacity: 0;
  visibility: hidden;
  transform: translateX(100%);
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms,
    transform 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
  /* hide scrollbar but allow scrolling */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.sidebar-one__content::-webkit-scrollbar {
  display: none;
  /* for Chrome, Safari, and Opera */
}
.sidebar-one.active .sidebar-one__content {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms,
    transform 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
}
.sidebar-one__close {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 24px;
  color: var(--cleenhearts-white, #fff);
  cursor: pointer;
}
.sidebar-one__close:hover {
  color: var(--cleenhearts-secondary, #f6d469);
}
.sidebar-one__item:not(:last-child) {
  margin-bottom: 40px;
}
.sidebar-one__title {
  font-size: 20px;
  color: var(--cleenhearts-white, #fff);
  line-height: 1.5;
  margin-bottom: 15px;
}
.sidebar-one__about__text {
  color: var(--cleenhearts-white2, #efece7);
  text-transform: capitalize;
  margin-bottom: 0;
}
.sidebar-one__information__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sidebar-one__information__list li {
  display: flex;
  align-items: flex-start;
}
.sidebar-one__information__list li:not(:last-child) {
  margin-bottom: 10px;
}
.sidebar-one__information__icon {
  position: relative;
  top: 7px;
  font-size: 16px;
  color: var(--cleenhearts-secondary, #f6d469);
  margin-right: 10px;
  line-height: normal;
}
.sidebar-one__information__icon.icon-envelope {
  top: 9px;
  font-size: 14px;
}
.sidebar-one__information__address {
  margin-bottom: 0;
}
.sidebar-one__information__text {
  color: var(--cleenhearts-white2, #efece7);
  font-size: 17px;
  margin-bottom: 0;
}
.sidebar-one__information__text a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.sidebar-one__information__text a:hover {
  background-size: 100% 1px;
}
.sidebar-one__information__text a:hover {
  color: var(--cleenhearts-secondary, #f6d469);
}
.sidebar-one__socila a {
  color: var(--cleenhearts-white, #fff);
}
.sidebar-one__socila a:hover {
  color: var(--cleenhearts-secondary, #f6d469);
}
.sidebar-one__socila a + a::after {
  background-color: var(--cleenhearts-border-color2, #414141);
}
.sidebar-one__newsletter__inner {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sidebar-one__newsletter__input {
  padding: 0;
  border: none;
  outline: none;
  color: var(--cleenhearts-white, #fff) !important;
  width: calc(100% - 50px);
  height: 50px;
  padding-left: 15px;
  padding-right: 10px;
  background-color: rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0.08);
}
.sidebar-one__newsletter__input::placeholder {
  color: var(--cleenhearts-white3, #d7d3cb) !important;
}
.sidebar-one__newsletter__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  width: 50px;
  height: 50px;
  background-color: var(--cleenhearts-secondary, #f6d469);
  transition: all 400ms ease;
}
.sidebar-one__newsletter__btn:hover {
  background-color: var(--cleenhearts-purple, #965995);
}
.sidebar-one__newsletter__btn:hover i {
  color: var(--cleenhearts-white, #fff);
}
.sidebar-one__newsletter__btn i {
  position: relative;
  top: 0px;
  font-size: 20px;
  color: var(--cleenhearts-base, #351c42);
  transition: all 400ms ease;
}

.sidebar__single {
  border-radius: 20px;
  background-color: var(--cleenhearts-white2, #efece7);
  padding: 30px;
}
@media (max-width: 1199px) {
  .sidebar__single {
    padding: 30px 25px;
  }
}
.sidebar__single + .sidebar__single {
  margin-top: 30px;
}
.sidebar__title {
  text-transform: capitalize;
  margin: 0;
  font-size: 20px;
  line-height: 1.3;
  margin-top: -5px;
  margin-bottom: 25px;
}
@media (min-width: 768px) {
  .sidebar__title {
    font-size: 22px;
  }
}
.sidebar__form__title {
  margin-bottom: 19px;
}
.sidebar__search {
  display: flex;
  align-items: center;
  position: relative;
  height: 59px;
}
.sidebar__search input[type="search"],
.sidebar__search input[type="text"] {
  padding-left: 20px;
  padding-right: 40px;
  outline: none;
  border: 0;
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background-color: var(--cleenhearts-white, #fff);
  transition: all 500ms ease;
}
.sidebar__search input[type="search"]:focus,
.sidebar__search input[type="text"]:focus {
  border-color: var(--cleenhearts-purple, #965995);
}
.sidebar__search button[type="submit"] {
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: auto;
  font-size: 20px;
  color: var(--cleenhearts-purple, #965995);
  z-index: 11;
}
.sidebar__posts {
  margin-bottom: 0;
}
.sidebar__posts__item {
  display: flex;
}
.sidebar__posts__item:not(:last-of-type) {
  margin-bottom: 29px;
}
.sidebar__posts__image {
  width: 80px;
  flex-shrink: 0;
  margin-right: 16px;
}
.sidebar__posts__image img {
  border-radius: 6px;
}
.sidebar__posts__content {
  position: relative;
  top: -1px;
  width: calc(100% - 80px);
}
.sidebar__posts__meta {
  margin: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 12px;
}
.sidebar__posts__meta a {
  display: inline-flex;
  align-items: center;
  color: var(--cleenhearts-gray, #888888);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.142;
  transition: all 500ms ease;
}
.sidebar__posts__meta a:hover {
  color: var(--cleenhearts-base, #351c42);
  text-shadow: 0 0 1px currentColor;
}
.sidebar__posts__meta a span {
  font-size: 20px;
  color: var(--cleenhearts-purple, #965995);
  margin-right: 10px;
}
.sidebar__posts__title {
  margin: 0;
  text-transform: capitalize;
  color: var(--cleenhearts-base, #351c42);
  font-size: 18px;
  font-weight: 600;
  line-height: 1.555;
}
@media (max-width: 1199px) {
  .sidebar__posts__title {
    font-size: 15px;
  }
}
@media (max-width: 359px) {
  .sidebar__posts__title {
    font-size: 14px;
  }
}
.sidebar__posts__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.sidebar__posts__title a:hover {
  background-size: 100% 1px;
}
.sidebar__categories {
  margin-top: -13px;
  margin-bottom: -12px;
}
.sidebar__categories li:not(:last-child) {
  margin-bottom: 6.5px;
}
.sidebar__categories li a {
  position: relative;
  font-size: 16px;
  color: var(--cleenhearts-base, #351c42);
  text-transform: capitalize;
  font-weight: 500;
  line-height: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 500ms ease;
  padding: 12px 0;
  z-index: 1;
}
.sidebar__categories li a::after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-color: var(--cleenhearts-secondary, #f6d469);
  border-radius: 6px;
  transition: all 500ms ease;
}
.sidebar__categories li a span {
  color: inherit;
}
.sidebar__categories li a span:last-child {
  font-weight: 400;
}
.sidebar__categories li a:hover {
  padding: 12px 12px;
  color: var(--cleenhearts-dark, #24102f);
  text-shadow: 0 0 1px currentColor;
}
.sidebar__categories li a:hover::after {
  left: 0;
  width: 100%;
}
.sidebar__tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.sidebar__tags a {
  background-color: var(--cleenhearts-white3, #d7d3cb);
  font-size: 14px;
  color: var(--cleenhearts-base, #351c42);
  text-transform: capitalize;
  font-weight: 400;
  line-height: 1;
  transition: all 500ms ease;
  display: inline-flex;
  padding: 6px 10.5px;
  border-radius: 100px;
}
.sidebar__tags a:hover {
  color: var(--cleenhearts-base, #351c42);
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.sidebar__comments {
  margin-top: -2px;
  margin-bottom: 0px;
}
.sidebar__comments li {
  display: flex;
  align-items: center;
}
.sidebar__comments li:not(:last-of-type) {
  margin-bottom: 31px;
}
.sidebar__comments__icon {
  position: relative;
  z-index: 1;
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--cleenhearts-white, #fff);
  font-size: 22px;
  color: var(--cleenhearts-purple, #965995);
  margin-right: 30px;
  border-radius: 50%;
  transition: all 500ms ease;
}
@media (max-width: 1199px) {
  .sidebar__comments__icon {
    margin-right: 20px;
  }
}
.sidebar__comments__icon::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform: scale(0);
  opacity: 0;
  transition: transform 400ms ease, opacity 400ms ease;
  background-color: var(--cleenhearts-secondary, #f6d469);
}
li:hover .sidebar__comments__icon {
  color: var(--cleenhearts-base, #351c42);
}
li:hover .sidebar__comments__icon::after {
  transform: scale(1);
  opacity: 1;
}
.sidebar__comments__title {
  margin: 0;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  line-height: 1.562;
  font-weight: 400;
  color: var(--cleenhearts-text, #757277);
  text-transform: capitalize;
  transition: all 500ms ease;
}
.sidebar__comments__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.sidebar__comments__title a:hover {
  background-size: 100% 1px;
}
.sidebar__comments__title a:hover {
  color: var(--cleenhearts-base, #351c42);
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog-one,
.blog-two {
  position: relative;
  background-color: var(--cleenhearts-white, #fff);
}
.blog-one__carousel,
.blog-two__carousel {
  position: relative;
}
.blog-one__carousel .owl-dots,
.blog-two__carousel .owl-dots {
  display: flex !important;
  margin-top: 0 !important;
  position: absolute;
  top: -128px;
  right: 0;
  z-index: 1;
}
@media (max-width: 767px) {
  .blog-one__carousel .owl-dots,
  .blog-two__carousel .owl-dots {
    position: static;
    margin-top: 60px !important;
  }
}
.blog-one--page .blog-two__carousel,
.blog-two--page .blog-two__carousel {
  position: relative;
}
.blog-one--page .blog-two__carousel .owl-dots,
.blog-two--page .blog-two__carousel .owl-dots {
  position: static;
  margin-top: 60px !important;
}

.blog-three .sec-title {
  width: 100%;
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.blog-page {
  background-color: var(--cleenhearts-white, #fff);
}
.blog-page--list .blog-page__pagination {
  justify-content: center;
}
@media (min-width: 992px) {
  .blog-page__carousel .owl-nav {
    display: none;
  }
}
.blog-page__pagination {
  margin-top: 25px;
}
@media (max-width: 767px) {
  .blog-page__pagination {
    margin-top: 15px;
  }
}

/*--------------------------------------------------------------
# Blog details
--------------------------------------------------------------*/
.blog-details .blog-card {
  border: 0;
  padding-bottom: 0;
  margin-bottom: 58px;
}
.blog-details__hall {
  padding: 10px;
  border-radius: 100px;
  position: absolute;
  top: 30px;
  left: 30px;
  background-color: var(--cleenhearts-secondary, #f6d469);
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  font-weight: 700;
  color: var(--cleenhearts-base, #351c42);
  text-transform: capitalize;
  line-height: 1;
  z-index: 11;
}
@media (max-width: 575px) {
  .blog-details__hall {
    top: 20px;
    left: 20px;
  }
}
@media (max-width: 425px) {
  .blog-details__hall {
    top: 15px;
    left: 15px;
  }
}
.blog-details .blog-card__date {
  top: 30px;
  right: 30px;
  left: auto;
}
@media (max-width: 575px) {
  .blog-details .blog-card__date {
    top: 20px;
    right: 20px;
  }
}
@media (max-width: 425px) {
  .blog-details .blog-card__date {
    top: 15px;
    right: 15px;
  }
}
.blog-details .blog-card-four__meta {
  margin-bottom: 37px;
}
.blog-details .blog-card__title {
  margin-bottom: 28px;
}
.blog-details .blog-card-four__text--one {
  margin-bottom: 28.5px;
}
.blog-details .blog-card-four__text--two {
  margin-bottom: 0;
}
.blog-details .blog-card__content {
  text-align: left;
  padding-bottom: 50px;
  border-bottom: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
.blog-details__inner__image {
  margin-top: 40px;
}
.blog-details__inner__image img {
  width: 100%;
  display: block;
  border-radius: 20px;
}
.blog-details__inner__text {
  text-transform: capitalize;
  margin-top: 40px;
  margin-bottom: 26px;
}
.blog-details__inner__list {
  padding: 0;
  margin: 0;
  margin-left: 18px;
}
.blog-details__inner__list li + li {
  margin-top: 8.5px;
}
.blog-details__inner__list li p {
  text-transform: capitalize;
  margin-bottom: 0;
}
.blog-details__meta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  padding-top: 23px;
  padding-bottom: 23px;
  border-top: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  border-bottom: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
.blog-details__meta__title {
  font-size: 20px;
  line-height: 1.5;
  text-transform: capitalize;
  margin-bottom: 0;
}
.blog-details__tags {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.blog-details__tags__box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.blog-details__tags__box a {
  background-color: var(--cleenhearts-white3, #d7d3cb);
  font-size: 14px;
  color: var(--cleenhearts-base, #351c42);
  text-transform: capitalize;
  font-weight: 400;
  line-height: 1;
  transition: all 500ms ease;
  display: inline-flex;
  padding: 6px 10.5px;
  border-radius: 100px;
}
.blog-details__tags__box a:hover {
  color: var(--cleenhearts-base, #351c42);
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.blog-details__social {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

/*--------------------------------------------------------------
# Comments
--------------------------------------------------------------*/
.comments-one {
  margin-top: 48px;
}
@media (max-width: 1399px) {
  .comments-one {
    margin-top: 80px;
  }
}
@media (max-width: 1199px) {
  .comments-one {
    margin-top: 60px;
  }
}
.comments-one__title {
  font-size: 30px;
  text-transform: capitalize;
  margin-bottom: 48px;
  letter-spacing: normal;
}
@media (max-width: 1399px) {
  .comments-one__title {
    margin-bottom: 53px;
  }
}
@media (max-width: 575px) {
  .comments-one__title {
    margin-bottom: 43px;
  }
}
@media (max-width: 425px) {
  .comments-one__title {
    font-size: 26px;
  }
}
@media (max-width: 375px) {
  .comments-one__title {
    font-size: 24px;
  }
}
.comments-one__list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.comments-one__card {
  display: grid;
  grid-template-columns: 96px auto;
  grid-gap: 20px;
  padding-bottom: 42px;
  margin-bottom: 40px;
  border-bottom: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
@media (max-width: 767px) {
  .comments-one__card {
    grid-template-columns: auto;
  }
}
.comments-one__card:last-child {
  margin-bottom: 0;
}
.comments-one__card__image {
  width: 96px;
  height: 96px;
  border-radius: 50%;
}
.comments-one__card__image img {
  width: 96px;
  height: 96px;
  border-radius: 50%;
}
.comments-one__card__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-top: 17px;
  margin-bottom: 24.5px;
}
@media (max-width: 500px) {
  .comments-one__card__top {
    align-items: flex-start;
    flex-direction: column;
  }
}
.comments-one__card__title {
  margin: 0;
  font-size: 24px;
  text-transform: capitalize;
  line-height: 1.333;
  margin-bottom: 4px;
}
@media (max-width: 575px) {
  .comments-one__card__title {
    font-size: 22px;
  }
}
@media (max-width: 425px) {
  .comments-one__card__title {
    font-size: 20px;
  }
}
.comments-one__card__date {
  text-transform: capitalize;
  margin-bottom: 0;
}
.comments-one__card__text {
  text-transform: capitalize;
  margin-bottom: 0;
}
.comments-one__card__reply {
  padding: 10px 10.5px;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-base, #351c42);
  font-weight: normal;
  font-size: 16px;
  border-radius: 100px;
  background-color: var(--cleenhearts-secondary, #f6d469);
  line-height: 1;
  transition: all 0.4s ease-in-out;
}
.comments-one__card__reply:hover {
  color: var(--cleenhearts-white, #fff);
  background-color: var(--cleenhearts-purple, #965995);
}
.comments-one__card__ontent {
  position: relative;
}

.comments-form {
  margin-top: 47px;
}
.comments-form__title {
  font-size: 30px;
  margin-bottom: 53px;
  text-transform: capitalize;
  letter-spacing: normal;
}
@media (max-width: 575px) {
  .comments-form__title {
    margin-bottom: 44px;
  }
}
@media (max-width: 425px) {
  .comments-form__title {
    font-size: 26px;
  }
}
@media (max-width: 375px) {
  .comments-form__title {
    font-size: 24px;
  }
}

/*--------------------------------------------------------------
# Shop
--------------------------------------------------------------*/
.product-page {
  padding-top: 105px;
}
@media (max-width: 767px) {
  .product-page {
    padding-top: 103px;
  }
}
@media (max-width: 575px) {
  .product-page {
    padding-top: 73px;
  }
}
@media (max-width: 991px) {
  .product-page--left {
    padding-top: 130px;
  }
}
@media (max-width: 767px) {
  .product-page--left {
    padding-top: 110px;
  }
}
@media (max-width: 575px) {
  .product-page--left {
    padding-top: 80px;
  }
}
.product-page__pagination {
  margin-top: 25px;
  justify-content: center;
}
@media (max-width: 767px) {
  .product-page__pagination {
    margin-top: 15px;
  }
}
@media (min-width: 992px) {
  .product-page__carousel .owl-nav {
    display: none;
  }
}

.product {
  position: relative;
}
.product__sidebar {
  margin-top: 72px;
  position: relative;
}
@media (max-width: 991px) {
  .product__sidebar {
    margin-top: 0;
  }
}
.product__sidebar__item + .product__sidebar__item {
  margin-top: 30px;
}
.product__sidebar__item {
  padding: 30px;
  background-color: #dadcc7;
  border-radius: 20px;
}
.product__sidebar__title {
  position: relative;
  font-size: 18px;
  line-height: 1.333;
  text-transform: capitalize;
  color: var(--cleenhearts-black, #000000);
  font-weight: 600;
  margin-top: -5px;
  margin-bottom: 15px;
}
.product__search {
  display: flex;
  align-items: center;
  position: relative;
  height: 59px;
}
.product__search input[type="search"],
.product__search input[type="text"] {
  padding-left: 10px;
  padding-right: 35px;
  outline: none;
  border: 0;
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background-color: var(--cleenhearts-white, #fff);
  background-color: var(--cleenhearts-white, #fff);
  transition: all 500ms ease;
}
.product__search input[type="search"]:focus,
.product__search input[type="text"]:focus {
  border-color: var(--cleenhearts-purple, #965995);
}
.product__search button[type="submit"] {
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: auto;
  font-size: 20px;
  color: var(--cleenhearts-purple, #965995);
  z-index: 11;
}
.product__price-ranger {
  padding-top: 32px;
}
.product__price-ranger .price-ranger {
  background-color: var(--cleenhearts-white, #fff);
  border-radius: 10px;
  padding: 16px 21px 6px 9px;
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
.product__price-ranger .price-ranger .ui-slider .ui-slider-handle:nth-child(2) {
  display: none;
}
.product__price-ranger .price-ranger .ui-slider .ui-slider-handle:nth-child(3) {
  position: relative;
  position: absolute;
}
.product__price-ranger
  .price-ranger
  .ui-slider
  .ui-slider-handle:nth-child(3)::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: var(--cleenhearts-white, #fff);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.product__price-ranger #slider-range {
  margin: 0;
  background-color: transparent;
  border: 1px solid var(--cleenhearts-purple, #965995);
  height: 7px;
  border-radius: 10px;
  position: relative;
}
.product__price-ranger #slider-range .ui-slider-range {
  height: 100%;
  background: var(--cleenhearts-purple, #965995);
}
.product__price-ranger #slider-range .ui-slider-handle {
  position: absolute;
  top: -6.5px;
  background: var(--cleenhearts-purple, #965995);
  border: 0;
  height: 18px;
  width: 18px !important;
  border-radius: 50%;
  margin-left: -2px;
  outline: medium none;
  cursor: pointer;
  z-index: 2;
}
.product__price-ranger .ranger-min-max-block {
  position: relative;
  display: block;
  margin-top: 7px;
}
.product__price-ranger .ranger-min-max-block input[type="text"] {
  position: relative;
  display: inline-block;
  color: var(--cleenhearts-text, #757277);
  font-size: 12px;
  font-weight: 400;
  width: 40px;
  line-height: 14px;
  border: none;
  outline: none;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  padding: 0;
  text-align: center;
  background-color: transparent;
}
.product__price-ranger .ranger-min-max-block span {
  position: relative;
  display: inline-block;
  color: var(--cleenhearts-text, #757277);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  left: -2px;
}
.product__categories {
  padding-top: 32px;
}
.product__categories ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: -20px;
}
.product__categories ul li {
  position: relative;
  margin: 0 0 4px;
}
.product__categories ul li a {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  line-height: 1;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--cleenhearts-base, #351c42);
  border-radius: 7px;
  font-weight: 400;
  transition: all 0.3s ease;
  z-index: 1;
  padding: 13.5px 0;
}
.product__categories ul li a > span {
  display: block;
  -webkit-transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.product__categories ul li a::before {
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  line-height: 1;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--cleenhearts-base, #351c42);
  font-weight: 500;
  background-color: var(--cleenhearts-secondary, #f6d469);
  color: var(--cleenhearts-base, #351c42);
  border-radius: 7px;
  -webkit-transform: translate3d(-150%, 0, 0);
  transform: translate3d(-150%, 0, 0);
  z-index: 11;
  -webkit-transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.product__categories ul li a:hover::before {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.product__categories ul li a:hover > span {
  -webkit-transform: translate3d(0, 200%, 0);
  transform: translate3d(0, 200%, 0);
}
.product__categories ul li.active a::after {
  background-color: var(--cleenhearts-secondary, #f6d469);
  color: var(--cleenhearts-base, #351c42);
  font-weight: 600;
}
.product__info-top {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-bottom: 8px;
}
@media (max-width: 425px) {
  .product__info-top {
    display: block;
  }
}
.product__showing-text {
  margin: 0;
  color: var(--cleenhearts-base, #351c42);
}
@media (max-width: 767px) {
  .product__showing-text {
    margin-bottom: 10px;
  }
}
.product__showing-sort {
  display: flex;
  justify-content: end;
  margin: 0;
  font-size: 18px;
}
.product__showing-sort
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  ) {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  position: relative;
  display: block;
  width: 100%;
}
@media (max-width: 767px) {
  .product__showing-sort
    .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
      .input-group-btn
    ) {
    min-width: 141px !important;
  }
}
.product__showing-sort .bootstrap-select > .dropdown-toggle::after {
  display: none;
}
.product__showing-sort .bootstrap-select .dropdown-menu {
  border: none;
}
.product__showing-sort .bootstrap-select > .dropdown-toggle {
  position: relative;
  height: 64px;
  outline: none !important;
  border-radius: 0;
  border: 0;
  background-color: transparent !important;
  margin: 0;
  padding: 0;
  padding-left: 0;
  padding-right: 22px;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-base, #351c42) !important;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 64px;
  font-weight: 400;
  box-shadow: none !important;
  background-repeat: no-repeat;
  background-size: 14px 12px;
  background-position: right 25.75px center;
}
@media (max-width: 767px) {
  .product__showing-sort .bootstrap-select > .dropdown-toggle {
    width: auto;
    padding-left: 0;
  }
}
.product__showing-sort .bootstrap-select > .dropdown-toggle > .filter-option {
  text-align: right;
}
@media (max-width: 767px) {
  .product__showing-sort .bootstrap-select > .dropdown-toggle > .filter-option {
    text-align: left;
  }
}
.product__showing-sort .bootstrap-select > .dropdown-toggle:before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0px;
  font-family: "Font Awesome 5 Free";
  content: "\f107";
  font-weight: 900;
  font-size: 18px;
  color: var(--cleenhearts-text, #757277);
}
.product__showing-sort .bootstrap-select .dropdown-menu > li + li > a {
  border-top: 1px solid var(--cleenhearts-border-color2, #414141);
}
.product__showing-sort .bootstrap-select .dropdown-menu > li > a {
  font-size: 16px;
  font-weight: 400;
  padding: 10px 22px;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-weight: normal;
  color: var(--cleenhearts-white, #fff);
  background-color: var(--cleenhearts-base, #351c42);
  text-transform: capitalize;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.product__showing-sort .bootstrap-select .dropdown-menu > li:hover > a,
.product__showing-sort .bootstrap-select .dropdown-menu > li.selected > a {
  background: var(--cleenhearts-secondary, #f6d469);
  color: var(--cleenhearts-base, #351c42);
  border-color: var(--cleenhearts-base, #351c42);
}
.product-item {
  position: relative;
  background-color: var(--cleenhearts-white, #fff);
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  border-radius: 20px;
  transition: all 500ms ease;
}
.product-item:hover {
  box-shadow: 0px 3px 18px rgba(var(--cleenhearts-dark-rgb, 36, 16, 47), 0.07);
}
.product-item__img {
  display: block;
  background-color: var(--cleenhearts-white, #fff);
  position: relative;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
}
.product-item__img img {
  display: block;
  width: 100%;
  border-radius: 20px 20px 0 0;
  transition: all 500ms ease;
}
.product-item:hover .product-item__img img {
  transform: scale(1.05);
}
.product-item__content {
  position: relative;
  text-align: center;
  padding: 30px 20px 29px;
}
.product-item__title {
  font-size: 24px;
  line-height: 1.333;
  text-transform: capitalize;
  margin-top: 20px;
  margin-bottom: 21px;
}
@media (max-width: 767px) {
  .product-item__title {
    font-size: 21px;
  }
}
.product-item__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.product-item__title a:hover {
  background-size: 100% 1px;
}
.product-item__title a:hover {
  color: var(--cleenhearts-purple, #965995);
}
.product-item__price {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  color: var(--cleenhearts-purple, #965995);
  line-height: 1;
  font-weight: 400;
  margin-bottom: 32px;
}
.product-item__price span {
  color: inherit;
}

/*--------------------------------------------------------------
# Shop details
--------------------------------------------------------------*/
.product-details {
  position: relative;
}
.product-details__gallery-top {
  position: relative;
  border-radius: 20px;
  background-color: var(--cleenhearts-white2, #efece7);
}
.product-details__gallery-top .swiper-slide {
  border-radius: 20px;
}
.product-details__gallery-top__img {
  border-radius: 20px;
  width: 100%;
  display: block;
}
.product-details__gallery-thumb {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.product-details__gallery-thumb-slide {
  border-radius: 10px;
  cursor: pointer;
}
.product-details__gallery-thumb__img {
  border-radius: 10px;
  width: 100%;
  display: block;
}
.product-details__content {
  position: relative;
  margin-top: 13px;
}
.product-details__top {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
}
.product-details__top__left {
  display: flex;
  align-items: center;
  gap: 19px;
}
.product-details__name {
  font-size: 24px;
  line-height: 1.333;
  text-transform: capitalize;
  margin: 0;
}
.product-details__price {
  border-radius: 100px;
  padding: 4.5px 10.5px;
  font-size: 24px;
  line-height: 1.333;
  text-transform: capitalize;
  background-color: var(--cleenhearts-purple, #965995);
  color: var(--cleenhearts-white, #fff);
  margin: 0;
}
.product-details__video {
  top: -8px;
}
.product-details__review {
  margin-top: 8px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 11px;
  padding-bottom: 23px;
  border-bottom: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
.product-details__review a {
  display: inline-block;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-text, #757277);
  text-transform: capitalize;
  font-size: 16px;
  line-height: 1.333;
  font-weight: 400;
  transition: all 0.4s ease-in-out;
}
.product-details__review a:hover {
  color: var(--cleenhearts-base, #351c42);
}
.product-details__excerpt {
  margin-top: 32.5px;
  padding-bottom: 29px;
  border-bottom: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
.product-details__excerpt__text1 {
  margin-bottom: 0;
}
.product-details__content__title {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-black, #000000);
  text-transform: capitalize;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.75;
  margin-bottom: 0;
}
.product-details__color {
  margin: 30px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 26px;
}
.product-details__color__box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.product-details__color__btn {
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 28px;
  height: 28px;
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  border-radius: 50%;
}
.product-details__color__btn span {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.product-details__color__btn--one span {
  background-color: var(--cleenhearts-fuchsia, #ff1c74);
}
.product-details__color__btn--two span {
  background-color: var(--cleenhearts-blue, #6c1cd1);
}
.product-details__color__btn--three span {
  background-color: var(--cleenhearts-green, #59c36a);
}
.product-details__color__btn--four span {
  background-color: var(--cleenhearts-yellow, #fcb720);
}
.product-details__color__btn--five span {
  background-color: var(--cleenhearts-orange, #e76100);
}
.product-details__size {
  display: flex;
  align-items: center;
  gap: 25px 36px;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  margin-bottom: 30px;
}
.product-details__size__box {
  display: flex;
  align-items: center;
  gap: 10px;
}
.product-details__size__btn {
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 28px;
  height: 28px;
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  border-radius: 50%;
}
.product-details__size__btn span {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  color: var(--cleenhearts-text, #757277);
  text-transform: uppercase;
  font-weight: normal;
  line-height: 1;
}
.product-details__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px 20px;
}
.product-details__quantity {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
}
.product-details__quantity .quantity-box {
  padding: 0 18px;
  position: relative;
  width: 165px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  border-radius: 100px;
  gap: 10px;
}
.product-details__quantity .quantity-box input {
  padding: 0;
  border: none;
  outline: none;
  width: 78px;
  height: 48px;
  text-align: center;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  color: var(--cleenhearts-purple, #965995);
  outline: none;
  font-size: 16px;
  font-weight: 700;
}
.product-details__quantity .quantity-box button {
  padding: 0;
  color: var(--cleenhearts-base, #351c42);
  font-size: 15px;
  background-color: transparent;
  border: none;
  outline: none;
  transition: all 400ms ease-in-out;
}
.product-details__quantity .quantity-box button i {
  color: inherit;
}
.product-details__quantity .quantity-box button:hover {
  color: var(--cleenhearts-purple, #965995);
}
.product-details__socials {
  display: flex;
  align-items: center;
  gap: 10px;
}
.product-details__buttons {
  margin-top: 39px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.product-details__btn-wishlist .cleenhearts-btn__icon-box {
  background-color: var(--cleenhearts-purple, #965995);
}
.product-details__btn-wishlist .cleenhearts-btn__icon-box span {
  color: var(--cleenhearts-white, #fff);
}
.product-details__btn-wishlist:hover .cleenhearts-btn__text {
  color: var(--cleenhearts-white, #fff);
}
.product-details__description-wrapper {
  padding-top: 65px;
  padding-bottom: 67px;
  margin-top: 65px;
  background-color: var(--cleenhearts-white2, #efece7);
}
.product-details__description {
  position: relative;
}
.product-details__description__title {
  font-size: 24px;
  line-height: 1.333;
  text-transform: capitalize;
  margin-bottom: 29px;
}
.product-details__description__text + .product-details__description__text {
  margin-top: 29px;
}
.product-details__description__text {
  text-transform: capitalize;
  margin-bottom: 0;
}
.product-details__comment {
  margin-top: 118px;
}
@media (max-width: 767px) {
  .product-details__comment {
    margin-top: 98px;
  }
}
@media (max-width: 575px) {
  .product-details__comment {
    margin-top: 68px;
  }
}
.product-details__comments-form__top {
  margin-bottom: 27px;
}
.product-details__comments-form__title {
  margin-bottom: 14px;
}
.product-details__comments-form__text {
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 19px;
}
.product-details__comments-form__ratings {
  display: flex;
  align-items: center;
  gap: 17px;
  flex-wrap: wrap;
}
.product-details__comments-form__ratings__text {
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 0;
}
.product-details__comments-form__ratings .cleenhearts-ratings span {
  color: var(--cleenhearts-white2, #efece7);
}

/*--------------------------------------------------------------
# Cart
--------------------------------------------------------------*/
.cart-page {
  position: relative;
}
.cart-page .table-responsive {
  position: relative;
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 1199px) {
  .cart-page .table-responsive {
    margin-bottom: 30px;
  }
}
.cart-page__table {
  position: relative;
  width: 100%;
  border: none;
  margin: 0 0 28px;
}
@media (max-width: 1199px) {
  .cart-page__table {
    min-width: 1000px;
  }
}
.cart-page__table thead {
  border-radius: 10px;
}
.cart-page__table thead tr {
  background-color: var(--cleenhearts-white2, #efece7);
  border: 0 !important;
  border-radius: 10px;
}
.cart-page__table thead tr th {
  font-size: 16px;
  color: var(--cleenhearts-base, #351c42);
  line-height: 1.75;
  font-weight: 500;
  padding-top: 21.5px;
  padding-bottom: 20.5px;
  padding-left: 25px;
  padding-right: 25px;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  text-transform: capitalize;
  border: none;
  background-color: transparent;
  border: 0 !important;
  box-shadow: none;
}
.cart-page__table thead tr th:first-child {
  border-radius: 10px 0 0 10px;
  padding-left: 21px;
}
.cart-page__table thead tr th:last-child {
  border-radius: 0 10px 10px 0;
  padding-right: 21px;
}
.cart-page__table tbody tr td {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  font-weight: 400;
  color: var(--cleenhearts-purple, #965995);
  vertical-align: middle;
  border-top: none;
  box-shadow: none;
  background-color: transparent;
  border-top: 0 !important;
  border-bottom: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  padding: 30px 25px;
  letter-spacing: 0;
}
.cart-page__table tbody tr td.cart-page__table__price {
  color: var(--cleenhearts-purple, #965995);
}
.cart-page__table tbody tr td.cart-page__table__total {
  color: var(--cleenhearts-base, #351c42);
}
.cart-page__table tbody tr td:first-child {
  padding-left: 0;
}
.cart-page__table tbody tr td:last-child {
  padding-right: 0;
}
.cart-page__table .quantity-box {
  width: 127px;
  height: 40px;
  gap: 6px;
}
.cart-page__table .quantity-box input {
  width: 52px;
  height: 38px;
}
.cart-page__table__meta {
  display: flex;
  align-items: center;
}
.cart-page__table__meta__img {
  flex-shrink: 0;
  width: 70px;
  height: 70px;
  margin-right: 10px;
  border-radius: 10px;
}
.cart-page__table__meta__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.cart-page__table__meta__title {
  font-size: 18px;
  text-transform: capitalize;
  line-height: 1.33;
  margin: 0;
}
.cart-page__table__meta__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.cart-page__table__meta__title a:hover {
  background-size: 100% 1px;
}
.cart-page__table__meta__title a:hover {
  color: var(--cleenhearts-purple, #965995);
}
.cart-page__table__remove {
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  text-transform: capitalize;
  color: var(--cleenhearts-text, #757277);
  font-size: 16px;
  margin-bottom: 0;
}
.cart-page__table__remove:hover {
  color: var(--cleenhearts-base, #351c42);
}
.cart-page__coupone__title {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  color: var(--cleenhearts-text, #757277);
  font-weight: 700;
  line-height: 1.75;
  text-transform: capitalize;
  margin-bottom: 8px;
}
.cart-page__coupone__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
@media (max-width: 767px) {
  .cart-page__coupone__box {
    flex-direction: column;
    align-items: flex-start;
  }
}
.cart-page__coupone__form {
  min-width: 445px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
}
@media (max-width: 767px) {
  .cart-page__coupone__form {
    min-width: 100%;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
}
.cart-page__coupone__form input[type="text"] {
  display: block;
  width: 268px;
  height: 62px;
  border: none;
  background-color: var(--cleenhearts-white, #fff);
  padding-left: 18px;
  padding-right: 18px;
  outline: none;
  border-radius: 10px;
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
@media (max-width: 767px) {
  .cart-page__coupone__form input[type="text"] {
    width: 100%;
  }
}
.cart-page__coupone__btn {
  padding: 0 25.5px;
  height: 62px;
  background-color: var(--cleenhearts-purple, #965995);
}
.cart-page__update-btn {
  padding: 20.5px 23.5px;
  background-color: var(--cleenhearts-base, #351c42);
}
.cart-page__bottom-btn {
  border: 0;
  outline: 0;
  border-radius: 10px;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-white, #fff);
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  transition: all 400ms ease;
}
.cart-page__bottom-btn:hover {
  color: var(--cleenhearts-base, #351c42);
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.cart-page__cart-checkout {
  padding: 25px 30px 29px;
  border-radius: 20px;
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
@media (max-width: 375px) {
  .cart-page__cart-checkout {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.cart-page__cart-total {
  position: relative;
  margin: 0;
}
.cart-page__cart-total li:not(:nth-child(2)) {
  display: flex;
  align-items: center;
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  justify-content: space-between;
  gap: 15px;
  font-size: 18px;
  color: var(--cleenhearts-base, #351c42);
  text-transform: capitalize;
  font-weight: 700;
}
.cart-page__cart-total li:not(:nth-child(2)) span {
  color: inherit;
}
.cart-page__cart-total li:nth-child(1) {
  margin-bottom: 28px;
  padding-bottom: 25px;
}
.cart-page__cart-total li:nth-child(2) {
  margin-bottom: 28px;
  padding-bottom: 28px;
}
.cart-page__cart-total li:nth-child(3) {
  margin-bottom: 25px;
}
.cart-page__cart-total li:not(:last-child) {
  border-bottom: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
.cart-page__cart-total__amount {
  text-align: right;
}
.cart-page__cart-total__address {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  color: var(--cleenhearts-text, #757277);
  font-weight: 500;
  line-height: 1.6;
  text-transform: capitalize;
  margin-bottom: 13px;
}
.cart-page__cart-total__address__text {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-text, #757277);
  font-weight: 400;
  line-height: 1.75;
  text-transform: capitalize;
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Checkout
--------------------------------------------------------------*/
.checkout-page {
  position: relative;
}
.checkout-page__title {
  font-size: 24px;
  line-height: 1.333;
  text-transform: capitalize;
}
.checkout-page__billing-address {
  position: relative;
  padding: 30px;
  border-radius: 20px;
  background-color: var(--cleenhearts-white2, #efece7);
}
@media (max-width: 425px) {
  .checkout-page__billing-address {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.checkout-page__billing-address__title {
  margin-bottom: 35px;
}
.checkout-page__input-box--address input:not(:last-child) {
  margin-bottom: 10px;
}
.checkout-page__input-box label {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-base, #351c42);
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 17px;
}
.checkout-page__input-box input[type="text"],
.checkout-page__input-box input[type="email"],
.checkout-page__input-box input[type="tel"],
.checkout-page__input-box textarea {
  height: 65px;
  width: 100%;
  border: none;
  background-color: var(--cleenhearts-white, #fff);
  padding-left: 30px;
  padding-right: 30px;
  outline: none;
  font-size: 14px;
  color: var(--cleenhearts-text, #757277);
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  display: block;
  font-weight: 400;
  border-radius: 10px;
}
@media (max-width: 425px) {
  .checkout-page__input-box input[type="text"],
  .checkout-page__input-box input[type="email"],
  .checkout-page__input-box input[type="tel"],
  .checkout-page__input-box textarea {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.checkout-page__input-box textarea {
  padding-top: 30px;
  height: 239px;
}
.checkout-page__input-box
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(
    .input-group-btn
  ) {
  position: relative;
  display: block;
  width: 100% !important;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
}
.checkout-page__input-box .bootstrap-select > .dropdown-toggle::after {
  display: none;
}
.checkout-page__input-box .bootstrap-select > .dropdown-toggle {
  position: relative;
  height: 65px;
  outline: none !important;
  border-radius: 10px;
  border: 0;
  background-color: var(--cleenhearts-white, #fff) !important;
  margin: 0;
  padding: 0;
  padding-left: 30px;
  padding-right: 30px;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-text, #757277) !important;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 65px;
  font-weight: 400;
  box-shadow: none !important;
  background-repeat: no-repeat;
  background-size: 14px 12px;
  background-position: right 25.75px center;
}
@media (max-width: 425px) {
  .checkout-page__input-box .bootstrap-select > .dropdown-toggle {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.checkout-page__input-box .bootstrap-select > .dropdown-toggle:before {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 30px;
  font-family: "Font Awesome 5 Free";
  content: "\f107";
  font-weight: 900;
  font-size: 16px;
  color: var(--cleenhearts-text, #757277);
}
@media (max-width: 425px) {
  .checkout-page__input-box .bootstrap-select > .dropdown-toggle:before {
    right: 20px;
  }
}
.checkout-page__input-box .bootstrap-select .dropdown-menu > li + li > a {
  border-top: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
.checkout-page__input-box .bootstrap-select .dropdown-menu {
  border: none;
}
.checkout-page__input-box .bootstrap-select .dropdown-menu > li > a {
  font-size: 14px;
  font-weight: 400;
  padding: 10px 22px;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-weight: normal;
  color: var(--cleenhearts-base, #351c42);
  background-color: var(--cleenhearts-white, #fff);
  text-transform: capitalize;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.checkout-page__input-box .bootstrap-select .dropdown-menu > li:hover > a,
.checkout-page__input-box .bootstrap-select .dropdown-menu > li.selected > a {
  background: var(--cleenhearts-secondary, #f6d469);
  color: var(--cleenhearts-base, #351c42);
  border-color: var(--cleenhearts-secondary, #f6d469);
}
.checkout-page__input-item + .checkout-page__input-item {
  margin-top: 12px;
}
.checkout-page__your-order {
  position: relative;
  padding: 30px;
  border-radius: 20px;
  background-color: var(--cleenhearts-white2, #efece7);
}
@media (max-width: 425px) {
  .checkout-page__your-order {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.checkout-page__your-order__title {
  margin-bottom: 19px;
}
.checkout-page__order-table {
  position: relative;
  width: 100%;
  border: none;
  margin: 0 0 0;
}
.checkout-page__order-table thead tr th {
  font-size: 18px;
  color: var(--cleenhearts-base, #351c42);
  text-transform: capitalize;
  font-weight: 500;
  line-height: 1;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  margin: 0;
  padding: 22px 0;
  border: none;
  border-bottom: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
.checkout-page__order-table thead tr th:not(:first-child) {
  text-align: right;
}
.checkout-page__order-table tbody tr td {
  margin: 0;
  padding: 23px 0;
  border-bottom: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
.checkout-page__order-table tbody tr td.pro__title,
.checkout-page__order-table tbody tr td.pro__price {
  font-size: 16px;
  line-height: 1;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-base, #351c42);
  font-weight: normal;
  text-transform: capitalize;
}
.checkout-page__order-table tbody tr td.pro__sub-title,
.checkout-page__order-table tbody tr td.pro__sub-price {
  font-size: 16px;
  line-height: 1;
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  color: var(--cleenhearts-base, #351c42);
  font-weight: 700;
  text-transform: capitalize;
}
.checkout-page__order-table tbody tr td .checkout-page__order-address-data {
  margin-top: -4px;
  margin-bottom: -5px;
}
.checkout-page__order-table tbody tr td .checkout-page__order-address {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  color: var(--cleenhearts-base, #351c42);
  font-weight: 500;
  line-height: 1.6;
  text-transform: capitalize;
  margin-bottom: 12px;
}
.checkout-page__order-table tbody tr td .checkout-page__order-address-text {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-text, #757277);
  font-weight: 400;
  line-height: 1.75;
  text-transform: capitalize;
  margin-bottom: 0;
}
.checkout-page__order-table tbody tr td .checkout-page__radio-box {
  padding-bottom: 6px;
}
.checkout-page__order-table
  tbody
  tr
  td
  .checkout-page__input-item
  + .checkout-page__input-item {
  margin-top: 12px;
}
.checkout-page__order-table tbody tr td .checkout-page__order-text {
  padding-top: 7px;
  padding-bottom: 10px;
  font-size: 14px;
  line-height: 1.714;
  text-transform: capitalize;
  margin-bottom: 0;
}
.checkout-page__order-table tbody tr td:not(:first-child) {
  text-align: right;
}
.checkout-page__order-table tbody tr:nth-child(6) td {
  padding-top: 21px;
}
.checkout-page__order-table tbody tr:nth-child(7) td {
  padding-top: 27px;
  padding-bottom: 19px;
}
.checkout-page__order-table tbody tr:nth-child(8) td {
  border: 0;
  padding-bottom: 25px;
}
.checkout-page__order-table tbody tr:last-child td {
  border: 0;
  padding: 0;
}

/*--------------------------------------------------------------
# Login
--------------------------------------------------------------*/
.login-page {
  position: relative;
}
.login-page__image img {
  border-radius: 20px;
  width: 100%;
  display: block;
}
.login-page__form {
  padding: 30px 30px 29px;
  background-color: var(--cleenhearts-white2, #efece7);
  border-radius: 20px;
}
@media (max-width: 425px) {
  .login-page__form {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.login-page__form__top-title {
  border-radius: 100px;
  padding: 10px;
  display: inline-block;
  color: var(--cleenhearts-purple, #965995);
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
  background-color: var(--cleenhearts-white, #fff);
  margin-bottom: 34px;
}
.login-page__form__title {
  font-size: 24px;
  line-height: 1.4;
  text-transform: capitalize;
  margin-bottom: 17px;
}
.login-page__form__input-box + .login-page__form__input-box {
  margin-top: 20px;
}
.login-page__form__checkbox-forgot {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}
.login-page__form .form-one__control__input {
  background-color: var(--cleenhearts-white, #fff);
  border-radius: 100px;
}
.login-page__form__checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  display: none;
  cursor: pointer;
}
.login-page__form__checkbox label {
  position: relative;
  cursor: pointer;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-text, #757277);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
}
.login-page__form__checkbox label::before {
  content: "";
  background-color: rgba(var(--cleenhearts-purple-rgb, 150, 89, 149), 0.25);
  border: 0;
  outline: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: -1px;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 11px;
}
.login-page__form__checkbox input:checked + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--cleenhearts-purple, #965995);
}
.login-page__form .cleenhearts-btn {
  margin-top: 6px;
  padding-right: 27px;
}
.login-page__form__forgot {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-text, #757277);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.login-page__form__forgot:hover {
  background-size: 100% 1px;
}
.login-page__form__forgot:hover {
  color: var(--cleenhearts-base, #351c42);
}
.login-page__form__or {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-top: 37px;
  margin-bottom: 37px;
}
.login-page__form__or-text {
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  color: var(--cleenhearts-base, #351c42);
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  text-transform: capitalize;
}
.login-page__form__or-line {
  flex-grow: 1;
  opacity: 1;
  background-color: transparent;
  margin: 0;
  border-color: var(--cleenhearts-border-color, #d7d3cb);
}
.login-page__form__signin-btn {
  padding: 0;
  border: none;
  outline: none;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-text, #757277);
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
  background-color: var(--cleenhearts-white, #fff);
  border-radius: 100px;
  width: 100%;
  height: 65px;
  padding-left: 30px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.4s ease-in-out;
}
.login-page__form__signin-btn:hover {
  background-color: var(--cleenhearts-secondary, #f6d469);
  color: var(--cleenhearts-base, #351c42);
}
@media (max-width: 425px) {
  .login-page__form__signin-btn {
    padding-left: 20px;
  }
}
@media (max-width: 375px) {
  .login-page__form__signin-btn {
    font-size: 14px;
  }
}
.login-page__form__signin-btn span {
  color: inherit;
}
.login-page__form__signin-btn + .login-page__form__signin-btn {
  margin-top: 10px;
}
.login-page__form__register-text {
  margin-top: 23px;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-text, #757277);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 0;
}
.login-page__form__register-text a {
  color: var(--cleenhearts-purple, #965995);
  font-weight: 700;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.login-page__form__register-text a:hover {
  background-size: 100% 1px;
}

/*--------------------------------------------------------------
# error 404
--------------------------------------------------------------*/
.error-404__content {
  text-align: center;
  width: 100%;
  max-width: 703px;
  margin-left: auto;
  margin-right: auto;
}
.error-404__content__top {
  width: 100%;
  max-width: 425px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 41px;
  position: relative;
  z-index: 1;
}
.error-404__image {
  max-width: 100%;
  height: auto;
  animation: slowXmove 8s ease-in infinite;
}
.error-404__title {
  position: absolute;
  font-size: 135px;
  line-height: 1;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-purple, #965995);
  margin-bottom: 0;
  z-index: 11;
}
@media (max-width: 425px) {
  .error-404__title {
    font-size: 100px;
  }
}
.error-404__title--one {
  left: 26px;
  bottom: 23px;
  transform: rotate(-15deg);
}
@media (max-width: 425px) {
  .error-404__title--one {
    left: 50px;
  }
}
@media (max-width: 375px) {
  .error-404__title--one {
    left: 30px;
    bottom: 10px;
  }
}
.error-404__title--two {
  left: 55%;
  bottom: 69px;
  transform: translateX(-50%) rotate(20deg);
}
@media (max-width: 425px) {
  .error-404__title--two {
    left: 52%;
  }
}
@media (max-width: 375px) {
  .error-404__title--two {
    bottom: 30px;
  }
}
.error-404__title--three {
  right: -22px;
  bottom: 126px;
  transform: rotate(8deg);
}
@media (max-width: 575px) {
  .error-404__title--three {
    right: 0;
    bottom: 100px;
  }
}
@media (max-width: 425px) {
  .error-404__title--three {
    right: 40px;
    bottom: 80px;
  }
}
@media (max-width: 375px) {
  .error-404__title--three {
    right: 10px;
    bottom: 40px;
  }
}
.error-404__sub-title {
  margin-bottom: 39px;
  text-transform: capitalize;
}

/*--------------------------------------------------------------
# Faq
--------------------------------------------------------------*/
.faq-one {
  position: relative;
  overflow: hidden;
}
.faq-one--about .accordion-title {
  background-color: var(--cleenhearts-white, #fff);
}
.faq-one--about .accordion-content .inner {
  background-color: var(--cleenhearts-white, #fff);
}
.faq-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--cleenhearts-white2, #efece7);
  mix-blend-mode: multiply;
  z-index: 1;
}
.faq-one .container {
  position: relative;
  z-index: 11;
}
.faq-one__image {
  position: relative;
}
.faq-one__image img {
  border-radius: 20px;
  width: 100%;
  display: block;
}
.faq-one__content {
  margin-top: 1px;
}
.faq-one .sec-title {
  margin-bottom: 29px;
}
.faq-one .sec-title__tagline {
  margin-bottom: 23px;
}
.faq-one__text {
  text-transform: capitalize;
  margin-bottom: 30px;
}
.faq-one__shape__one {
  position: absolute;
  top: 0;
  right: -257px;
  z-index: 1;
  animation: zumpY 1200ms infinite alternate;
}
.faq-one__shape__two {
  position: absolute;
  left: 110px;
  bottom: 0;
  z-index: 1;
  animation: zumpY 1200ms infinite alternate;
}
.faq-one .cleenhearts-accordion .accordion {
  overflow: hidden;
}

.faq-page-inner {
  position: relative;
}
.faq-page-inner__bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--cleenhearts-white2, #efece7);
  mix-blend-mode: multiply;
  z-index: 1;
}
.faq-page-inner .container {
  position: relative;
  z-index: 11;
}
.faq-page-inner .accordion-title {
  background-color: var(--cleenhearts-white, #fff);
  border-radius: 10px;
}
.faq-page-inner .accordion-content .inner {
  background-color: var(--cleenhearts-white, #fff);
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing-card {
  padding: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--cleenhearts-white2, #efece7);
  border-radius: 20px;
  mix-blend-mode: multiply;
  transition: transform 500ms linear;
}
.pricing-card:hover {
  transform: translateY(-4px);
}
.pricing-card__image {
  padding: 24px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  margin-bottom: 40px;
  overflow: hidden;
}
@media (max-width: 575px) {
  .pricing-card__image {
    padding: 20px;
  }
}
.pricing-card__image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(var(--cleenhearts-dark3-rgb, 32, 12, 43), 0.5);
  z-index: 1;
  border-radius: 20px;
}
.pricing-card__image::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0) 0%,
    rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0.2) 100%
  );
  background: linear-gradient(
    to right,
    rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0) 0%,
    rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0.2) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
.pricing-card__image__icon {
  position: relative;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cleenhearts-white, #fff);
  z-index: 1;
  margin-bottom: 15px;
}
@media (max-width: 575px) {
  .pricing-card__image__icon {
    width: 60px;
    height: 60px;
  }
}
.pricing-card__image__icon::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform: scale(0);
  opacity: 0;
  transition: transform 400ms ease, opacity 400ms ease;
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.pricing-card__image__icon span {
  font-size: 36px;
  color: var(--cleenhearts-purple, #965995);
}
@media (max-width: 575px) {
  .pricing-card__image__icon span {
    font-size: 26px;
  }
}
.pricing-card:hover .pricing-card__image::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}
.pricing-card:hover .pricing-card__image__icon::after {
  transform: scale(1);
  opacity: 1;
}
.pricing-card__image__content {
  position: relative;
  z-index: 11;
}
.pricing-card__title {
  position: relative;
  font-size: 30px;
  color: var(--cleenhearts-white, #fff);
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .pricing-card__title {
    font-size: 25px;
  }
}
@media (max-width: 425px) {
  .pricing-card__title {
    font-size: 23px;
  }
}
.pricing-card__list {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 45px;
}
.pricing-card__list li + li {
  margin-top: 20px;
}
.pricing-card__list li {
  display: flex;
  align-items: center;
}
.pricing-card__list__icon {
  font-size: 13px;
  color: var(--cleenhearts-purple, #965995);
  margin-right: 10px;
}
.pricing-card__list__text {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  color: var(--cleenhearts-text, #757277);
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
}
.pricing-card__price {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--cleenhearts-secondary, #f6d469);
  border-radius: 100px;
}
.pricing-card__price__inner {
  display: flex;
  align-items: center;
}
.pricing-card__price__title {
  font-size: 40px;
  line-height: 1;
  text-transform: capitalize;
  margin-right: 8px;
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .pricing-card__price__title {
    font-size: 30px;
  }
}
.pricing-card__price__tagline {
  padding: 5px 10px;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 12px;
  color: var(--cleenhearts-base, #351c42);
  font-weight: 500;
  line-height: 1.333;
  text-transform: capitalize;
  margin-bottom: 0;
  border-radius: 20px;
  border: 1px solid var(--cleenhearts-base, #351c42);
}
.pricing-card__button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--cleenhearts-base, #351c42);
  font-size: 13px;
  color: var(--cleenhearts-white, #fff);
  z-index: 1;
}
.pricing-card__button::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform: scale(0);
  opacity: 0;
  transition: transform 300ms ease, opacity 300ms ease;
  background-color: var(--cleenhearts-white, #fff);
}
.pricing-card__button:hover {
  color: var(--cleenhearts-base, #351c42);
}
.pricing-card__button:hover::after {
  transform: scale(1);
  opacity: 1;
}
.pricing-card__button span {
  color: inherit;
}

/*--------------------------------------------------------------
# Offer
--------------------------------------------------------------*/
.offer-one {
  padding-bottom: 120px;
}
@media (max-width: 767px) {
  .offer-one {
    padding-bottom: 80px;
  }
}
.offer-one--home {
  padding: 100px 0;
}
@media (max-width: 767px) {
  .offer-one--home {
    padding: 80px 0;
  }
}
.offer-one .container-fluid {
  max-width: 1604px;
}
.offer-one__card {
  padding: 20px;
  background-size: cover;
  position: relative;
  overflow: hidden;
}
.offer-one__card::before {
  background: linear-gradient(
    90deg,
    rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0.13) 0px,
    rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0.13) 77%,
    rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0.5) 92%,
    rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0)
  );
  content: "";
  height: 200%;
  left: -210%;
  opacity: 0;
  position: absolute;
  top: -50%;
  transition: all 0.7s ease 0s;
  width: 200%;
}
.offer-one__card:hover::before {
  left: -30%;
  opacity: 1;
  top: -20%;
  transition-duration: 0.7s, 0.7s, 0.15s;
  transition-property: left, top, opacity;
  transition-timing-function: linear;
}
.offer-one__card__inner {
  position: relative;
  border: 1px solid var(--cleenhearts-white, #fff);
  padding: 40px;
}
@media (min-width: 992px) {
  .offer-one__card__inner {
    padding: 50px;
  }
}
.offer-one__card__shape {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
@media (min-width: 992px) {
  .offer-one__card__shape {
    display: block;
  }
}
.offer-one__card__value {
  font-family: var(--cleenhearts-special-font, "Schoolbell", cursive);
  font-weight: 400;
  font-size: 30px;
  color: var(--cleenhearts-base, #351c42);
  line-height: 1;
  margin: 0;
}
@media (min-width: 992px) {
  .offer-one__card__value {
    font-size: 40px;
  }
}
.offer-one__card__title {
  position: relative;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  font-size: 30px;
  line-height: 1.2em;
  margin-bottom: 17px;
}
@media (min-width: 992px) {
  .offer-one__card__title {
    font-size: 40px;
  }
}

/*--------------------------------------------------------------
# Animations
--------------------------------------------------------------*/
@keyframes bubbleMover {
  0% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
    transform: translateY(0px) translateX(0) rotate(0);
  }
  30% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
    transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
  50% {
    -webkit-transform: translateY(50px) translateX(100px) rotate(45deg);
    transform: translateY(50px) translateX(100px) rotate(45deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
  }
  80% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
    transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: left top;
    transform-origin: left top;
  }
  100% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
    transform: translateY(0px) translateX(0) rotate(0);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
}
@keyframes rotated {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes shapeMover {
  0%,
  100% {
    transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px)
      translateX(0);
  }
  50% {
    transform: perspective(400px) rotate(-45deg) translateZ(20px)
      translateY(20px) translateX(20px);
  }
}
@keyframes banner3Shake {
  0% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }
  30% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  60% {
    -webkit-transform: rotate3d(1, 0, 0, 0deg);
    transform: rotate3d(1, 0, 0, 0deg);
  }
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }
}
@keyframes squareMover {
  0%,
  100% {
    -webkit-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0);
  }
  20%,
  60% {
    -webkit-transform: translate(20px, 40px) rotate(180deg);
    transform: translate(20px, 40px) rotate(180deg);
  }
  30%,
  80% {
    -webkit-transform: translate(40px, 60px) rotate(0deg);
    transform: translate(40px, 60px) rotate(0deg);
  }
}
@keyframes treeMove {
  0%,
  100% {
    -webkit-transform: rotate(0deg) translateX(0);
    transform: rotate(0deg) translateX(0);
  }
  25%,
  75% {
    -webkit-transform: rotate(5deg) translateX(15px);
    transform: rotate(5deg) translateX(15px);
  }
  50% {
    -webkit-transform: rotate(10deg) translateX(30px);
    transform: rotate(10deg) translateX(30px);
  }
}
@keyframes leafMove {
  0%,
  100% {
    -webkit-transform: rotate(0deg) translateX(0);
    transform: rotate(0deg) translateX(0);
  }
  25%,
  75% {
    transform: rotate(-2deg) translateX(5px);
  }
  50% {
    transform: rotate(-4deg) translateX(10px);
  }
}
@keyframes messageMove {
  0%,
  100% {
    transform: translateX(0);
  }
  25%,
  75% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(10px);
  }
}
@keyframes textRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes flowerRotate {
  0%,
  100% {
    transform: rotate(0deg);
  }
  25%,
  75% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(10deg);
  }
}
@keyframes slowXmove {
  0%,
  100% {
    -webkit-transform: rotate(0deg) translateX(0);
    transform: rotate(0deg) translateX(0);
  }
  25%,
  75% {
    -webkit-transform: rotate(5deg) translateX(15px);
    transform: rotate(5deg) translateX(15px);
  }
  50% {
    -webkit-transform: rotate(10deg) translateX(30px);
    transform: rotate(10deg) translateX(30px);
  }
}
@keyframes smartMove {
  0% {
    width: 0;
  }
  15% {
    width: 100%;
  }
  85% {
    opacity: 1;
  }
  90% {
    width: 100%;
    opacity: 0;
  }
  100% {
    width: 0;
    opacity: 0;
  }
}
@keyframes zumpX {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-30px);
  }
}
@keyframes zumpY {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-30px);
  }
}
@keyframes zumpXtwo {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(30px);
  }
}
@keyframes zump {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  50% {
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}
@keyframes float-bob-y-2 {
  0% {
    -webkit-transform: translateY(0px) translateX(0px) rotate(0deg);
    transform: translateY(0px) translateX(0px) rotate(0deg);
  }
  50% {
    -webkit-transform: translateY(10px) translateX(10px) rotate(5deg);
    transform: translateY(10px) translateX(10px) rotate(5deg);
  }
  100% {
    -webkit-transform: translateY(0px) translateX(0px) rotate(0deg);
    transform: translateY(0px) translateX(0px) rotate(0deg);
  }
}

/*--------------------------------------------------------------
# Mobile Nav
--------------------------------------------------------------*/
.mobile-nav__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  transform: translateX(-100%);
  transform-origin: left center;
  transition: transform 500ms ease 500ms, visibility 500ms ease 500ms;
  visibility: hidden;
  position: fixed;
}
.mobile-nav__wrapper .container {
  padding-left: 0;
  padding-right: 0;
}
.mobile-nav__wrapper .home-showcase .row [class*="col-"] {
  flex: 0 0 100%;
}
.mobile-nav__wrapper .home-showcase {
  margin-bottom: -1px;
  margin-top: 0;
  border-bottom: 1px solid
    RGBA(var(--cleenhearts-white-rgb, 255, 255, 255), 0.1);
}
.mobile-nav__wrapper .home-showcase__inner {
  padding: 15px 0px;
  background-color: transparent;
  box-shadow: none;
}
.mobile-nav__wrapper .home-showcase__title {
  color: var(--cleenhearts-white, #fff);
}

.mobile-nav__wrapper.expanded {
  opacity: 1;
  transform: translateX(0%);
  visibility: visible;
  transition: transform 500ms ease 0ms, visibility 500ms ease 0ms;
}
.mobile-nav__wrapper.expanded .mobile-nav__content {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
  transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms,
    transform 500ms ease 500ms;
}

.mobile-nav__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--cleenhearts-dark, #24102f);
  opacity: 0.7;
}

.mobile-nav__content {
  width: 300px;
  background-color: var(--cleenhearts-dark2, #200c2a);
  z-index: 10;
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-100%);
  transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms,
    transform 500ms ease 0ms;
}
.mobile-nav__content .main-menu__nav {
  display: block;
  padding: 0;
}

.mobile-nav__content .logo-box {
  margin-bottom: 40px;
  display: flex;
}

.mobile-nav__close {
  position: absolute;
  top: 20px;
  right: 15px;
  font-size: 18px;
  color: var(--cleenhearts-white, #fff);
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
.mobile-nav__close:hover {
  color: var(--cleenhearts-secondary, #f6d469);
}

.mobile-nav__content .main-menu__list,
.mobile-nav__content .main-menu__list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.mobile-nav__content .main-menu__list ul {
  display: none;
  border-top: 1px solid RGBA(var(--cleenhearts-white-rgb, 255, 255, 255), 0.1);
}

.mobile-nav__content .main-menu__list ul li > a {
  padding-left: 1em;
}

.mobile-nav__content .main-menu__list li:not(:last-child) {
  border-bottom: 1px solid
    RGBA(var(--cleenhearts-white-rgb, 255, 255, 255), 0.1);
}

.mobile-nav__content .main-menu__list li > a {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  color: var(--cleenhearts-white, #fff);
  font-size: 16px;
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  text-transform: capitalize;
  font-weight: 700;
  height: 46px;
  align-items: center;
  transition: 500ms;
}

.mobile-nav__content .main-menu__list li ul li a {
  font-size: 14px;
}

.mobile-nav__content .main-menu__list li a.expanded {
  color: var(--cleenhearts-secondary, #f6d469);
}

.mobile-nav__content .main-menu__list li a button {
  width: 30px;
  height: 30px;
  background-color: var(--cleenhearts-secondary, #f6d469);
  border: none;
  outline: none;
  color: var(--cleenhearts-base, #351c42);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transform: rotate(-90deg);
  transition: transform 500ms ease;
}

.mobile-nav__content .main-menu__list li a button.expanded {
  transform: rotate(0deg);
  background-color: var(--cleenhearts-purple, #965995);
  color: var(--cleenhearts-white, #fff);
}

.mobile-nav__social {
  display: flex;
  align-items: center;
}
.mobile-nav__social a {
  font-size: 16px;
  color: var(--cleenhearts-white, #fff);
  transition: 500ms;
}
.mobile-nav__social a + a {
  margin-left: 20px;
}
.mobile-nav__social a:hover {
  color: var(--cleenhearts-secondary, #f6d469);
}

.mobile-nav__contact {
  margin-bottom: 0;
  margin-top: 20px;
  margin-bottom: 20px;
}
.mobile-nav__contact li {
  color: var(--cleenhearts-white, #fff);
  font-size: 14px;
  font-weight: 500;
  position: relative;
  display: flex;
  align-items: center;
}
.mobile-nav__contact li + li {
  margin-top: 15px;
}
.mobile-nav__contact li a {
  color: inherit;
  transition: 500ms;
}
.mobile-nav__contact li a:hover {
  color: var(--cleenhearts-secondary, #f6d469);
}
.mobile-nav__contact li > i {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--cleenhearts-secondary, #f6d469);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  margin-right: 10px;
  color: var(--cleenhearts-base, #351c42);
}

.mobile-nav__container .main-menu__logo,
.mobile-nav__container .main-menu__right {
  display: none;
}

/*--------------------------------------------------------------
# Search Popup
--------------------------------------------------------------*/
.search-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -2;
  -webkit-transition: all 1s ease;
  -khtml-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.search-popup__overlay {
  position: fixed;
  width: 224vw;
  height: 224vw;
  top: calc(90px - 112vw);
  right: calc(50% - 112vw);
  z-index: 3;
  display: block;
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: scale(0);
  -khtml-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transition: transform 0.8s ease-in-out;
  -khtml-transition: transform 0.8s ease-in-out;
  -moz-transition: transform 0.8s ease-in-out;
  -ms-transition: transform 0.8s ease-in-out;
  -o-transition: transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out;
  transition-delay: 0s;
  transition-delay: 0.3s;
  -webkit-transition-delay: 0.3s;
  background-color: #000;
  opacity: 0.9;
}
@media (max-width: 767px) {
  .search-popup__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
    width: 100%;
    height: 100%;
    border-radius: 0;
    transform: translateY(-110%);
  }
}
.search-popup__content {
  position: fixed;
  width: 0;
  max-width: 560px;
  padding: 30px 15px;
  left: 50%;
  top: 50%;
  opacity: 0;
  z-index: 3;
  -webkit-transform: translate(-50%, -50%);
  -khtml-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: opacity 0.5s 0s,
    width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
  -khtml-transition: opacity 0.5s 0s,
    width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
  -moz-transition: opacity 0.5s 0s,
    width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
  -ms-transition: opacity 0.5s 0s,
    width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
  -o-transition: opacity 0.5s 0s,
    width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
  transition: opacity 0.5s 0s,
    width 0.8s 0.8s cubic-bezier(0.225, 0.01, 0.475, 1.01), transform 0.2s 0s;
  transition-delay: 0s, 0.8s, 0s;
  transition-delay: 0s, 0.4s, 0s;
  transition-delay: 0.2s;
  -webkit-transition-delay: 0.2s;
}
.search-popup__form {
  position: relative;
}
.search-popup__form input[type="search"],
.search-popup__form input[type="text"] {
  width: 100%;
  background-color: var(--cleenhearts-white, #fff);
  font-size: 15px;
  color: var(--cleenhearts-text, #757277);
  border: none;
  outline: none;
  height: 66px;
  padding-left: 30px;
}
.search-popup__form .cleenhearts-btn {
  padding: 0;
  width: 66px;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  right: -1px;
  border-radius: 0;
}
.search-popup__form .cleenhearts-btn i {
  margin: 0;
}
.search-popup__form .cleenhearts-btn::after {
  background-color: var(--cleenhearts-dark, #24102f);
}
.search-popup.active {
  z-index: 9999;
}
.search-popup.active .search-popup__overlay {
  top: auto;
  bottom: calc(90px - 112vw);
  -webkit-transform: scale(1);
  -khtml-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  opacity: 0.9;
  -webkit-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
  -khtml-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
  -moz-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
  -ms-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
  -o-transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
  transition: transform 1.6s cubic-bezier(0.4, 0, 0, 1);
}
@media (max-width: 767px) {
  .search-popup.active .search-popup__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
    width: 100%;
    height: 100%;
    border-radius: 0;
    transform: translateY(0%);
  }
}
.search-popup.active .search-popup__content {
  width: 100%;
  opacity: 1;
  transition-delay: 0.7s;
  -webkit-transition-delay: 0.7s;
}

/*--------------------------------------------------------------
# Page Header
--------------------------------------------------------------*/
.page-header {
  background-color: var(--cleenhearts-base, #351c42);
  position: relative;
  padding-top: 122px;
  padding-bottom: 128px;
}
@media (max-width: 767px) {
  .page-header {
    padding-top: 102px;
    padding-bottom: 108px;
  }
}
@media (max-width: 425px) {
  .page-header {
    padding-top: 92px;
    padding-bottom: 98px;
  }
}
.page-header__bg {
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.page-header__bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    RGBA(var(--cleenhearts-base-rgb, 53, 28, 66), 0.8) 0%,
    RGBA(var(--cleenhearts-base-rgb, 53, 28, 66), 0.8) 100%
  );
  z-index: 1;
}
.page-header .container {
  position: relative;
  z-index: 1;
  text-align: center;
}
.page-header__title {
  margin: 0;
  text-transform: capitalize;
  font-size: 50px;
  color: var(--cleenhearts-white, #fff);
  line-height: 1.4;
  margin-bottom: 19px;
}
@media (max-width: 767px) {
  .page-header__title {
    font-size: 45px;
  }
}
@media (max-width: 575px) {
  .page-header__title {
    font-size: 40px;
  }
}
@media (max-width: 425px) {
  .page-header__title {
    font-size: 35px;
  }
}

.cleenhearts-breadcrumb {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  margin-bottom: 0;
}
.cleenhearts-breadcrumb li {
  font-size: 14px;
  color: var(--cleenhearts-white, #fff);
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
.cleenhearts-breadcrumb li:not(:last-of-type)::after {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 12px;
  position: relative;
  top: 0px;
  margin-left: 10px;
  margin-right: 10px;
}
.cleenhearts-breadcrumb li i {
  position: relative;
  top: -1px;
  margin-right: 9px;
  font-size: 14px;
  color: var(--cleenhearts-secondary, #f6d469);
}
.cleenhearts-breadcrumb li span,
.cleenhearts-breadcrumb li a {
  color: inherit;
  display: inline-flex;
  line-height: 1.5;
}
.cleenhearts-breadcrumb li a {
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.cleenhearts-breadcrumb li a:hover {
  background-size: 100% 1px;
}
.cleenhearts-breadcrumb li a:hover {
  color: var(--cleenhearts-secondary, #f6d469);
}

/*--------------------------------------------------------------
# Google Map
--------------------------------------------------------------*/
.google-map {
  position: relative;
}
.google-map iframe {
  position: relative;
  display: block;
  border: none;
  height: 440px;
  width: 100%;
}
.google-map__contact {
  overflow: hidden;
  background-color: var(--cleenhearts-gray, #888888);
}

.contact-map {
  position: relative;
  padding-bottom: 120px;
}
@media (max-width: 767px) {
  .contact-map {
    padding-bottom: 80px;
  }
}
.contact-map .container-fluid {
  padding-left: 0;
  padding-right: 0;
  max-width: 1654px;
}

/*--------------------------------------------------------------
# Client Carousel
--------------------------------------------------------------*/
.client-carousel {
  padding-top: 206px;
  padding-bottom: 100px;
  background-color: var(--cleenhearts-white, #fff);
}
@media (max-width: 767px) {
  .client-carousel {
    padding-top: 186px;
    padding-bottom: 80px;
  }
}
@media (max-width: 575px) {
  .client-carousel {
    padding-top: 156px;
    padding-bottom: 50px;
  }
}
.client-carousel__one {
  position: relative;
}
.client-carousel__one .owl-stage-outer {
  padding-top: 30px;
  padding-bottom: 30px;
}
.client-carousel__one .owl-nav {
  position: absolute;
  width: 100%;
  top: -116px;
  left: 0;
}
.client-carousel__one .owl-nav.disabled {
  display: flex !important;
}
.client-carousel__item {
  position: relative;
  transition: transform 500ms ease;
}
.client-carousel__item img {
  transition: transform 500ms ease;
}
.client-carousel__item__hover-img {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  transition: transform 500ms ease, opacity 1s ease;
}
.client-carousel__item:hover img {
  transform: scale(1.1);
}
.client-carousel__item:hover .client-carousel__item__hover-img {
  transform: translate(-50%, -50%) scale(1.1);
  opacity: 1;
}
.client-carousel__item:nth-child(1),
.client-carousel__item:nth-child(1) img {
  width: 86px;
  height: 87px;
}
.client-carousel__item:nth-child(2),
.client-carousel__item:nth-child(2) img {
  width: 85px;
  height: 86px;
}
.client-carousel__item:nth-child(3),
.client-carousel__item:nth-child(4),
.client-carousel__item:nth-child(3) img,
.client-carousel__item:nth-child(4) img {
  width: 80px;
  height: 79px;
}
.client-carousel__item:nth-child(5),
.client-carousel__item:nth-child(5) img {
  width: 87px;
  height: 84px;
}
.client-carousel__item:nth-child(6),
.client-carousel__item:nth-child(6) img {
  width: 83px;
  height: 84px;
}
@media (max-width: 1199px) {
  .client-carousel__item,
  .client-carousel__item img {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 767px) {
  .client-carousel__item,
  .client-carousel__item img {
    width: 70px !important;
    height: 70px !important;
  }
}
@media (max-width: 375px) {
  .client-carousel__item,
  .client-carousel__item img {
    width: 60px !important;
    height: 60px !important;
  }
}
@media (max-width: 359px) {
  .client-carousel__item,
  .client-carousel__item img {
    width: 70px !important;
    height: 70px !important;
  }
}

.client-carousel-two {
  padding-top: 35px;
  padding-bottom: 35px;
  position: relative;
  background-color: var(--cleenhearts-base, #351c42);
}
.client-carousel-two__bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  mix-blend-mode: multiply;
}
.client-carousel-two__carousel .owl-stage-outer {
  padding-top: 30px;
  padding-bottom: 30px;
}

/*--------------------------------------------------------------
# Board Member
--------------------------------------------------------------*/
.board-member {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--cleenhearts-base, #351c42);
}
.board-member__shape--one {
  position: absolute;
  top: 0;
  left: 50px;
  border-radius: 0px 0px 1000px 1000px;
  animation: zumpX 2000ms infinite alternate;
  border: 5px solid var(--cleenhearts-secondary, #f6d469);
  opacity: 0.25;
  width: 234px;
  height: 117px;
}
.board-member__shape--one::after {
  content: "";
  position: absolute;
  top: -5px;
  left: 50px;
  width: 100%;
  height: 100%;
  border: 5px solid var(--cleenhearts-secondary, #f6d469);
  border-radius: 0px 0px 1000px 1000px;
  animation: zumpX 2000ms infinite alternate;
}
.board-member__shape--two {
  position: absolute;
  bottom: 0;
  right: -141px;
  border-radius: 1000px 1000px 0px 0px;
  animation: zumpX 2000ms infinite alternate;
  border: 5px solid var(--cleenhearts-secondary, #f6d469);
  opacity: 0.25;
  width: 590px;
  height: 295px;
}
.board-member__shape--two::after {
  content: "";
  position: absolute;
  bottom: -5px;
  right: 120px;
  width: 100%;
  height: 100%;
  border: 5px solid var(--cleenhearts-secondary, #f6d469);
  border-radius: 1000px 1000px 0px 0px;
  animation: zumpX 2000ms infinite alternate;
}
@media (min-width: 500px) {
  .board-member .container-fluid {
    padding: 0;
  }
}
.board-member .sec-title {
  text-align: center;
  width: 100%;
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
}
.board-member .sec-title__tagline {
  color: var(--cleenhearts-white, #fff);
}
.board-member .sec-title__tagline::before {
  background-color: var(--cleenhearts-white, #fff);
}
.board-member .sec-title__tagline::after {
  border-color: var(--cleenhearts-white, #fff);
}
.board-member .sec-title__title {
  color: var(--cleenhearts-white, #fff);
}
.board-member__item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}
@media (max-width: 767px) {
  .board-member__item {
    grid-template-columns: 1fr;
    gap: 0;
  }
}
.board-member__image {
  display: block;
  position: relative;
  transition: all 400ms ease;
  border-radius: 20px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .board-member__image {
    border-radius: 20px 20px 0 0;
  }
}
.board-member__image img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
@media (max-width: 767px) {
  .board-member__image img {
    border-radius: 20px 20px 0 0;
  }
}
.board-member__info {
  overflow: hidden;
  position: relative;
  padding: 41px 34px 40px;
  background-color: var(--cleenhearts-white2, #efece7);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30px 0;
  transition: all 400ms ease;
}
@media (max-width: 1900px) {
  .board-member__info {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 767px) {
  .board-member__info {
    border-radius: 0 0 20px 20px;
  }
}
@media (max-width: 425px) {
  .board-member__info {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
@media (max-width: 360px) {
  .board-member__info {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.board-member__name {
  font-size: 24px;
  line-height: 1.458;
  text-transform: capitalize;
  margin-bottom: 9px;
  transition: all 400ms ease;
}
.board-member__name a {
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
  color: inherit;
}
.board-member__name a:hover {
  background-size: 100% 1px;
}
.board-member__name a:hover {
  color: var(--cleenhearts-purple, #965995);
}
@media (max-width: 1900px) {
  .board-member__name {
    font-size: 22px;
  }
}
@media (max-width: 1399px) {
  .board-member__name {
    font-size: 20px;
  }
}
.board-member__designation {
  font-size: 16px;
  color: var(--cleenhearts-purple, #965995);
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  display: block;
}
.board-member__social {
  display: flex;
  align-items: center;
  gap: 7px;
}
.board-member__social a {
  font-size: 14px;
  color: var(--cleenhearts-text, #757277);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid var(--cleenhearts-text, #757277);
  transition: all 400ms ease;
}
.board-member__social a:hover {
  color: var(--cleenhearts-white, #fff);
  background-color: var(--cleenhearts-purple, #965995);
  border-color: transparent;
}

/*--------------------------------------------------------------
# Hero Slider
--------------------------------------------------------------*/
.main-slider-one {
  position: relative;
  background-color: var(--cleenhearts-white, #fff);
}
.main-slider-one__wrapper {
  position: relative;
  margin: 0 100px;
  overflow: hidden;
  border-radius: 20px;
}
@media (max-width: 1599px) {
  .main-slider-one__wrapper {
    margin: 0 15px;
  }
}
.main-slider-one__carousel {
  position: relative;
  width: 100%;
}
.main-slider-one__carousel.owl-carousel .owl-nav {
  left: 118px;
  margin: auto 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50%;
  transform: translateY(-50%);
  max-width: 120px;
}
@media (max-width: 1400px) {
  .main-slider-one__carousel.owl-carousel .owl-nav {
    left: 50px;
  }
}
@media (max-width: 1199px) {
  .main-slider-one__carousel.owl-carousel .owl-nav {
    left: 20px;
  }
}
@media (max-width: 767px) {
  .main-slider-one__carousel.owl-carousel .owl-nav {
    display: none;
  }
}
.main-slider-one__carousel.owl-carousel .owl-nav button {
  transition: all 400ms ease;
  width: 52px;
  height: 52px;
  outline: none;
  box-shadow: none;
  border: none;
  background-color: RGBA(var(--cleenhearts-white-rgb, 255, 255, 255), 0.1);
  border-radius: 50%;
  display: block;
  color: var(--cleenhearts-white, #fff);
  line-height: 53px;
  font-size: 16px;
  margin: 9px 0;
  text-align: center;
}
.main-slider-one__carousel.owl-carousel .owl-nav button:hover {
  background-color: var(--cleenhearts-base, #351c42);
}
.main-slider-one__carousel .owl-dots {
  right: 74px;
  margin: auto 0;
  position: absolute;
  text-align: right;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1199px) {
  .main-slider-one__carousel .owl-dots {
    right: 50px;
  }
}
@media (max-width: 991px) {
  .main-slider-one__carousel .owl-dots {
    right: 30px;
  }
}
@media (max-width: 767px) {
  .main-slider-one__carousel .owl-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    margin: auto;
    left: 0;
    transform: none;
    top: auto;
    bottom: 30px;
    right: 0;
  }
}
.main-slider-one__carousel .owl-dots .owl-dot {
  display: block;
  margin: 19px 0;
}
@media (max-width: 767px) {
  .main-slider-one__carousel .owl-dots .owl-dot {
    margin: 0 6px;
  }
}
.main-slider-one__carousel .owl-dots .owl-dot span {
  width: 6px;
  height: 6px;
  display: block;
  border-radius: 50%;
  background-color: var(--cleenhearts-white2, #efece7);
  border: none;
  position: relative;
  margin: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-slider-one__carousel .owl-dots .owl-dot span::after {
  width: 24px;
  height: 24px;
  position: absolute;
  content: "";
  left: -9px;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  transform: scale(0);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-slider-one__carousel .owl-dots .owl-dot:hover span,
.main-slider-one__carousel .owl-dots .owl-dot.active span {
  background-color: var(--cleenhearts-yellow, #fcb720);
}
.main-slider-one__carousel .owl-dots .owl-dot:hover span::after,
.main-slider-one__carousel .owl-dots .owl-dot.active span::after {
  transform: scale(1);
}
.main-slider-one__item {
  background-color: var(--cleenhearts-gray, #888888);
  position: relative;
  z-index: 3;
  padding-top: 201px;
  padding-bottom: 200px;
}
@media (max-width: 991px) {
  .main-slider-one__item {
    padding-top: 180px;
    padding-bottom: 150px;
  }
}
@media (max-width: 767px) {
  .main-slider-one__item {
    padding-top: 120px;
    padding-bottom: 150px;
  }
}
.main-slider-one__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  transform: scale(1);
  transition: transform 10000ms ease, -webkit-transform 10000ms ease;
  overflow: hidden;
}
.main-slider-one__shape-one {
  position: absolute;
  left: 0;
  top: 30px;
  width: 100%;
  height: 169px;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: top left;
  opacity: 0.35;
  transform: scale(1);
  transition: transform 1000ms ease, -webkit-transform 1000ms ease;
  transform: translateX(-100%);
}
@media (max-width: 991px) {
  .main-slider-one__shape-one {
    display: none;
  }
}
.main-slider-one__shape-two {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 171px;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: bottom right;
  opacity: 0.35;
  transform: scale(1);
  transition: transform 1000ms ease, -webkit-transform 1000ms ease;
  transform: translateY(100%);
}
@media (max-width: 991px) {
  .main-slider-one__shape-two {
    display: none;
  }
}
.main-slider-one__content {
  position: relative;
  z-index: 3;
  padding: 0 0 0 90px;
}
@media (max-width: 1199px) {
  .main-slider-one__content {
    padding-left: 50px;
  }
}
@media (max-width: 767px) {
  .main-slider-one__content {
    padding-left: 20px;
  }
}
.main-slider-one__sub-title {
  display: inline-block;
  color: var(--cleenhearts-secondary, #f6d469);
  font-size: 20px;
  font-weight: 700;
  line-height: 1;
  border: 1px solid rgba(var(--cleenhearts-secondary-rgb, 246, 212, 105), 0.4);
  border-radius: 100px;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  padding: 8.5px 14px;
  margin: 0 0 22px;
  opacity: 0;
  transition: transform 1000ms ease, opacity 1000ms ease;
  transform: translateX(100px);
}
@media (max-width: 991px) {
  .main-slider-one__sub-title {
    font-size: 15px;
  }
}
.main-slider-one__title {
  color: var(--cleenhearts-white, #fff);
  font-size: 50px;
  font-weight: 700;
  line-height: 1.5;
  text-transform: capitalize;
  letter-spacing: -3.2px;
  margin: 0 0 0;
  opacity: 0;
  transition: transform 1200ms ease, opacity 1200ms ease;
  transform: translateX(200px);
}
@media (max-width: 991px) {
  .main-slider-one__title {
    font-size: 60px;
    margin-bottom: -20px;
  }
}
@media (max-width: 767px) {
  .main-slider-one__title {
    font-size: 30px;
    letter-spacing: -1px;
    margin-bottom: 10px;
  }
}
.main-slider-one__title__text {
  display: inline-block;
  position: relative;
  overflow: hidden;
}
.main-slider-one__title__text::after {
  content: "";
  width: 101%;
  height: 72%;
  position: absolute;
  top: 22px;
  left: 100%;
  background: currentColor;
  transition: 1s cubic-bezier(0.858, 0.01, 0.068, 0.99);
  z-index: 3;
  transform: translateX(-100%);
  transition-delay: 1s;
}
@media (max-width: 1199px) {
  .main-slider-one__title__text::after {
    top: 19px;
  }
}
@media (max-width: 991px) {
  .main-slider-one__title__text::after {
    top: 15px;
  }
}
.main-slider-one__title__text:not(:first-child) {
  top: -36px;
}
@media (max-width: 767px) {
  .main-slider-one__title__text:not(:first-child) {
    top: -6px;
  }
}
.main-slider-one__btn {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 5;
  overflow: hidden;
}
.main-slider-one__btn .cleenhearts-btn {
  opacity: 0;
  transform: perspective(400px) rotateY(0deg) translateY(80px);
  transform-origin: bottom;
  transition: all 1500ms ease;
}
.main-slider-one__author {
  display: flex;
  position: relative;
  margin-left: 30px;
}
@media (max-width: 767px) {
  .main-slider-one__author {
    margin-left: 12px;
  }
}
.main-slider-one__author img {
  width: 50px !important;
  height: 50px;
  border-radius: 50%;
  border: 3px solid var(--cleenhearts-white, #fff);
  margin-right: -15px;
  opacity: 0;
  transition: transform 1200ms ease, opacity 1200ms ease;
  transform: translateX(100px);
}
.main-slider-one .active .main-slider-one__shape-one {
  transform: translateX(0);
}
.main-slider-one .active .main-slider-one__shape-two {
  transform: translateY(0);
}
.main-slider-one .active .main-slider-one__bg {
  transform: scale(1.15);
}
.main-slider-one .active .main-slider-one__sub-title {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 1100ms;
}
.main-slider-one .active .main-slider-one__title {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 1300ms;
}
.main-slider-one .active .main-slider-one__title__text::after {
  transform: translateX(1%);
  transition-delay: 1500ms;
}
.main-slider-one .active .main-slider-one__btn .cleenhearts-btn {
  opacity: 1;
  transform: perspective(400px) rotateY(0deg) translateY(0px);
  transition-delay: 1600ms;
}
.main-slider-one .active .main-slider-one__author img {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 1700ms;
}
.main-slider-one .active .main-slider-one__author img:nth-child(2) {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 1800ms;
}
.main-slider-one .active .main-slider-one__author img:nth-child(3) {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 1900ms;
}

.hero-banner {
  position: relative;
  padding: 95px 85px 63px;
  background-color: var(--cleenhearts-white, #fff);
}
@media (max-width: 1599px) {
  .hero-banner {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 991px) {
  .hero-banner {
    padding: 80px 0 60px;
  }
}
.hero-banner__shape {
  position: absolute;
  right: 29%;
  top: 0;
}
.hero-banner__shape img {
  max-width: 100%;
  animation: slowXmove 6s ease infinite;
}
.hero-banner__title {
  font-size: 80px;
  font-weight: 600;
  line-height: 100px;
  letter-spacing: -3.2px;
  text-transform: capitalize;
  margin: -5px 0 0;
}
.hero-banner__title__purple {
  color: var(--cleenhearts-purple, #965995);
}
@media (max-width: 1599px) {
  .hero-banner__title {
    font-size: 60px;
    line-height: 80px;
  }
}
@media (max-width: 1199px) {
  .hero-banner__title {
    margin: 0 0 10px;
  }
  .hero-banner__title br {
    display: none;
  }
}
@media (max-width: 767px) {
  .hero-banner__title {
    font-size: 39px;
    line-height: 50px;
    letter-spacing: -1.2px;
    margin: 0 0 10px;
  }
}
.hero-banner__content {
  position: relative;
  max-width: 383px;
  margin-left: auto;
}
@media (max-width: 991px) {
  .hero-banner__content {
    margin: 0;
    max-width: 100%;
  }
}
.hero-banner__content__text {
  text-transform: capitalize;
  margin: 0 0 41px;
}
.hero-banner__content__btn {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.hero-banner__content__btn .cleenhearts-btn {
  background-color: var(--cleenhearts-purple, #965995);
  color: var(--cleenhearts-white, #fff);
  padding-right: 18px;
}
.hero-banner__content__btn .cleenhearts-btn__icon-box {
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.hero-banner__content__btn .cleenhearts-btn__icon-box span {
  color: var(--cleenhearts-base, #351c42);
}
.hero-banner__content__btn .cleenhearts-btn:hover .cleenhearts-btn__text {
  color: var(--cleenhearts-base, #351c42);
}
.hero-banner__content__author {
  display: flex;
  position: relative;
  margin-left: 30px;
}
@media (max-width: 767px) {
  .hero-banner__content__author {
    margin-left: 12px;
  }
}
.hero-banner__content__author img {
  width: 50px !important;
  height: 50px;
  border-radius: 50%;
  border: 3px solid var(--cleenhearts-white, #fff);
  margin-right: -15px;
}

.main-slider-two {
  position: relative;
  background-color: var(--cleenhearts-white, #fff);
}
.main-slider-two__wrapper {
  position: relative;
  margin: 0 100px;
  overflow: hidden;
  border-radius: 20px;
}
@media (max-width: 1599px) {
  .main-slider-two__wrapper {
    margin: 0 15px;
  }
}
.main-slider-two__carousel {
  position: relative;
  width: 100%;
}
.main-slider-two__carousel.owl-carousel .owl-nav {
  left: 118px;
  margin: auto 0;
  position: absolute;
  right: 0;
  text-align: left;
  top: 50%;
  transform: translateY(-50%);
  max-width: 120px;
}
@media (max-width: 1400px) {
  .main-slider-two__carousel.owl-carousel .owl-nav {
    left: 50px;
  }
}
@media (max-width: 1199px) {
  .main-slider-two__carousel.owl-carousel .owl-nav {
    left: 20px;
  }
}
@media (max-width: 767px) {
  .main-slider-two__carousel.owl-carousel .owl-nav {
    display: none;
  }
}
.main-slider-two__carousel.owl-carousel .owl-nav button {
  transition: all 400ms ease;
  width: 52px;
  height: 52px;
  outline: none;
  box-shadow: none;
  border: none;
  background-color: RGBA(var(--cleenhearts-white-rgb, 255, 255, 255), 0.1);
  border-radius: 50%;
  display: block;
  color: var(--cleenhearts-white, #fff);
  line-height: 53px;
  font-size: 16px;
  margin: 9px 0;
  text-align: center;
}
.main-slider-two__carousel.owl-carousel .owl-nav button:hover {
  background-color: var(--cleenhearts-base, #351c42);
}
.main-slider-two__carousel .owl-dots {
  left: 73px;
  margin: auto 0;
  position: absolute;
  text-align: left;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 1199px) {
  .main-slider-two__carousel .owl-dots {
    left: 50px;
  }
}
@media (max-width: 991px) {
  .main-slider-two__carousel .owl-dots {
    left: 30px;
  }
}
@media (max-width: 767px) {
  .main-slider-two__carousel .owl-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    margin: auto;
    left: 0;
    transform: none;
    top: auto;
    bottom: 30px;
    right: 0;
  }
}
.main-slider-two__carousel .owl-dots .owl-dot {
  display: block;
  margin: 19px 0;
}
@media (max-width: 767px) {
  .main-slider-two__carousel .owl-dots .owl-dot {
    margin: 0 6px;
  }
}
.main-slider-two__carousel .owl-dots .owl-dot span {
  width: 6px;
  height: 6px;
  display: block;
  border-radius: 50%;
  background-color: var(--cleenhearts-white2, #efece7);
  border: none;
  position: relative;
  margin: 0;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-slider-two__carousel .owl-dots .owl-dot span::after {
  width: 24px;
  height: 24px;
  position: absolute;
  content: "";
  left: -9px;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 50%;
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  transform: scale(0);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}
.main-slider-two__carousel .owl-dots .owl-dot:hover span,
.main-slider-two__carousel .owl-dots .owl-dot.active span {
  background-color: var(--cleenhearts-yellow, #fcb720);
}
.main-slider-two__carousel .owl-dots .owl-dot:hover span::after,
.main-slider-two__carousel .owl-dots .owl-dot.active span::after {
  transform: scale(1);
}
.main-slider-two__item {
  background-color: var(--cleenhearts-gray, #888888);
  position: relative;
  z-index: 3;
  padding-top: 204px;
  padding-bottom: 204px;
}
@media (max-width: 991px) {
  .main-slider-two__item {
    padding-top: 180px;
    padding-bottom: 180px;
  }
}
@media (max-width: 767px) {
  .main-slider-two__item {
    padding-top: 90px;
    padding-bottom: 120px;
  }
}
.main-slider-two__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  transform: scale(1);
  transition: transform 10000ms ease, -webkit-transform 10000ms ease;
  overflow: hidden;
}
.main-slider-two__content {
  position: relative;
  z-index: 3;
  padding: 0 83px 0 0;
}
@media (max-width: 1199px) {
  .main-slider-two__content {
    padding-right: 50px;
  }
}
@media (max-width: 767px) {
  .main-slider-two__content {
    padding-right: 20px;
  }
}
.main-slider-two .video-button {
  margin-left: auto;
  position: relative;
  width: 66px;
  height: 66px;
  background-color: RGBA(var(--cleenhearts-white-rgb, 255, 255, 255), 0.5);
  opacity: 0;
  transition: transform 1200ms ease, opacity 1200ms ease;
  transform: translateX(100px);
}
@media (max-width: 767px) {
  .main-slider-two .video-button {
    margin-right: auto;
  }
}
.main-slider-two .video-button__ripple::before,
.main-slider-two .video-button__ripple::after {
  box-shadow: 0 0 0 0 RGBA(var(--cleenhearts-white-rgb, 255, 255, 255), 0.25);
  width: 100%;
  height: 100%;
}
.main-slider-two .video-button span {
  color: var(--cleenhearts-purple, #965995);
  font-size: 16px;
  position: relative;
  z-index: 2;
}
.main-slider-two .video-button:hover {
  background-color: var(--cleenhearts-purple, #965995);
}
.main-slider-two .video-button:hover span {
  color: var(--cleenhearts-white, #fff);
}
.main-slider-two .active .main-slider-two__bg {
  transform: scale(1.12);
}
.main-slider-two .active .video-button {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 1200ms;
}

.main-slider-three {
  position: relative;
  overflow: hidden;
}
.main-slider-three__carousel {
  position: relative;
  width: 100%;
}
@media (max-width: 1199px) {
  .main-slider-three__carousel.owl-carousel .owl-nav {
    left: 20px;
  }
}
.main-slider-three__carousel.owl-carousel .owl-nav button {
  width: 60px;
  height: 60px;
  left: 80px;
  margin: auto 0;
  position: absolute;
  text-align: left;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  border: 1px solid var(--cleenhearts-text, #757277);
  color: var(--cleenhearts-text, #757277);
  border-radius: 50%;
  transition: all 400ms ease;
}
@media (max-width: 1400px) {
  .main-slider-three__carousel.owl-carousel .owl-nav button {
    left: 30px;
  }
}
@media (max-width: 767px) {
  .main-slider-three__carousel.owl-carousel .owl-nav button {
    top: auto;
    transform: none;
    bottom: 30px;
    text-align: center;
    right: 0;
    left: -50px;
    margin: auto;
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
}
.main-slider-three__carousel.owl-carousel .owl-nav button:hover {
  background-color: var(--cleenhearts-purple, #965995);
  border-color: var(--cleenhearts-purple, #965995);
  color: var(--cleenhearts-white, #fff);
}
.main-slider-three__carousel.owl-carousel .owl-nav button.owl-next {
  left: auto;
  right: 80px;
}
@media (max-width: 1400px) {
  .main-slider-three__carousel.owl-carousel .owl-nav button.owl-next {
    right: 30px;
  }
}
@media (max-width: 767px) {
  .main-slider-three__carousel.owl-carousel .owl-nav button.owl-next {
    right: -50px;
    left: 0;
  }
}
.main-slider-three__item {
  position: relative;
  padding-top: 382px;
  padding-bottom: 273px;
  background-color: var(--cleenhearts-base, #351c42);
}
@media (max-width: 1199px) {
  .main-slider-three__item {
    padding-top: 220px;
    padding-bottom: 150px;
  }
}
@media (max-width: 767px) {
  .main-slider-three__item {
    padding-top: 180px;
    padding-bottom: 140px;
  }
}
.main-slider-three__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  overflow: hidden;
  background-color: var(--cleenhearts-base, #351c42);
  transform: scale(1);
  transition: transform 10000ms ease, -webkit-transform 10000ms ease;
}
.main-slider-three__bg::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 100%);
}
.main-slider-three__shape {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-size: auto;
  background-repeat: no-repeat;
  background-position: bottom right;
  opacity: 0.25;
  transition: transform 1000ms ease, -webkit-transform 1000ms ease;
  transform: translateX(100%);
}
@media (max-width: 991px) {
  .main-slider-three__shape {
    display: none;
  }
}
.main-slider-three__bg-svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}
.main-slider-three__bg-svg rect {
  stroke: var(--cleenhearts-base, #351c42);
  fill: none;
  transition: 0.5s;
}
.main-slider-three__bg-svg .steap {
  stroke-width: 0;
}
.main-slider-three__bg-svg .circle1 {
  transition-delay: 0.3s;
}
.main-slider-three__bg-svg .circle2 {
  transition-delay: 0.35s;
}
.main-slider-three__bg-svg .circle3 {
  transition-delay: 0.4s;
}
.main-slider-three__bg-svg .circle4 {
  transition-delay: 0.45s;
}
.main-slider-three__bg-svg .circle5 {
  transition-delay: 0.5s;
}
.main-slider-three__bg-svg .circle6 {
  transition-delay: 0.55s;
}
.main-slider-three__bg-svg .circle7 {
  transition-delay: 0.6s;
}
.main-slider-three__bg-svg .circle8 {
  transition-delay: 0.65s;
}
.main-slider-three__bg-svg .circle9 {
  transition-delay: 0.7s;
}
.main-slider-three .container {
  position: relative;
  z-index: 3;
}
.main-slider-three__content {
  position: relative;
  z-index: 3;
  overflow: hidden;
}
.main-slider-three__sub-title {
  color: var(--cleenhearts-secondary, #f6d469);
  font-size: 18px;
  font-weight: 500;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  text-transform: capitalize;
  line-height: 1;
  opacity: 0;
  margin: 0 0 22px;
  transition: transform 1000ms ease, opacity 1000ms ease;
  transform: translateX(-200px);
}
@media (max-width: 767px) {
  .main-slider-three__sub-title {
    font-size: 16px;
  }
}
@media (max-width: 400px) {
  .main-slider-three__sub-title {
    font-size: 15px;
  }
}
.main-slider-three__title {
  color: var(--cleenhearts-white, #fff);
  font-size: 80px;
  font-weight: 600;
  line-height: 100px;
  letter-spacing: -3.2px;
  text-transform: capitalize;
  margin: 0 0 45px;
  opacity: 0;
  transition: transform 1200ms ease, opacity 1200ms ease;
  transform: translateX(200px);
}
@media (max-width: 1199px) {
  .main-slider-three__title {
    font-size: 55px;
    line-height: 65px;
  }
}
@media (max-width: 767px) {
  .main-slider-three__title {
    font-size: 36px;
    line-height: 45px;
    letter-spacing: -1.2px;
  }
}
.main-slider-three__btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
  overflow: hidden;
}
.main-slider-three__btn .cleenhearts-btn {
  opacity: 0;
  transform: perspective(400px) rotateY(0deg) translateY(80px);
  transform-origin: bottom;
  transition: all 1500ms ease;
}
.main-slider-three .active .steap {
  animation: dash 0.5s linear;
}
@keyframes dash {
  0% {
    stroke-width: 0;
  }
  100% {
    stroke-width: 200px;
  }
}
.main-slider-three .active .main-slider-three__shape {
  transform: translateX(0);
}
.main-slider-three .active .main-slider-three__bg {
  transform: scale(1.05);
}
.main-slider-three .active .main-slider-three__sub-title {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 1200ms;
}
.main-slider-three .active .main-slider-three__title {
  opacity: 1;
  transform: translateX(0);
  transition-delay: 1400ms;
}
.main-slider-three .active .main-slider-three__btn .cleenhearts-btn {
  opacity: 1;
  transform: perspective(400px) rotateY(0deg) translateY(0px);
  transition-delay: 1600ms;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about-one {
  position: relative;
}
.about-one--home .about-one__text-box__image img {
  border-radius: 100px;
}
.about-one__bg {
  position: relative;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: calc(50% - 15px);
  height: calc(100% - 260px);
  background-color: var(--cleenhearts-white2, #efece7);
  z-index: -1;
  border-radius: 0px 1000px 1000px 0px;
}
@media (max-width: 1199px) {
  .about-one__bg {
    display: none;
  }
}
.about-one__bg__border {
  position: absolute;
  top: 0;
  left: -10px;
  width: calc(100% - 80px);
  height: 100%;
  border-radius: 0px 1000px 1000px 0px;
  border: 5px solid var(--cleenhearts-secondary, #f6d469);
  animation: zumpX 1s infinite alternate;
}
.about-one__bg__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--cleenhearts-white2, #efece7);
  mix-blend-mode: multiply;
  border-radius: 0px 1000px 1000px 0px;
}
.about-one .row {
  align-items: center;
}
.about-one__image {
  position: relative;
  z-index: 1;
  margin-left: auto;
  margin-right: 64px;
  width: 464px;
  height: 464px;
}
@media (max-width: 1399px) {
  .about-one__image {
    margin-right: 50px;
  }
}
@media (max-width: 1199px) {
  .about-one__image {
    margin-left: 45px;
    margin-right: 0;
  }
}

@media (max-width: 1199px) {
  .about-one__left {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 575px) {
  .about-one__image {
    width: 380px;
    height: 380px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 425px) {
  .about-one__image {
    width: 330px;
    height: 330px;
  }
}
@media (max-width: 375px) {
  .about-one__image {
    width: 320px;
    height: 320px;
  }
}
@media (max-width: 360px) {
  .about-one__image {
    width: 290px;
    height: 290px;
  }
}
.about-one__image__one {
  width: 464px;
  height: 464px;
  border-radius: 50%;
}
@media (max-width: 575px) {
  .about-one__image__one {
    width: 380px;
    height: 380px;
  }
}
@media (max-width: 425px) {
  .about-one__image__one {
    width: 330px;
    height: 330px;
  }
}
@media (max-width: 375px) {
  .about-one__image__one {
    width: 320px;
    height: 320px;
  }
}
@media (max-width: 360px) {
  .about-one__image__one {
    width: 290px;
    height: 290px;
  }
}
.about-one__video {
  position: absolute;
  top: 24px;
  left: -42px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 164px;
  height: 164px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media (max-width: 575px) {
  .about-one__video {
    width: 100px;
    height: 100px;
    left: -10px;
  }
}
@media (max-width: 375px) {
  .about-one__video {
    width: 85px;
    height: 85px;
    left: 0px;
  }
}
.about-one__video__btn {
  background-color: rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0.5);
  width: 67px;
  height: 67px;
}
.about-one__video__btn span {
  color: var(--cleenhearts-purple, #965995);
  font-size: 18px;
}
.about-one__video__btn .video-button__ripple::before,
.about-one__video__btn .video-button__ripple::after {
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 0 rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0.25);
}
.about-one__profile {
  position: absolute;
  bottom: 104px;
  right: -35px;
  padding: 17px 21px 17px 17px;
  border-radius: 1000px;
  background-color: var(--cleenhearts-white, #fff);
  display: block;
}
@media (max-width: 1399px) {
  .about-one__profile {
    right: -20px;
  }
}
@media (max-width: 1199px) {
  .about-one__profile {
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  }
}
@media (max-width: 575px) {
  .about-one__profile {
    padding: 12px 21px 12px 12px;
    bottom: 40px;
    right: -10px;
  }
  .about-one__profile .volunteer-profile__image {
    width: 60px;
    height: 60px;
  }
}
@media (max-width: 425px) {
  .about-one__profile {
    bottom: 30px;
  }
}
@media (max-width: 375px) {
  .about-one__profile {
    right: 0;
    bottom: 25px;
  }
}
.about-one__profile .volunteer-profile__inner {
  border: 0;
  padding: 0;
}
.about-one__content {
  margin-top: 2px;
}
.about-one .sec-title {
  margin-bottom: 34px;
}
.about-one .sec-title__tagline {
  margin-bottom: 23px;
}
.about-one__text-box {
  display: flex;
  align-items: center;
  gap: 1px;
}
@media (max-width: 767px) {
  .about-one__text-box {
    flex-direction: column;
    align-items: flex-start;
  }
}
.about-one__text-box__image {
  width: 170px;
  flex-shrink: 0;
}
.about-one__text-box__image img {
  display: block;
  width: 100%;
  border-radius: 20px;
}
.about-one__text {
  margin-bottom: 0;
}
.about-one__wrapper {
  margin: 29px 0 40px;
  padding: 30px 0 26px;
  border-top: 1px solid var(--cleenhearts-white2, #efece7);
  border-bottom: 1px solid var(--cleenhearts-white2, #efece7);
}
.about-one__donate {
  width: 100%;
}
.about-one__donate--one {
  max-width: 112px;
}
@media (max-width: 1199px) {
  .about-one__donate--one {
    max-width: 100%;
    text-align: center;
  }
}
.about-one__donate--two {
  max-width: 126px;
}
@media (max-width: 1199px) {
  .about-one__donate--two {
    max-width: 100%;
    text-align: center;
  }
}
.about-one__donate__icon {
  text-align: center;
  margin-bottom: 11px;
  transition: transform 400ms ease;
}
.about-one__donate__icon span {
  font-size: 40px;
  color: var(--cleenhearts-purple, #965995);
  transition: all 0.4s ease-in-out;
}
.about-one__donate:hover .about-one__donate__icon {
  transform: scale(1.3);
}
.about-one__donate:hover .about-one__donate__icon span {
  transform: scale(1.5);
  color: var(--cleenhearts-base, #351c42);
}
.about-one__donate__title {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  line-height: 1.3;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 9px;
}
.about-one__donate__text {
  text-align: center;
}
.about-one__donate__text span {
  font-size: 16px;
  color: var(--cleenhearts-purple, #965995);
  font-weight: 700;
  line-height: 1.3;
  text-transform: capitalize;
}
.about-one__hand {
  position: absolute;
  right: 0;
  bottom: 134px;
  z-index: -1;
  animation: slowXmove 6s ease infinite;
}
@media (max-width: 1680px) {
  .about-one__hand {
    width: 190px;
  }
}
@media (max-width: 1599px) {
  .about-one__hand {
    top: 10px;
    bottom: auto;
    width: 190px;
  }
}
@media (max-width: 767px) {
  .about-one__hand {
    display: none;
  }
}

.about-two {
  position: relative;
  background-color: var(--cleenhearts-base, #351c42);
  overflow: hidden;
}
.about-two .container {
  position: relative;
  z-index: 11;
}
.about-two__image {
  position: relative;
  top: -3px;
}
.about-two__image__inner {
  margin-left: 4px;
  width: 100%;
  max-width: 463px;
  position: relative;
}
@media (max-width: 1199px) {
  .about-two__image__inner {
    max-width: 430px;
    margin-left: 0;
  }
}
.about-two__image__one {
  width: 100%;
}
.about-two__image__two {
  width: 217px;
  position: absolute;
  top: 0;
  right: -64px;
  animation: zump 3s infinite linear;
}
@media (max-width: 1199px) {
  .about-two__image__two {
    right: 0;
  }
}
@media (max-width: 991px) {
  .about-two__image__two {
    right: -64px;
  }
}
@media (max-width: 575px) {
  .about-two__image__two {
    right: 0;
  }
}
@media (max-width: 450px) {
  .about-two__image__two {
    width: 200px;
  }
}
@media (max-width: 425px) {
  .about-two__image__two {
    width: 170px;
  }
}
@media (max-width: 375px) {
  .about-two__image__two {
    width: 150px;
  }
}
@media (max-width: 360px) {
  .about-two__image__two {
    width: 130px;
  }
}
.about-two .sec-title {
  margin-bottom: 39px;
}
.about-two .sec-title__tagline {
  color: var(--cleenhearts-white2, #efece7);
  margin-bottom: 23px;
}
.about-two .sec-title__tagline::before {
  background-color: var(--cleenhearts-white2, #efece7);
}
.about-two .sec-title__tagline::after {
  border-color: var(--cleenhearts-white2, #efece7);
}
.about-two .sec-title__title {
  color: var(--cleenhearts-white, #fff);
}
.about-two .sec-title__title__inner {
  z-index: 11;
}
.about-two .sec-title__title__inner::after {
  z-index: -11;
}
.about-two__text {
  color: var(--cleenhearts-white2, #efece7);
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 54px;
}
.about-two .contact-information__btn {
  margin-right: 15px;
  padding-right: 20px;
}
@media (max-width: 575px) {
  .about-two .contact-information__btn {
    margin-right: 0;
  }
}
.about-two .contact-information__btn::after {
  display: none;
}
.about-two .contact-information__phone {
  padding-left: 0;
  margin-left: 15px;
}
@media (max-width: 575px) {
  .about-two .contact-information__phone {
    margin-left: 0;
  }
}
.about-two .contact-information__phone__icon {
  border-color: var(--cleenhearts-secondary, #f6d469);
}
.about-two .contact-information__phone__icon span {
  color: var(--cleenhearts-secondary, #f6d469);
}
.about-two .contact-information__phone__text h5,
.about-two .contact-information__phone__text span {
  color: var(--cleenhearts-white2, #efece7);
}
.about-two .contact-information__phone__text h5:hover {
  color: var(--cleenhearts-secondary, #f6d469);
}
.about-two
  .contact-information__phone:hover
  .contact-information__phone__icon::after {
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.about-two
  .contact-information__phone:hover
  .contact-information__phone__icon
  span {
  color: var(--cleenhearts-base, #351c42);
}
.about-two__shape__shape {
  position: absolute;
  width: 270px;
  height: 199px;
  background: rgba(255, 255, 255, 0.1);
}
@media (max-width: 1799px) {
  .about-two__shape__shape {
    width: 200px;
    height: 150px;
  }
}
@media (max-width: 1599px) {
  .about-two__shape__shape {
    width: 160px;
    height: 120px;
  }
}
@media (max-width: 1199px) {
  .about-two__shape__shape {
    width: 150px;
    height: 110px;
  }
}
@media (max-width: 767px) {
  .about-two__shape__shape {
    width: 130px;
    height: 100px;
  }
}
@media (max-width: 575px) {
  .about-two__shape__shape {
    width: 100px;
    height: 70px;
  }
}
.about-two__shape__shape::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--cleenhearts-secondary, #f6d469);
}
.about-two__shape__shape--one {
  top: -2px;
  left: 105px;
  border-radius: 0px 0px 1000px 1000px;
  animation: zumpX 2000ms infinite alternate;
}
@media (max-width: 1799px) {
  .about-two__shape__shape--one {
    left: 70px;
  }
}
@media (max-width: 767px) {
  .about-two__shape__shape--one {
    left: 50px;
  }
}
.about-two__shape__shape--one::after {
  left: 40px;
  border-radius: 0px 0px 1000px 1000px;
  animation: zumpX 2000ms infinite alternate;
}
.about-two__shape__shape--two {
  right: 105px;
  bottom: -2px;
  border-radius: 1000px 1000px 0px 0px;
  animation: zumpXtwo 2000ms infinite alternate;
}
@media (max-width: 1799px) {
  .about-two__shape__shape--two {
    right: 70px;
  }
}
@media (max-width: 767px) {
  .about-two__shape__shape--two {
    right: 50px;
  }
}
.about-two__shape__shape--two::after {
  right: 40px;
  border-radius: 1000px 1000px 0px 0px;
  animation: zumpXtwo 2000ms infinite alternate;
}

.about-three {
  position: relative;
  padding-top: 271px;
  padding-bottom: 130px;
  background-color: var(--cleenhearts-white, #fff);
}
@media (max-width: 1199px) {
  .about-three {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}
@media (max-width: 767px) {
  .about-three {
    padding: 110px 0;
  }
}
@media (max-width: 575px) {
  .about-three {
    padding: 80px 0;
  }
}
.about-three__image__inner {
  position: relative;
  width: 100%;
  max-width: 370px;
  margin-left: auto;
}
@media (max-width: 1199px) {
  .about-three__image__inner {
    max-width: 340px;
  }
}
@media (max-width: 991px) {
  .about-three__image__inner {
    max-width: 370px;
    margin-left: 200px;
  }
}
@media (max-width: 767px) {
  .about-three__image__inner {
    max-width: 340px;
    margin-left: 130px;
  }
}
@media (max-width: 500px) {
  .about-three__image__inner {
    margin-left: 35px;
  }
}
@media (max-width: 412px) {
  .about-three__image__inner {
    max-width: 310px;
    margin-left: 25px;
  }
}
@media (max-width: 375px) {
  .about-three__image__inner {
    max-width: 300px;
  }
}
@media (max-width: 360px) {
  .about-three__image__inner {
    max-width: 263px;
  }
}
.about-three__image__inner::after {
  content: "";
  position: absolute;
  top: -30px;
  left: -55px;
  width: 100%;
  height: 100%;
  border-radius: 1000px 1000px 0 0;
  border: 3px solid var(--cleenhearts-purple, #965995);
  transition: all 500ms ease;
  z-index: 1;
  transition: all 400ms ease;
}
@media (max-width: 500px) {
  .about-three__image__inner::after {
    left: -35px;
  }
}
@media (max-width: 412px) {
  .about-three__image__inner::after {
    top: -25px;
    left: -25px;
  }
}
.about-three__image__inner:hover::after {
  left: -30px;
}
@media (max-width: 500px) {
  .about-three__image__inner:hover::after {
    left: -25px;
  }
}
@media (max-width: 412px) {
  .about-three__image__inner:hover::after {
    top: -20px;
    left: -20px;
  }
}
.about-three__image__one {
  position: relative;
  border-radius: 1000px 1000px 0 0;
  max-width: 100%;
  display: block;
  z-index: 11;
}
.about-three__image__two {
  border-radius: 20px;
  position: absolute;
  left: -200px;
  bottom: -71px;
  z-index: 12;
}
@media (max-width: 1199px) {
  .about-three__image__two {
    max-width: 200px;
    left: -120px;
    bottom: -101px;
  }
}
@media (max-width: 991px) {
  .about-three__image__two {
    max-width: 270px;
    left: -200px;
    bottom: -71px;
  }
}
@media (max-width: 767px) {
  .about-three__image__two {
    max-width: 200px;
    left: -130px;
  }
}
@media (max-width: 500px) {
  .about-three__image__two {
    display: none;
  }
}
.about-three__experience {
  position: absolute;
  right: 0;
  bottom: -100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 317px;
  height: 248px;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  z-index: 12;
  animation: movebounce3 3s linear infinite;
}
.about-three__experience__content {
  text-align: center;
}
.about-three__experience__title {
  font-size: 30px;
  line-height: 24px;
  letter-spacing: -1.2px;
  text-transform: capitalize;
  margin-bottom: 12px;
}
.about-three__experience__text {
  color: var(--cleenhearts-base, #351c42);
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.64px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.about-three__experience__text br {
  display: inline;
}
.about-three__shape {
  position: absolute;
  top: -30px;
  left: -80%;
  animation: zumpX 1500ms infinite alternate;
  z-index: 1;
}
.about-three__shape-box {
  position: absolute;
  left: -66px;
  bottom: -100px;
  z-index: 12;
  width: 270px;
  height: 260px;
  border: 1px solid var(--cleenhearts-secondary, #f6d469);
  border-radius: 20px;
  z-index: 1;
  animation: zumpX 1500ms infinite alternate;
}
@media (max-width: 1199px) {
  .about-three__shape-box {
    bottom: -130px;
  }
}
@media (max-width: 991px) {
  .about-three__shape-box {
    bottom: -100px;
  }
}
@media (max-width: 500px) {
  .about-three__shape-box {
    display: none;
  }
}
.about-three__content {
  margin-top: -20px;
}
@media (max-width: 991px) {
  .about-three__content {
    margin-top: 100px;
  }
}
.about-three .sec-title {
  margin-bottom: 40px;
}
.about-three .sec-title__tagline {
  margin-bottom: 23px;
}
.about-three .sec-title__title__inner {
  z-index: 11;
}
.about-three .sec-title__title__inner::after {
  z-index: -11;
}
.about-three__text {
  text-transform: capitalize;
}
.about-three__text--one {
  font-weight: 700;
  margin-bottom: 29px;
}
.about-three__text--two {
  margin-bottom: 58px;
}
@media (max-width: 1199px) {
  .about-three__text--two {
    margin-bottom: 40px;
  }
}
.about-three__progress {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-bottom: 55px;
}
@media (max-width: 1199px) {
  .about-three__progress {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 991px) {
  .about-three__progress {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767px) {
  .about-three__progress {
    grid-template-columns: repeat(1, 1fr);
  }
}
.about-three__progress .progress-box__title {
  font-size: 16px;
  line-height: 14px;
  margin-bottom: 10px;
}
.about-three__progress .progress-box__bar__inner::before,
.about-three__progress .progress-box__bar__inner::after {
  display: none;
}
.about-three__progress .progress-box__number {
  bottom: calc(100% + 11px);
}

/*--------------------------------------------------------------
# Help Donate
--------------------------------------------------------------*/
.help-donate-one {
  padding: 132px 0 132px;
  position: relative;
  background-color: var(--cleenhearts-base, #351c42);
  overflow: hidden;
  z-index: 1;
}
.help-donate-one__bg {
  position: absolute;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.1;
}
.help-donate-one__shape-one {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: top left;
  background-repeat: no-repeat;
  background-size: auto;
  animation: zumpX 1200ms infinite alternate;
}
.help-donate-one__shape-two {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: auto;
  animation: zumpX 1200ms infinite alternate;
}
.help-donate-one .sec-title {
  margin-bottom: 22px;
}
.help-donate-one .sec-title__tagline {
  color: var(--cleenhearts-white, #fff);
  margin-bottom: 0;
}
.help-donate-one .sec-title__tagline::before {
  background-color: var(--cleenhearts-white, #fff);
}
.help-donate-one .sec-title__tagline::after {
  border-color: var(--cleenhearts-white, #fff);
}
.help-donate-one__slide {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  animation: textScrolling 25s linear infinite;
  animation-direction: reverse;
  will-change: transform;
  position: relative;
  white-space: nowrap;
}
.help-donate-one__slide:hover {
  animation-play-state: paused;
}
@keyframes textScrolling {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(0%, 0, 0);
  }
}
.help-donate-one__text {
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  font-size: 80px;
  font-weight: 700;
  line-height: 1.125;
  text-transform: capitalize;
  margin: 0;
  display: block;
  transition: all 400ms ease;
}
@media (max-width: 767px) {
  .help-donate-one__text {
    font-size: 70px;
  }
}
@media (max-width: 575px) {
  .help-donate-one__text {
    font-size: 60px;
  }
}
.help-donate-one__text--one {
  color: RGBA(var(--cleenhearts-base-rgb, 53, 28, 66), 1);
  text-shadow: 2px 2px 0 var(--cleenhearts-white2, #efece7),
    -2px -2px 0 var(--cleenhearts-white2, #efece7),
    2px -2px 0 var(--cleenhearts-white2, #efece7),
    -2px 2px 0 var(--cleenhearts-white2, #efece7),
    2px 2px 0 var(--cleenhearts-white2, #efece7);
}
.help-donate-one__text--two {
  color: var(--cleenhearts-white, #fff);
}

/*--------------------------------------------------------------
# Story
--------------------------------------------------------------*/
.story-one {
  position: relative;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  padding-bottom: 121px;
}
@media (max-width: 767px) {
  .story-one {
    padding-bottom: 101px;
  }
}
@media (max-width: 575px) {
  .story-one {
    padding-bottom: 71px;
  }
}
.story-one::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(var(--cleenhearts-base-rgb, 53, 28, 66), 0.75);
}
.story-one .container {
  position: relative;
  z-index: 1;
}
.story-one .sec-title {
  width: 100%;
  max-width: 441px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.story-one .sec-title__tagline {
  margin-left: auto;
  margin-right: auto;
  color: var(--cleenhearts-white2, #efece7);
  margin-bottom: 23px;
}
.story-one .sec-title__tagline::before {
  background-color: var(--cleenhearts-white2, #efece7);
}
.story-one .sec-title__tagline::after {
  border-color: var(--cleenhearts-white2, #efece7);
}
.story-one .sec-title__title {
  color: var(--cleenhearts-white, #fff);
}
.story-one__image img {
  display: block;
  width: 100%;
  border-radius: 20px;
}
.story-one__content {
  padding: 40px 40px 39px;
  background-color: var(--cleenhearts-white2, #efece7);
  border-radius: 20px;
}
@media (max-width: 575px) {
  .story-one__content {
    padding: 30px 30px 29px;
  }
}
.story-one__title {
  font-size: 22px;
  line-height: 22px;
  letter-spacing: -0.66px;
  text-transform: capitalize;
  margin-bottom: 30px;
}
.story-one__text {
  text-transform: capitalize;
  margin-bottom: 0;
}
.story-one__text--one {
  margin-bottom: 20px;
}
.story-one .volunteer-profile {
  margin-top: 44px;
}
.story-one__divider {
  margin-top: 30px;
  margin-bottom: 31px;
  height: 2px;
  background-color: var(--cleenhearts-text, #757277);
}
.story-one__tabs-box .tab-buttons {
  position: relative;
  z-index: 11;
}
.story-one__tabs-box .tab-buttons .tab-btn--one {
  padding-left: 1px;
}
.story-one__tabs-box .tab-buttons .tab-btn--two {
  padding-left: 8px;
}
.story-one__tabs-box .tab-buttons .tab-btn--three {
  padding-left: 24px;
}
.story-one__tabs-box .tab-buttons .tab-btn--four {
  text-align: right;
  padding-right: 25px;
}
.story-one__tabs-box .tab-buttons .tab-btn--five {
  text-align: right;
  padding-right: 11px;
}
.story-one__tabs-box .tab-buttons .tab-btn--six {
  text-align: right;
}
.story-one__tabs-box .tab-buttons .tab-btn--six .tab-btn__text {
  position: relative;
  right: -5px;
}
@media (max-width: 1199px) {
  .story-one__tabs-box .tab-buttons .tab-btn {
    padding: 0;
    text-align: center;
  }
  .story-one__tabs-box .tab-buttons .tab-btn .tab-btn__text {
    right: 0;
  }
}
.story-one__tabs-box .tab-buttons .tab-btn__text {
  cursor: pointer;
  font-size: 50px;
  line-height: 1;
  text-transform: capitalize;
  color: RGBA(var(--cleenhearts-base-rgb, 53, 28, 66), 1);
  text-shadow: 1px 1px 0 var(--cleenhearts-white, #fff),
    -1px -1px 0 var(--cleenhearts-white, #fff),
    1px -1px 0 var(--cleenhearts-white, #fff),
    -1px 1px 0 var(--cleenhearts-white, #fff),
    1px 1px 0 var(--cleenhearts-white, #fff);
  transition: all 500ms ease;
}
.story-one__tabs-box .tab-buttons .tab-btn .tab-btn__text:hover,
.story-one__tabs-box .tab-buttons .tab-btn.active-btn .tab-btn__text {
  color: var(--cleenhearts-secondary, #f6d469);
  text-shadow: 1px 1px 0 var(--cleenhearts-secondary, #f6d469),
    -1px -1px 0 var(--cleenhearts-secondary, #f6d469),
    1px -1px 0 var(--cleenhearts-secondary, #f6d469),
    -1px 1px 0 var(--cleenhearts-secondary, #f6d469),
    1px 1px 0 var(--cleenhearts-secondary, #f6d469);
}
.story-one__tabs-box .tabs-content {
  position: relative;
  display: block;
}
.story-one__tabs-box .tabs-content .tab {
  position: relative;
  display: none;
  -webkit-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  transition: all 600ms ease;
  z-index: 10;
}
.story-one__tabs-box .tabs-content .tab.active-tab {
  display: block;
}

/*--------------------------------------------------------------
# Inspiring
--------------------------------------------------------------*/
.inspiring-one {
  position: relative;
  background-color: var(--cleenhearts-white2, #efece7);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}
.inspiring-one .container {
  position: relative;
  z-index: 11;
}
.inspiring-one .sec-title {
  margin-bottom: 39px;
}
.inspiring-one .sec-title__tagline {
  margin-bottom: 23px;
}
.inspiring-one__text {
  margin-bottom: 25px;
}
.inspiring-one__inner {
  width: 100%;
  max-width: 494px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}
@media (max-width: 575px) {
  .inspiring-one__inner {
    grid-template-columns: 1fr;
  }
}
.inspiring-one__inner__content {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 30px;
  border-radius: 10px;
  background: var(--cleenhearts-white, #fff);
}
.inspiring-one__inner__title {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 20px;
  text-transform: capitalize;
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .inspiring-one__inner__title {
    order: 1;
  }
}
.inspiring-one__inner__icon {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  background-color: var(--cleenhearts-purple, #965995);
  border-radius: 10px;
  transition: all 400ms ease;
}
@media (max-width: 575px) {
  .inspiring-one__inner__icon {
    order: 0;
  }
}
.inspiring-one__inner__icon span {
  font-size: 40px;
  color: var(--cleenhearts-white, #fff);
  transition: all 400ms ease;
}
.inspiring-one__inner__content:hover .inspiring-one__inner__icon {
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.inspiring-one__inner__content:hover .inspiring-one__inner__icon span {
  color: var(--cleenhearts-base, #351c42);
}
.inspiring-one__image {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
@media (max-width: 1199px) {
  .inspiring-one__image {
    justify-content: flex-start;
  }
}
@media (max-width: 575px) {
  .inspiring-one__image {
    gap: 20px;
  }
}
.inspiring-one__image__inner {
  position: absolute;
  max-width: 270px;
}
.inspiring-one__image__inner img {
  width: 100%;
}
.inspiring-one__image__inner--one {
  top: -130px;
  left: 0;
}
@media (max-width: 1199px) {
  .inspiring-one__image__inner--one {
    position: static;
  }
}
.inspiring-one__image__inner--one img {
  border-radius: 0 0 1000px 1000px;
}
@media (max-width: 1199px) {
  .inspiring-one__image__inner--one img {
    border-radius: 1000px;
  }
}
.inspiring-one__image__inner--two {
  right: 0;
  top: 0;
}
@media (max-width: 1199px) {
  .inspiring-one__image__inner--two {
    position: static;
  }
}
.inspiring-one__image__inner--two img {
  border-radius: 1000px 1000px 0 0;
}
@media (max-width: 1199px) {
  .inspiring-one__image__inner--two img {
    border-radius: 1000px;
  }
}
.inspiring-one__shape {
  position: relative;
  position: absolute;
  width: 234px;
  height: 234px;
  border-radius: 50%;
  background-color: var(--cleenhearts-secondary, #f6d469);
  z-index: 1;
}
.inspiring-one__shape::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid var(--cleenhearts-secondary, #f6d469);
  z-index: 1;
}
.inspiring-one__shape--one {
  top: -117px;
  left: 50px;
  animation: zumpX 1500ms infinite alternate;
}
@media (max-width: 1799px) {
  .inspiring-one__shape--one {
    top: -140px;
  }
}
@media (max-width: 767px) {
  .inspiring-one__shape--one {
    top: -150px;
  }
}
@media (max-width: 575px) {
  .inspiring-one__shape--one {
    top: -175px;
    left: 20px;
  }
}
@media (max-width: 412px) {
  .inspiring-one__shape--one {
    left: 0px;
  }
}
.inspiring-one__shape--one::after {
  top: 0;
  left: 37px;
  animation: zumpX 1500ms infinite alternate;
}
.inspiring-one__shape--two {
  right: 50px;
  bottom: -117px;
  animation: zumpXtwo 1500ms infinite alternate;
}
@media (max-width: 1799px) {
  .inspiring-one__shape--two {
    bottom: -140px;
  }
}
@media (max-width: 767px) {
  .inspiring-one__shape--two {
    bottom: -150px;
  }
}
@media (max-width: 575px) {
  .inspiring-one__shape--two {
    right: 20px;
    bottom: -175px;
  }
}
@media (max-width: 412px) {
  .inspiring-one__shape--two {
    right: 0px;
  }
}
.inspiring-one__shape--two::after {
  top: 0;
  right: 37px;
  animation: zumpXtwo 1500ms infinite alternate;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team-one__top {
  margin-bottom: 92px;
}
.team-one .sec-title {
  width: 100%;
  max-width: 570px;
  margin-bottom: 0;
}
.team-one .sec-title__tagline {
  margin-bottom: 23px;
}
.team-one__text {
  text-transform: capitalize;
  margin-bottom: 0;
}

.team-single {
  position: relative;
}
.team-single::before {
  content: "";
  position: absolute;
  top: -25px;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1000px 1000px 20px 20px;
  border: 2px solid var(--cleenhearts-purple, #965995);
  transition: all 500ms ease;
  z-index: -1;
}
.team-single:hover::before {
  top: -14px;
}
.team-single__image {
  position: relative;
  border-radius: 1000px 1000px 20px 20px;
  overflow: hidden;
  z-index: 1;
  width: calc(100% - 28px);
  left: 28px;
  transition: all 500ms ease;
}
.team-single__image img {
  display: block;
  width: 100%;
  border-radius: 1000px 1000px 20px 20px;
}
.team-single:hover .team-single__image {
  left: 14px;
}
.team-single__content {
  padding: 35px 20px;
  width: 100%;
  height: 100%;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(var(--cleenhearts-base-rgb, 53, 28, 66), 0.5);
  border-radius: 1000px 1000px 20px 20px;
  z-index: 11;
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  opacity: 0;
  -webkit-perspective: 50em;
  perspective: 50em;
  transition: transform 500ms ease, opacity 500ms ease;
}
.team-single__content__inner {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 35px;
  text-align: center;
}
.team-single:hover .team-single__content {
  opacity: 1;
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
}
.team-single__social {
  margin-top: 15px;
}
@media (max-width: 375px) {
  .team-single__social {
    margin-top: 20px;
  }
}
.team-single__social li a {
  border-color: var(--cleenhearts-white, #fff);
  color: var(--cleenhearts-white, #fff);
}
.team-single__social li a::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform: scale(0);
  opacity: 0;
  transition: transform 400ms ease, opacity 400ms ease;
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.team-single__social li a:hover {
  border-color: var(--cleenhearts-secondary, #f6d469);
  color: var(--cleenhearts-base, #351c42);
}
.team-single__name {
  font-size: 24px;
  color: var(--cleenhearts-white, #fff);
  line-height: 1.458;
  text-transform: capitalize;
  margin-bottom: 10px;
}
@media (max-width: 375px) {
  .team-single__name {
    font-size: 22px;
  }
}
.team-single__designation {
  color: var(--cleenhearts-secondary, #f6d469);
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Help People
--------------------------------------------------------------*/
.help-people {
  padding-top: 80px;
  padding-bottom: 60px;
  position: relative;
  background-color: var(--cleenhearts-base, #351c42);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media (max-width: 1199px) {
  .help-people {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}
@media (max-width: 767px) {
  .help-people {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}
@media (max-width: 575px) {
  .help-people {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (max-width: 1199px) {
  .help-people .row {
    --bs-gutter-y: 60px;
  }
}
.help-people__images {
  position: relative;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 50%;
  height: calc(100% - 260px);
  z-index: 1;
}
@media (max-width: 1199px) {
  .help-people__images {
    position: relative;
    transform: translateY(0);
    width: 100%;
  }
}
.help-people__images__inner {
  padding: 16px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: -16px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
  background-color: var(--cleenhearts-secondary, #f6d469);
  border-radius: 0px 1000px 1000px 0px;
}
@media (max-width: 1199px) {
  .help-people__images__inner {
    position: relative;
    transform: translateY(0);
    left: -150px;
    width: calc(100% + 150px);
  }
}
@media (max-width: 991px) {
  .help-people__images__inner {
    left: -150px;
    width: calc(100% + 150px);
  }
}
.help-people__images__inner img {
  flex-shrink: 0;
  width: 376px;
  height: 376px;
  border-radius: 50%;
}
@media (max-width: 991px) {
  .help-people__images__inner img {
    width: 330px;
    height: 330px;
  }
}
@media (max-width: 767px) {
  .help-people__images__inner img {
    width: 270px;
    height: 270px;
  }
}
@media (max-width: 412px) {
  .help-people__images__inner img {
    width: 250px;
    height: 250px;
  }
}
@media (max-width: 360px) {
  .help-people__images__inner img {
    width: 230px;
    height: 230px;
  }
}
.help-people__content {
  position: relative;
  z-index: 11;
}
@media (min-width: 1200px) {
  .help-people__content {
    margin-left: auto;
  }
}
.help-people .sec-title {
  margin-bottom: 41px;
}
.help-people .sec-title__tagline {
  color: var(--cleenhearts-white, #fff);
  margin-bottom: 24px;
}
.help-people .sec-title__tagline::before {
  background-color: var(--cleenhearts-white, #fff);
}
.help-people .sec-title__tagline::after {
  border-color: var(--cleenhearts-white, #fff);
}
.help-people .sec-title__title {
  color: var(--cleenhearts-white, #fff);
}
.help-people__text {
  color: rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0.6);
  font-weight: 500;
  margin-bottom: 41px;
}

.help-people__shape__one {
  position: absolute;
  right: 38px;
  bottom: 0;
  z-index: 1;
  animation: zumpX 1500ms infinite alternate;
}

/*--------------------------------------------------------------
# Worldwide
--------------------------------------------------------------*/
.worldwide-one {
  position: relative;
  background-color: var(--cleenhearts-white, #fff);
  overflow: hidden;
}
.worldwide-one__bg {
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media (max-width: 1199px) {
  .worldwide-one__bg {
    background-position: right;
  }
}
.worldwide-one__bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(98deg, #fff 43.75%, rgba(255, 255, 255, 0) 99.1%);
}
.worldwide-one .container {
  position: relative;
  z-index: 11;
}
.worldwide-one .sec-title {
  margin-bottom: 40px;
}
.worldwide-one__text {
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 40px;
}
.worldwide-one__inner {
  display: flex;
  align-items: center;
  gap: 20px;
}
.worldwide-one__inner__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  background-color: var(--cleenhearts-purple, #965995);
  border-radius: 10px;
  transition: all 400ms ease;
}
.worldwide-one__inner__icon span {
  font-size: 40px;
  color: var(--cleenhearts-white, #fff);
  transition: all 400ms ease;
}
.worldwide-one__inner:hover .worldwide-one__inner__icon {
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.worldwide-one__inner:hover .worldwide-one__inner__icon span {
  color: var(--cleenhearts-base, #351c42);
}
.worldwide-one__inner__title {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.272;
  text-transform: capitalize;
  margin-bottom: 15px;
}
.worldwide-one__inner__text {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.25;
  text-transform: capitalize;
  margin-bottom: 0;
}
.worldwide-one__bottom {
  margin-top: 60px;
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}
.worldwide-one__bottom .cleenhearts-btn {
  border-color: var(--cleenhearts-base, #351c42);
}
.worldwide-one__right {
  position: relative;
  top: -3px;
  min-height: 494px;
}
@media (max-width: 1199px) {
  .worldwide-one__right {
    max-width: 572px;
  }
}
@media (max-width: 468px) {
  .worldwide-one__right {
    min-height: 400px;
  }
}
.worldwide-one__location {
  position: relative;
  position: absolute;
}
.worldwide-one__location--one {
  top: -2px;
  right: -2px;
}
.worldwide-one__location--one .worldwide-one__location__btn {
  top: 0;
  right: 0;
}
.worldwide-one__location--one .worldwide-one__location__popup {
  top: 26px;
  right: 28px;
}
.worldwide-one__location--two {
  top: 44.2%;
  right: -3px;
}
.worldwide-one__location--two .worldwide-one__location__btn {
  top: 0;
  right: 0;
}
.worldwide-one__location--two .worldwide-one__location__popup {
  top: 26px;
  right: 28px;
}
.worldwide-one__location--three {
  right: 8%;
  bottom: 22.5%;
}
.worldwide-one__location--three .worldwide-one__location__btn {
  top: 0;
  right: 0;
}
.worldwide-one__location--three .worldwide-one__location__popup {
  top: -104px;
  right: 28px;
}
.worldwide-one__location--four {
  right: 35%;
  bottom: 36px;
}
.worldwide-one__location--four .worldwide-one__location__btn {
  top: 0;
  right: 0;
}
.worldwide-one__location--four .worldwide-one__location__popup {
  top: -104px;
  right: 28px;
}
.worldwide-one__location--five {
  left: 14%;
  bottom: 74px;
}
.worldwide-one__location--five .worldwide-one__location__btn {
  top: 0;
  left: 0;
}
.worldwide-one__location--five .worldwide-one__location__popup {
  top: -104px;
  left: 28px;
}
.worldwide-one__location--six {
  top: 30%;
  left: 0;
}
.worldwide-one__location--six .worldwide-one__location__btn {
  top: 0;
  left: 0;
}
.worldwide-one__location--six .worldwide-one__location__popup {
  top: -104px;
  left: 28px;
}
.worldwide-one__location--seven {
  top: 0;
  right: 44.7%;
}
.worldwide-one__location--seven .worldwide-one__location__btn {
  top: 0;
  left: 0;
}
.worldwide-one__location--seven .worldwide-one__location__popup {
  top: 26px;
  right: -10px;
}
.worldwide-one__location--eight {
  top: 17.5%;
  left: 35.2%;
}
.worldwide-one__location--eight .worldwide-one__location__btn {
  top: 0;
  left: 0;
}
.worldwide-one__location--eight .worldwide-one__location__popup {
  top: -104px;
  left: 28px;
}
.worldwide-one__location--nine {
  top: 46%;
  left: 33%;
}
.worldwide-one__location--nine .worldwide-one__location__btn {
  top: 0;
  left: 0;
}
.worldwide-one__location--nine .worldwide-one__location__popup {
  bottom: -12px;
  left: 28px;
}
.worldwide-one__location__btn {
  position: relative;
  position: absolute;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  transition: transform 400ms ease;
  background-color: transparent;
  z-index: 1;
}
.worldwide-one__location__btn::before,
.worldwide-one__location__btn::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 38px;
  height: 38px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: rgba(var(--cleenhearts-purple-rgb, 150, 89, 149), 0.25);
  box-shadow: 0 0 0 0 rgba(var(--cleenhearts-purple-rgb, 150, 89, 149), 0.25);
  z-index: -1;
  border-radius: 50%;
  transition: transform 500ms ease;
  -webkit-animation: productVideo 3s infinite;
  animation: productVideo 3s infinite;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.worldwide-one__location__btn::before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
@keyframes productVideo {
  70% {
    box-shadow: 0 0 0 40px rgba(10, 165, 205, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(10, 165, 205, 0);
  }
}
.worldwide-one__location__btn:hover::before,
.worldwide-one__location__btn:hover::after {
  transform: translate(-50%, -50%) scale(1.6);
}
.worldwide-one__location__btn__box {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: var(--cleenhearts-purple, #965995);
}
.worldwide-one__location:hover .worldwide-one__location__btn::before,
.worldwide-one__location:hover .worldwide-one__location__btn::after {
  transform: translate(-50%, -50%) scale(1.6);
}
.worldwide-one__location__popup {
  min-width: 290px;
  position: absolute;
  padding: 15px;
  display: none;
  align-items: center;
  gap: 15px;
  background-color: var(--cleenhearts-secondary, #f6d469);
  border-radius: 10px;
  z-index: 11;
}
@media (max-width: 575px) {
  .worldwide-one__location__popup {
    min-width: 250px;
  }
}
@media (max-width: 468px) {
  .worldwide-one__location__popup {
    min-width: 170px;
    flex-direction: column;
    align-items: flex-start;
  }
}
.worldwide-one__location:hover .worldwide-one__location__popup {
  display: flex;
}
.worldwide-one__location__image {
  width: 76px;
  flex-shrink: 0;
}
@media (max-width: 575px) {
  .worldwide-one__location__image {
    width: 70px;
  }
}
.worldwide-one__location__image img {
  border-radius: 10px;
  display: block;
  width: 100%;
}
.worldwide-one__location__text {
  font-size: 16px;
  color: var(--cleenhearts-base, #351c42);
  font-weight: 500;
  line-height: 1.437;
  letter-spacing: -0.48px;
  text-transform: capitalize;
  margin-bottom: 14px;
}
@media (max-width: 575px) {
  .worldwide-one__location__text {
    font-size: 14px;
  }
}
@media (max-width: 468px) {
  .worldwide-one__location__text {
    margin-bottom: 10px;
  }
}
.worldwide-one__location__map {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-base, #351c42);
  font-weight: 500;
  line-height: 1.285;
}
.worldwide-one__location__map:hover {
  color: var(--cleenhearts-purple, #965995);
}
.worldwide-one__location__map span,
.worldwide-one__location__map i {
  font-size: inherit;
  color: inherit;
  transition: all 0.4s ease-in-out;
}
.worldwide-one__location__map span {
  margin-right: 12px;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.worldwide-one__location__map span:hover {
  background-size: 100% 1px;
}
.worldwide-one__hand {
  position: absolute;
  left: 18px;
  bottom: -27px;
  animation: slowHandMove 6s ease infinite;
}
@keyframes slowHandMove {
  0%,
  100% {
    -webkit-transform: rotate(0deg) translateX(0);
    transform: rotate(0deg) translateX(0);
  }
  25%,
  75% {
    -webkit-transform: rotate(3deg) translateX(6px);
    transform: rotate(3deg) translateX(6px);
  }
  50% {
    -webkit-transform: rotate(6deg) translateX(12px);
    transform: rotate(6deg) translateX(12px);
  }
}
.worldwide-one--home {
  background-color: var(--cleenhearts-purple, #965995);
}
.worldwide-one--home .worldwide-one__bg::after {
  background: linear-gradient(
    99deg,
    #965995 27.6%,
    rgba(150, 89, 149, 0.5) 99.42%
  );
}
.worldwide-one--home .sec-title__tagline {
  color: var(--cleenhearts-white, #fff);
}
.worldwide-one--home .sec-title__tagline::before {
  background-color: var(--cleenhearts-white, #fff);
}
.worldwide-one--home .sec-title__tagline::after {
  border-color: var(--cleenhearts-white, #fff);
}
.worldwide-one--home .sec-title__title {
  color: var(--cleenhearts-white, #fff);
}
.worldwide-one--home .worldwide-one__text {
  font-weight: 700;
  color: var(--cleenhearts-white, #fff);
}
.worldwide-one--home .worldwide-one__inner__icon {
  background-color: transparent;
  border: 1px solid var(--cleenhearts-white2, #efece7);
}
.worldwide-one--home .worldwide-one__inner__icon span {
  color: var(--cleenhearts-white, #fff);
}
.worldwide-one--home .worldwide-one__inner:hover .worldwide-one__inner__icon {
  border-color: transparent;
}
.worldwide-one--home .worldwide-one__inner__title {
  color: var(--cleenhearts-white, #fff);
}
.worldwide-one--home .worldwide-one__inner__text {
  color: var(--cleenhearts-white, #fff);
}
.worldwide-one--home .cleenhearts-btn {
  border: 0;
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.worldwide-one--home .cleenhearts-btn__icon-box {
  background-color: var(--cleenhearts-base, #351c42);
}
.worldwide-one--home .cleenhearts-btn__icon-box__inner span {
  color: var(--cleenhearts-white, #fff);
}
.worldwide-one--home .cleenhearts-btn:hover .cleenhearts-btn__text {
  color: var(--cleenhearts-white, #fff);
}
.worldwide-one--home .worldwide-one__location__btn::before,
.worldwide-one--home .worldwide-one__location__btn::after {
  background-color: rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0.25);
  box-shadow: 0 0 0 0 rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0.25);
}
.worldwide-one--home .worldwide-one__location__btn__box {
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.worldwide-one--home .worldwide-one__location__popup {
  background-color: var(--cleenhearts-white, #fff);
}
.worldwide-one--home .worldwide-one__location__map {
  color: var(--cleenhearts-purple, #965995);
}
.worldwide-one--home .worldwide-one__location__map:hover {
  color: var(--cleenhearts-base, #351c42);
}

/*--------------------------------------------------------------
# Volunteer
--------------------------------------------------------------*/
.volunteer-card {
  position: relative;
}
.volunteer-card__image {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  z-index: 1;
}
.volunteer-card__image::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0) 0%,
    rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0.2) 100%
  );
  background: linear-gradient(
    to right,
    rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0) 0%,
    rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0.2) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
.volunteer-card__image img {
  display: block;
  width: 100%;
  border-radius: 20px;
}
.volunteer-card:hover .volunteer-card__image::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}
.volunteer-card__social {
  position: relative;
  position: absolute;
  top: 30px;
  right: 30px;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: var(--cleenhearts-white2, #efece7);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 11;
  transition: all 400ms ease;
}
.volunteer-card__social::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform: scale(0);
  opacity: 0;
  transition: transform 400ms ease, opacity 400ms ease;
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.volunteer-card__social span {
  font-size: 24px;
  color: var(--cleenhearts-purple, #965995);
  transition: all 0.4s ease-in-out;
}
@media (max-width: 1199px) {
  .volunteer-card__social {
    top: 25px;
    right: 25px;
    width: 42px;
    height: 42px;
  }
  .volunteer-card__social span {
    font-size: 18px;
  }
}
.volunteer-card__social:hover:after {
  transform: scale(1);
  opacity: 1;
}
.volunteer-card__social:hover span {
  color: var(--cleenhearts-base, #351c42);
}
.volunteer-card__social__list {
  position: relative;
  position: absolute;
  top: 50%;
  right: calc(100% + 10px);
  transform: translateY(-50%) scale(0, 1);
  background-color: var(--cleenhearts-secondary, #f6d469);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border-radius: 10px;
  min-height: 52px;
  padding-left: 20px;
  padding-right: 20px;
  opacity: 0;
  transition: 500ms ease;
  transform-origin: top right;
}
.volunteer-card__social__list::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 0 5px 10px;
  border-color: transparent transparent transparent
    var(--cleenhearts-secondary, #f6d469);
}
@media (max-width: 1199px) {
  .volunteer-card__social__list {
    min-height: 42px;
  }
}
.volunteer-card__social__list a {
  color: var(--cleenhearts-base, #351c42);
  font-size: 16px;
  transition: all 500ms ease;
}
@media (max-width: 1199px) {
  .volunteer-card__social__list a {
    font-size: 14px;
  }
}
.volunteer-card__social__list a + a {
  margin-left: 25px;
}
.volunteer-card__social__list a:hover {
  color: var(--cleenhearts-purple, #965995);
}
.volunteer-card__social:hover .volunteer-card__social__list {
  opacity: 1;
  transform: translateY(-50%) scale(1, 1);
}
.volunteer-card__content {
  position: relative;
  margin-top: -50px;
  width: calc(100% - 84px);
  margin-left: auto;
  margin-right: auto;
  padding: 21px 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: var(--cleenhearts-white2, #efece7);
  border-radius: 10px;
  text-align: center;
  overflow: hidden;
  z-index: 11;
}
@media (max-width: 1399px) {
  .volunteer-card__content {
    width: calc(100% - 60px);
  }
}
@media (max-width: 1199px) {
  .volunteer-card__content {
    width: calc(100% - 50px);
  }
}
.volunteer-card__content::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: var(--cleenhearts-secondary, #f6d469);
  z-index: -1;
  position: absolute;
  top: 100%;
  left: 100%;
  border-radius: 10px;
  transition: all 400ms ease;
}
.volunteer-card:hover .volunteer-card__content::after {
  top: 0;
  left: 0;
}
.volunteer-card__name {
  font-size: 24px;
  line-height: 1.458;
  text-transform: capitalize;
  margin-bottom: 9px;
}
@media (max-width: 1199px) {
  .volunteer-card__name {
    font-size: 22px;
  }
}
@media (max-width: 425px) {
  .volunteer-card__name {
    font-size: 21px;
  }
}
.volunteer-card__name a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.volunteer-card__name a:hover {
  background-size: 100% 1px;
}
.volunteer-card__designation {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  color: var(--cleenhearts-purple, #965995);
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  transition: all 0.4s ease-in-out;
  margin-bottom: 0;
}
@media (max-width: 425px) {
  .volunteer-card__designation {
    font-size: 15px;
  }
}
.volunteer-card:hover .volunteer-card__designation {
  color: var(--cleenhearts-base, #351c42);
}

/*--------------------------------------------------------------
# Volunteer Details
--------------------------------------------------------------*/
.volunteer-details__image {
  position: relative;
  overflow: hidden;
}
.volunteer-details__image img {
  display: block;
  width: 100%;
  border-radius: 20px;
}
.volunteer-details__social {
  padding: 52px 0 23px;
  width: 100%;
  max-width: 362px;
  position: relative;
  position: absolute;
  left: 92px;
  bottom: 0;
  z-index: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1199px) {
  .volunteer-details__social {
    left: 0;
    right: 0;
    margin: auto;
  }
}
@media (max-width: 425px) {
  .volunteer-details__social {
    padding: 37px 0 38px;
    bottom: -10px;
  }
}
.volunteer-details__info {
  margin-top: 1px;
}
.volunteer-details__info__top {
  padding-bottom: 31px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid var(--cleenhearts-white2, #efece7);
}
.volunteer-details__name {
  font-size: 24px;
  line-height: 1.458;
  text-transform: capitalize;
  margin-bottom: 10px;
}
.volunteer-details__designation {
  color: var(--cleenhearts-purple, #965995);
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0;
}
.volunteer-details__video {
  top: 2px;
}
.volunteer-details__text {
  margin-top: 31px;
  margin-bottom: 30px;
  text-transform: capitalize;
}
.volunteer-details__progress .progress-box + .progress-box {
  margin-top: 25px;
}
.volunteer-details__profile {
  display: flex;
  align-items: center;
  gap: 21px;
  border-right: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  padding-right: 34px;
}
@media (max-width: 575px) {
  .volunteer-details__profile {
    padding-right: 0;
    border: 0;
  }
}
.volunteer-details__profile__image {
  width: 82px;
  height: 82px;
  border-radius: 50%;
}
.volunteer-details__profile__name {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  color: var(--cleenhearts-base, #351c42);
  font-weight: 500;
  line-height: 1.75;
  margin-bottom: 5px;
  text-transform: capitalize;
}
.volunteer-details__profile__designation {
  font-size: 14px;
  color: var(--cleenhearts-purple, #965995);
  font-weight: 500;
  line-height: 1.142;
  margin-bottom: 5px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.volunteer-details__profile__signature {
  margin-left: 34px;
}
@media (max-width: 575px) {
  .volunteer-details__profile__signature {
    margin-left: 0;
  }
}
.volunteer-details__background {
  padding-top: 66px;
  padding-bottom: 64px;
  background-color: var(--cleenhearts-white2, #efece7);
}
.volunteer-details__background__heading {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.458;
  text-transform: capitalize;
  margin-bottom: 0;
  margin-bottom: 29px;
}
.volunteer-details__background__content {
  padding-top: 41px;
  padding-bottom: 28px;
  border-top: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
.volunteer-details__background__content:last-child {
  padding-bottom: 0;
}
.volunteer-details__background__title {
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.volunteer-details__background__year {
  position: relative;
  top: -3px;
  padding: 10px;
  background-color: var(--cleenhearts-white, #fff);
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  font-size: 16px;
  color: var(--cleenhearts-purple, #965995);
  font-weight: 700;
  line-height: 1;
  text-transform: capitalize;
  border-radius: 100px;
  margin-left: 10px;
  transition: all 400ms ease;
}
.volunteer-details__background__content:hover
  .volunteer-details__background__year {
  color: var(--cleenhearts-base, #351c42);
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.volunteer-details__background__text {
  text-transform: capitalize;
  margin-bottom: 0;
}
.volunteer-details__background__text:not(:last-child) {
  margin-bottom: 30px;
}
.volunteer-details__contact .contact-one__form {
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.volunteer-details__contact .contact-one__form__bg {
  mix-blend-mode: multiply;
}

/*--------------------------------------------------------------
# Become Volunteer
--------------------------------------------------------------*/
.become-volunteer {
  background-color: var(--cleenhearts-white2, #efece7);
}
.become-volunteer__image__inner {
  max-width: 472px;
  margin-left: auto;
  margin-right: 33px;
  position: relative;
}
@media (max-width: 1199px) {
  .become-volunteer__image__inner {
    max-width: 388px;
    margin-right: 30px;
  }
}
@media (max-width: 991px) {
  .become-volunteer__image__inner {
    max-width: 472px;
    margin-left: 28px;
    margin-right: 0;
  }
}
.become-volunteer__image__inner::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: -28px;
  z-index: 1;
  border-radius: 236px;
  border: 5px solid var(--cleenhearts-purple, #965995);
  transition: all 400ms ease;
}
.become-volunteer__image__inner:hover::after {
  left: 0;
}
.become-volunteer__image__inner img {
  display: block;
  width: 100%;
  border-radius: 236px;
}
.become-volunteer__form {
  margin-left: 1px;
  position: relative;
  padding: 61px 60px 60px;
  background-color: var(--cleenhearts-secondary, #f6d469);
  border-radius: 20px;
}
@media (max-width: 1199px) {
  .become-volunteer__form {
    padding: 36px 35px 35px;
  }
}
@media (max-width: 500px) {
  .become-volunteer__form {
    padding: 26px 25px 25px;
  }
}
.become-volunteer__form__bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  mix-blend-mode: multiply;
}
.become-volunteer__form__title {
  font-size: 30px;
  line-height: 35px;
  text-transform: capitalize;
  margin-bottom: 39px;
  position: relative;
  z-index: 11;
}
@media (max-width: 575px) {
  .become-volunteer__form__title {
    font-size: 27px;
  }
}
.become-volunteer__form__inner {
  position: relative;
  z-index: 11;
}
.become-volunteer__form .form-one__control:not(:last-of-type) {
  margin-bottom: 20px;
}
.become-volunteer__form .form-one__control__input {
  border-radius: 100px;
}
.become-volunteer__form .form-one__control__message {
  border-radius: 30px;
  height: 142px;
}
.become-volunteer__form__bottom {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}
.become-volunteer__form .cleenhearts-btn {
  margin-top: 7px;
}

/*--------------------------------------------------------------
# Events
--------------------------------------------------------------*/
.events-one .sec-title {
  width: 100%;
  max-width: 507px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.events-one .horizontal-accordion {
  display: flex;
  width: 100%;
  padding: 0;
  gap: 30px;
}
@media (max-width: 991px) {
  .events-one .horizontal-accordion {
    display: block;
  }
}
.events-one .choice {
  height: 356px;
  width: 170px;
  min-width: 170px;
  box-sizing: border-box;
  padding: 0;
  overflow: hidden;
  float: left;
  align-items: center;
  transition: width 0.2s;
  border-radius: 20px;
  border: none;
  margin: 0;
  padding: 0;
}
@media (max-width: 1199px) {
  .events-one .choice {
    width: 110px;
    min-width: 110px;
  }
}
@media (max-width: 991px) {
  .events-one .choice {
    width: 100%;
    min-width: 100%;
    height: 110px;
    margin: 30px 0 0;
    float: none;
  }
}
.events-one .card-body {
  margin: 0;
  padding: 0;
}
.events-one .expand {
  width: 100%;
}
@media (max-width: 991px) {
  .events-one .expand {
    height: 100%;
  }
}
@media (max-width: 767px) {
  .events-one .expand {
    border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
    border-top: none;
    border-bottom: none;
  }
}
.events-one .choice .event-card-two {
  opacity: 0;
  height: 100%;
  overflow: hidden;
  width: 0;
}
@media (max-width: 1199px) {
  .events-one .choice .event-card-two {
    padding: 24px;
  }
}
.events-one .choice.expand .event-card-two {
  opacity: 1;
  z-index: 9;
  width: 100%;
}
.events-one__card {
  position: relative;
}
.events-one__card__top {
  position: absolute;
  left: 0;
  top: 0;
  padding: 96px 50px 30px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  transition: all 400ms ease;
  height: 100%;
  width: 100%;
  z-index: 1;
}
@media (max-width: 1199px) {
  .events-one__card__top {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 991px) {
  .events-one__card__top {
    padding: 38px 30px;
  }
}
@media (max-width: 767px) {
  .events-one__card__top {
    padding: 22px 65px 22px 25px;
  }
}
.events-one__card__top::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  z-index: 1;
}
.events-one__card__title {
  position: relative;
  font-size: 24px;
  text-transform: capitalize;
  color: var(--cleenhearts-white2, #efece7);
  line-height: 33px;
  margin: 0;
  transition: all 500ms ease;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  z-index: 11;
}
@media (max-width: 991px) {
  .events-one__card__title {
    transform: none;
    writing-mode: horizontal-tb;
  }
}
.events-one__card__icon {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--cleenhearts-secondary, #f6d469);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: var(--cleenhearts-base, #351c42);
  border-radius: 50%;
  z-index: 11;
}
@media (max-width: 991px) {
  .events-one__card__icon {
    left: auto;
    top: 35px;
    right: 10px;
  }
}
@media (max-width: 767px) {
  .events-one__card__icon {
    right: 0;
  }
}

.events-two {
  padding-bottom: 55px;
  position: relative;
  background-color: var(--cleenhearts-white, #fff);
}
.events-two .sec-title {
  width: 100%;
  max-width: 670px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.events-two .accordion {
  position: relative;
  background-color: transparent;
}
.events-two .accordion + .accordion {
  margin-top: 20px;
}
.events-two .accordion-title {
  position: relative;
  padding: 30px 143px 25px 30px;
  cursor: pointer;
  background-color: var(--cleenhearts-white2, #efece7);
  border-radius: 20px;
  z-index: 1;
  margin-bottom: 10px;
  transition: all 400ms ease;
}
@media (max-width: 1199px) {
  .events-two .accordion-title {
    padding-right: 108px;
  }
}
@media (max-width: 767px) {
  .events-two .accordion-title {
    padding: 25px 103px 25px 25px;
  }
}
@media (max-width: 575px) {
  .events-two .accordion-title {
    padding-right: 75px;
  }
}
.events-two .accordion-title__bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  mix-blend-mode: multiply;
  z-index: 1;
}
.events-two .accordion-title__icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  background-color: var(--cleenhearts-white, #fff);
  z-index: 11;
  transition: all 400ms ease;
}
@media (max-width: 767px) {
  .events-two .accordion-title__icon {
    right: 25px;
  }
}
@media (max-width: 575px) {
  .events-two .accordion-title__icon {
    width: 35px;
    height: 35px;
  }
}
.events-two .accordion-title__icon::after,
.events-two .accordion-title__icon::before {
  width: 2px;
  height: 14px;
  position: absolute;
  background-color: var(--cleenhearts-purple, #965995);
  top: 50%;
  left: 50%;
  content: "";
  transform: translate(-50%, -50%);
  transition: all 500ms ease;
  border-radius: 50px;
}
.events-two .accordion-title__icon::after {
  width: 14px;
  height: 2px;
  border-radius: 50px;
}
.events-two .accordion:hover .accordion-title__icon {
  background-color: var(--cleenhearts-purple, #965995);
}
.events-two .accordion:hover .accordion-title__icon::after,
.events-two .accordion:hover .accordion-title__icon::before {
  background-color: var(--cleenhearts-white, #fff);
}
.events-two .active .accordion-title {
  border-radius: 20px;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}
.events-two .active .accordion-title__icon {
  background-color: var(--cleenhearts-white2, #efece7);
}
.events-two .active .accordion-title__icon::after,
.events-two .active .accordion-title__icon::before {
  background-color: var(--cleenhearts-purple, #965995);
  opacity: 0;
}
.events-two .active .accordion-title__icon::after {
  opacity: 1;
}
.events-two .accordion-content {
  position: relative;
  z-index: 1;
  border-radius: 0;
}
.events-two .accordion-content .inner {
  position: relative;
  margin-top: 0;
  padding: 0;
  background-color: transparent;
  border-radius: 0;
}
.events-two .accordion-content .inner .accordion-title__icon {
  top: 30px;
  right: 30px;
  transform: translate(0);
}
@media (max-width: 575px) {
  .events-two .accordion-content .inner .accordion-title__icon {
    right: 25px;
  }
}
.events-two .accordion-content p {
  margin: 0;
  text-transform: capitalize;
}
.events-two__content {
  position: relative;
  z-index: 11;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
@media (max-width: 1199px) {
  .events-two__content {
    flex-direction: column;
  }
}
.events-two__time {
  padding: 8px 15px 8px 8px;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  color: var(--cleenhearts-purple, #965995);
  background-color: var(--cleenhearts-white, #fff);
  transition: all 0.4s ease-in-out;
  margin-bottom: 21px;
}
.events-two__time__icon {
  font-size: 24px;
  color: inherit;
  margin-right: 11px;
  transition: all ease 400ms;
}
@media (max-width: 575px) {
  .events-two__time__icon {
    font-size: 20px;
    margin-right: 8px;
  }
}
.events-two__time__text {
  position: relative;
  top: 1px;
  font-size: 16px;
  color: var(--cleenhearts-text-gray2, #888888);
  line-height: 1;
  font-weight: 500;
  text-transform: capitalize;
  transition: all ease 400ms;
}
@media (max-width: 575px) {
  .events-two__time__text {
    top: 0px;
    font-size: 14px;
  }
}
.events-two__content:hover .events-two__time {
  border-color: var(--cleenhearts-purple, #965995);
  background-color: var(--cleenhearts-purple, #965995);
}
.events-two__content:hover .events-two__time__icon,
.events-two__content:hover .events-two__time__text {
  color: var(--cleenhearts-white, #fff);
}
.events-two__title {
  font-size: 22px;
  line-height: 1.5;
  text-transform: capitalize;
  margin-bottom: 0;
}
@media (max-width: 500px) {
  .events-two__title {
    font-size: 20px;
  }
}
@media (max-width: 375px) {
  .events-two__title {
    font-size: 18px;
  }
}
.events-two__meta {
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 9px 30px 12px 33px;
  border-radius: 100px;
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
@media (max-width: 575px) {
  .events-two__meta {
    padding: 15px;
    border-radius: 20px;
  }
}
.events-two__meta li h5 {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  color: var(--cleenhearts-purple, #965995);
  line-height: 1.75;
  margin-bottom: 0px;
  text-transform: capitalize;
}
.events-two__meta li h5 span {
  position: relative;
  top: 2px;
  margin-right: 6px;
  font-size: 16px;
  color: var(--cleenhearts-purple, #965995);
}
.events-two__meta li address {
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.75;
  margin: 0;
  text-transform: capitalize;
}
@media (max-width: 375px) {
  .events-two__meta li address {
    font-size: 14px;
  }
}

.events-three {
  position: relative;
  padding: 0 0 65px;
}
.events-three__bg {
  background-color: var(--cleenhearts-white2, #efece7);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
  padding: 67px 40px 40px;
  position: relative;
  overflow: hidden;
}
@media (max-width: 767px) {
  .events-three__bg {
    padding: 50px 20px 20px;
  }
}
.events-three .sec-title {
  width: 100%;
  max-width: 507px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  z-index: 2;
  position: relative;
}
.events-three .horizontal-accordion {
  display: flex;
  width: 100%;
  padding: 0;
  gap: 30px;
}
@media (max-width: 991px) {
  .events-three .horizontal-accordion {
    display: block;
  }
}
.events-three .choice {
  height: 356px;
  width: 170px;
  min-width: 170px;
  box-sizing: border-box;
  padding: 0;
  overflow: hidden;
  float: left;
  align-items: center;
  transition: width 0.2s;
  border-radius: 20px;
  border: none;
  margin: 0;
  padding: 0;
}
@media (max-width: 1199px) {
  .events-three .choice {
    width: 110px;
    min-width: 110px;
  }
}
@media (max-width: 991px) {
  .events-three .choice {
    width: 100%;
    min-width: 100%;
    height: 110px;
    margin: 30px 0 0;
    float: none;
  }
}
.events-three .card-body {
  margin: 0;
  padding: 0;
}
.events-three .expand {
  width: 100%;
}
@media (max-width: 991px) {
  .events-three .expand {
    height: 100%;
  }
}
.events-three .choice .event-card-three {
  opacity: 0;
  height: 100%;
  overflow: hidden;
  width: 0;
}
.events-three .choice.expand .event-card-three {
  opacity: 1;
  z-index: 9;
  width: 100%;
}
.events-three__card {
  position: relative;
}
.events-three__card__top {
  position: absolute;
  left: 0;
  top: 0;
  padding: 96px 50px 30px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  transition: all 400ms ease;
  height: 100%;
  width: 100%;
  z-index: 1;
}
@media (max-width: 1199px) {
  .events-three__card__top {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 991px) {
  .events-three__card__top {
    padding: 38px 30px;
  }
}
@media (max-width: 767px) {
  .events-three__card__top {
    padding: 22px 65px 22px 25px;
  }
}
.events-three__card__top::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  z-index: 1;
}
.events-three__card__title {
  position: relative;
  font-size: 24px;
  text-transform: capitalize;
  color: var(--cleenhearts-white2, #efece7);
  line-height: 33px;
  margin: 0;
  transition: all 500ms ease;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  z-index: 11;
}
@media (max-width: 991px) {
  .events-three__card__title {
    transform: none;
    writing-mode: horizontal-tb;
  }
}
@media (max-width: 767px) {
  .events-three__card__title {
    font-size: 22px;
    line-height: 30px;
  }
}
.events-three__card__icon {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--cleenhearts-secondary, #f6d469);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  color: var(--cleenhearts-base, #351c42);
  border-radius: 50%;
  z-index: 11;
}
@media (max-width: 991px) {
  .events-three__card__icon {
    left: auto;
    top: 35px;
    right: 10px;
  }
}
@media (max-width: 767px) {
  .events-three__card__icon {
    right: 0;
  }
}

/*--------------------------------------------------------------
# Event Details
--------------------------------------------------------------*/
.event-details__image {
  position: relative;
  margin-bottom: 30px;
}
.event-details__image img {
  display: block;
  width: 100%;
  border-radius: 20px;
}
.event-details__hall {
  padding: 10px;
  border-radius: 100px;
  position: absolute;
  top: 30px;
  left: 30px;
  background-color: var(--cleenhearts-secondary, #f6d469);
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  font-weight: 700;
  color: var(--cleenhearts-base, #351c42);
  text-transform: capitalize;
  line-height: 1;
  z-index: 11;
}
@media (max-width: 575px) {
  .event-details__hall {
    top: 20px;
    left: 20px;
  }
}
@media (max-width: 425px) {
  .event-details__hall {
    top: 15px;
    left: 15px;
  }
}
.event-details__date {
  position: absolute;
  top: 30px;
  right: 30px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  font-size: 16px;
  color: var(--cleenhearts-base, #351c42);
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  background-color: var(--cleenhearts-white, #fff);
  gap: 4px;
  z-index: 11;
}
@media (max-width: 425px) {
  .event-details__date {
    width: 54px;
    height: 54px;
    top: 15px;
    right: 15px;
  }
}
.event-details__date span {
  color: inherit;
}
.event-details__time {
  padding: 8px 10px 8px 8px;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  position: relative;
  background-color: transparent;
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  margin-bottom: 31px;
  transition: all 0.4s ease-in-out;
}
.event-details__time:hover {
  background-color: var(--cleenhearts-purple, #965995);
}
.event-details__time__icon {
  font-size: 24px;
  color: var(--cleenhearts-purple, #965995);
  margin-right: 11px;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 575px) {
  .event-details__time__icon {
    font-size: 20px;
  }
}
.event-details__time__text {
  position: relative;
  top: 1px;
  font-size: 16px;
  color: var(--cleenhearts-text, #757277);
  line-height: 1;
  font-weight: 500;
  text-transform: capitalize;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 575px) {
  .event-details__time__text {
    font-size: 14px;
  }
}
.event-details__time:hover .event-details__time__icon,
.event-details__time:hover .event-details__time__text {
  color: var(--cleenhearts-white, #fff);
}
.event-details__title {
  font-size: 24px;
  line-height: 1.541;
  margin-bottom: 28px;
}
.event-details__text__inner {
  text-transform: capitalize;
  margin-bottom: 0;
}
.event-details__text__inner + .event-details__text__inner {
  margin-top: 28.5px;
}
.event-details__inner__image {
  margin-top: 39px;
}
.event-details__inner__image img {
  width: 100%;
  display: block;
  border-radius: 20px;
}
.event-details__inner__text {
  text-transform: capitalize;
  margin-top: 40px;
  margin-bottom: 26px;
}
.event-details__inner__list {
  padding: 0;
  margin: 0;
  margin-left: 18px;
}
.event-details__inner__list li + li {
  margin-top: 8.5px;
}
.event-details__inner__list li p {
  text-transform: capitalize;
  margin-bottom: 0;
}
.event-details__contact {
  margin: 56px 0 66px;
  padding: 30px 0;
  border-top: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  border-bottom: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
.event-details__contact .contact-information__btn {
  margin-right: 30px;
}
.event-details__contact .contact-information__btn::after {
  right: -30px;
}
@media (max-width: 575px) {
  .event-details__contact .contact-information__btn {
    margin-right: 0;
  }
  .event-details__contact .contact-information__btn::after {
    display: none;
  }
}
.event-details__contact .contact-information__phone {
  padding-left: 30px;
}
@media (max-width: 575px) {
  .event-details__contact .contact-information__phone {
    padding-left: 0;
  }
}
.event-details__speaker__title {
  margin-bottom: 29px;
}
.event-details__speaker__info + .event-details__speaker__info {
  margin-top: 30px;
}
.event-details__speaker__info {
  padding: 29px;
  display: grid;
  grid-template-columns: 168px auto;
  grid-gap: 25px;
  border-radius: 20px;
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
@media (max-width: 1199px) {
  .event-details__speaker__info {
    grid-template-columns: 100px auto;
  }
}
@media (max-width: 767px) {
  .event-details__speaker__info {
    grid-template-columns: auto;
  }
}
.event-details__speaker__image {
  width: 168px;
}
@media (max-width: 1199px) {
  .event-details__speaker__image {
    width: 100px;
  }
}
.event-details__speaker__image img {
  width: 168px;
  height: 168px;
  border-radius: 50%;
}
@media (max-width: 1199px) {
  .event-details__speaker__image img {
    width: 100px;
    height: 100px;
  }
}
.event-details__speaker__content {
  margin-top: 2px;
}
.event-details__speaker__content__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px 20px;
  padding-bottom: 16px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
@media (max-width: 575px) {
  .event-details__speaker__content__inner {
    flex-direction: column;
    align-items: flex-start;
  }
}
.event-details__speaker__name {
  font-size: 20px;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 11px;
}
@media (max-width: 1199px) {
  .event-details__speaker__name {
    font-size: 18px;
  }
}
.event-details__speaker__designation {
  color: var(--cleenhearts-purple, #965995);
  text-transform: capitalize;
  line-height: 1;
  margin-bottom: 0;
}
@media (max-width: 1199px) {
  .event-details__speaker__designation {
    font-size: 15px;
  }
}
.event-details__speaker__text p {
  text-transform: capitalize;
  margin-bottom: 0;
}

.sidebar-event__item + .sidebar-event__item {
  margin-top: 30px;
}
.sidebar-event__contact {
  padding: 24px;
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  border-radius: 20px;
}
.sidebar-event__contact__image {
  margin-top: 27px;
}
.sidebar-event__contact__image img {
  display: block;
  width: 100%;
  border-radius: 20px;
}
.sidebar-event .contact-one__info {
  padding: 0;
  border-radius: 0;
  position: static;
  transform: translate(0);
  width: 100%;
}
.sidebar-event__map iframe {
  border-radius: 20px;
  height: 400px;
}

/*--------------------------------------------------------------
# Donars
--------------------------------------------------------------*/
.donars-one {
  position: relative;
  background-color: var(--cleenhearts-secondary, #f6d469);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  mix-blend-mode: multiply;
}
.donars-one .container-fluid {
  position: relative;
  z-index: 11;
  padding-left: 0;
  padding-right: 0;
}
.donars-one .sec-title {
  text-align: center;
  width: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 62px;
}
.donars-one .sec-title__tagline {
  color: var(--cleenhearts-base, #351c42);
  margin-bottom: 23px;
}
.donars-one .sec-title__tagline::before {
  background-color: var(--cleenhearts-base, #351c42);
}
.donars-one .sec-title__tagline::after {
  border-color: var(--cleenhearts-base, #351c42);
}
.donars-one .sec-title__title__inner::after {
  opacity: 0;
}
.donars-one--home {
  mix-blend-mode: normal;
  background-color: var(--cleenhearts-base, #351c42);
}

.donars-one--home .sec-title__tagline {
  color: var(--cleenhearts-white, #fff);
  margin-bottom: 23px;
}
.donars-one--home .sec-title__tagline::before {
  background-color: var(--cleenhearts-white, #fff);
}
.donars-one--home .sec-title__tagline::after {
  border-color: var(--cleenhearts-white, #fff);
}
.donars-one--home .sec-title__title {
  color: var(--cleenhearts-white, #fff);
}
.donars-one--home .sec-title__title__inner::after {
  opacity: 1;
}
.donars-one__row {
  display: flex;
  align-items: center;
  gap: 30px;
  will-change: transform;
  position: relative;
  white-space: nowrap;
}
@media (max-width: 425px) {
  .donars-one__row {
    gap: 20px;
  }
}
.donars-one__row--top {
  animation: imageScrolling 30s linear infinite;
  animation-direction: reverse;
  margin-bottom: 30px;
}
.donars-one__row--bottom {
  animation: imageScrolling 30s linear infinite;
  animation-direction: normal;
}
.donars-one__row:hover {
  animation-play-state: paused;
}
.donars-one__country {
  padding: 13px;
  background-color: var(--cleenhearts-base, #fff);
  border-radius: 100px;
  min-width: 250px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  font-weight: bold;
  align-items: center;
  gap: 15px;
  /*  font-family: 'Gentium Book Plus', serif;*/
}
.donars-one__country__text {
  font-size: 18px;
  color: var(--cleenhearts-white, #351c42);
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
}

@keyframes imageScrolling {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(0%, 0, 0);
  }
}

/*--------------------------------------------------------------
# Donations
--------------------------------------------------------------*/
.donations-one {
  margin-top: -315px;
  position: relative;
  z-index: 11;
}
.donations-one__carousel {
  position: relative;
}
.donations-one__carousel .owl-nav::before,
.donations-one__carousel .owl-nav::after {
  display: none;
}
@media (min-width: 1330px) {
  .donations-one__carousel .owl-nav {
    position: absolute;
    top: 140px;
    width: 100%;
    margin-top: 0 !important;
    justify-content: space-between;
  }
  .donations-one__carousel .owl-nav .owl-prev,
  .donations-one__carousel .owl-nav .owl-next {
    position: relative;
  }
  .donations-one__carousel .owl-nav .owl-prev span,
  .donations-one__carousel .owl-nav .owl-next span {
    width: 60px;
    height: 60px;
    font-size: 24px;
    background-color: transparent;
    color: var(--cleenhearts-white, #fff);
    border: 1px solid var(--cleenhearts-border-color2, #414141);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
  }
  .donations-one__carousel .owl-nav .owl-prev span:hover,
  .donations-one__carousel .owl-nav .owl-next span:hover {
    background-color: var(--cleenhearts-white, #fff);
    color: var(--cleenhearts-base, #351c42);
  }
}
@media (min-width: 1330px) {
  .donations-one__carousel .owl-nav .owl-prev {
    left: -70.5px;
  }
}
@media (min-width: 1400px) {
  .donations-one__carousel .owl-nav .owl-prev {
    left: -105px;
  }
}
@media (min-width: 1330px) {
  .donations-one__carousel .owl-nav .owl-next {
    right: -70.5px;
  }
}
@media (min-width: 1400px) {
  .donations-one__carousel .owl-nav .owl-next {
    right: -105px;
  }
}
.donations-one__row {
  margin-top: 35px;
}

.donations-two {
  position: relative;
  margin-top: 0;
  background-color: var(--cleenhearts-white2, #efece7);
  overflow: hidden;
}
.donations-two__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 1;
  mix-blend-mode: multiply;
}
.donations-two .container,
.donations-two .container-fluid {
  position: relative;
  z-index: 11;
}
@media (min-width: 530px) {
  .donations-two .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}
.donations-two .sec-title {
  text-align: center;
  width: 100%;
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
}
.donations-two__carousel {
  position: relative;
}
.donations-two__carousel .owl-nav::before,
.donations-two__carousel .owl-nav::after {
  display: none;
}
@media (min-width: 768px) {
  .donations-two__carousel .owl-nav {
    position: absolute;
    top: -150px;
    width: 100%;
    margin-top: 0 !important;
    justify-content: space-between;
  }
}
.donations-two__carousel .owl-nav .owl-prev,
.donations-two__carousel .owl-nav .owl-next {
  position: relative;
}
.donations-two__carousel .owl-nav .owl-prev span,
.donations-two__carousel .owl-nav .owl-next span {
  background-color: transparent;
  color: var(--cleenhearts-purple, #965995);
  border: 1px solid var(--cleenhearts-purple, #965995);
  width: 60px;
  height: 60px;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: inherit;
}
.donations-two__carousel .owl-nav .owl-prev span:hover,
.donations-two__carousel .owl-nav .owl-next span:hover {
  background-color: var(--cleenhearts-purple, #965995);
  color: var(--cleenhearts-white, #fff);
}
@media (min-width: 768px) {
  .donations-two__carousel .owl-nav .owl-prev {
    left: 5%;
  }
}
@media (min-width: 992px) {
  .donations-two__carousel .owl-nav .owl-prev {
    left: 10%;
  }
}
@media (min-width: 1200px) {
  .donations-two__carousel .owl-nav .owl-prev {
    left: 15%;
  }
}
@media (min-width: 768px) {
  .donations-two__carousel .owl-nav .owl-next {
    right: 5%;
  }
}
@media (min-width: 992px) {
  .donations-two__carousel .owl-nav .owl-next {
    right: 10%;
  }
}
@media (min-width: 1200px) {
  .donations-two__carousel .owl-nav .owl-next {
    right: 15%;
  }
}
.donations-two .donation-card {
  background-color: var(--cleenhearts-white, #fff);
}
.donations-two .donation-card__bg {
  display: none;
}
.donations-two .donation-card__progress {
  background-color: var(--cleenhearts-white2, #efece7);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.donations-two .donation-card__btn {
  border-color: var(--cleenhearts-border-color, #d7d3cb);
}
.donations-two .donation-card__btn:hover {
  border-color: transparent;
}
.donations-two .donation-card__title {
  font-size: 24px;
  line-height: 33px;
  letter-spacing: -0.72px;
}
@media (max-width: 991px) {
  .donations-two .donation-card__title {
    font-size: 20px;
    line-height: 30px;
  }
}
.donations-two__shape {
  position: relative;
  position: absolute;
  width: 234px;
  height: 234px;
  border-radius: 50%;
  background-color: var(--cleenhearts-secondary, #f6d469);
  z-index: 1;
  opacity: 0.5;
}
.donations-two__shape::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid var(--cleenhearts-secondary, #f6d469);
  z-index: 1;
}
.donations-two__shape--one {
  top: -117px;
  left: 50px;
  animation: zumpX 1500ms infinite alternate;
}
@media (max-width: 1799px) {
  .donations-two__shape--one {
    top: -140px;
  }
}
@media (max-width: 767px) {
  .donations-two__shape--one {
    top: -150px;
  }
}
@media (max-width: 575px) {
  .donations-two__shape--one {
    top: -175px;
    left: 20px;
  }
}
@media (max-width: 412px) {
  .donations-two__shape--one {
    left: 0px;
  }
}
.donations-two__shape--one::after {
  top: 0;
  left: 37px;
  animation: zumpX 1500ms infinite alternate;
}
.donations-two__shape--two {
  width: 646px;
  height: 646px;
  right: 50px;
  bottom: -323px;
  animation: zumpXtwo 1500ms infinite alternate;
}
.donations-two__shape--two::after {
  top: 0;
  right: 37px;
  animation: zumpXtwo 1500ms infinite alternate;
}

.donations-gift {
  padding-top: 65px;
}
.donations-gift .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.donations-gift__col {
  overflow: hidden;
  position: relative;
}
.donations-gift .donation-information {
  height: 100%;
}
@media (max-width: 1199px) {
  .donations-gift .donation-information {
    padding: 30px;
  }
}
@media (max-width: 991px) {
  .donations-gift .donation-information {
    padding: 40px;
  }
}
@media (max-width: 575px) {
  .donations-gift .donation-information {
    padding: 30px;
  }
}
.donations-gift .donation-information,
.donations-gift .donation-information__bg,
.donations-gift .donation-information__bg::after {
  border-radius: 0 20px 20px 0;
}
@media (max-width: 991px) {
  .donations-gift .donation-information,
  .donations-gift .donation-information__bg,
  .donations-gift .donation-information__bg::after {
    border-radius: 0;
  }
}
.donations-gift .donation-information__bg::after {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.84) 82.29%
  );
}
.donations-gift .donation-information__content {
  width: 100%;
  max-width: 505px;
  margin-left: auto;
}
@media (max-width: 1399px) {
  .donations-gift .donation-information__content {
    margin-left: 0;
  }
}
@media (max-width: 991px) {
  .donations-gift .donation-information__content {
    max-width: 700px;
  }
}
.donations-gift .gift-card {
  height: 100%;
}
@media (max-width: 1199px) {
  .donations-gift .gift-card {
    padding: 30px;
  }
}
@media (max-width: 991px) {
  .donations-gift .gift-card {
    padding: 40px;
  }
}
@media (max-width: 575px) {
  .donations-gift .gift-card {
    padding: 30px;
  }
}
.donations-gift .gift-card,
.donations-gift .gift-card__bg,
.donations-gift .gift-card__bg::after {
  border-radius: 20px 0 0 20px;
}
@media (max-width: 991px) {
  .donations-gift .gift-card,
  .donations-gift .gift-card__bg,
  .donations-gift .gift-card__bg::after {
    border-radius: 0;
  }
}
.donations-gift .gift-card__bg::after {
  background: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0) 100%);
}
.donations-gift .gift-card__content {
  width: 100%;
  max-width: 470px;
}
@media (max-width: 1199px) {
  .donations-gift .gift-card__content {
    max-width: 100%;
  }
}
@media (max-width: 991px) {
  .donations-gift .gift-card__content {
    max-width: 700px;
  }
}
.donations-gift__shape {
  position: relative;
  position: absolute;
  width: 234px;
  height: 234px;
  border-radius: 50%;
  background-color: var(--cleenhearts-secondary, #f6d469);
  opacity: 0.4;
  z-index: 1;
}
.donations-gift__shape::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid var(--cleenhearts-secondary, #f6d469);
  opacity: 0.4;
  z-index: 1;
}
.donations-gift__shape--one {
  left: 87px;
  bottom: -117px;
  animation: zumpX 1500ms infinite alternate;
}
@media (max-width: 1799px) {
  .donations-gift__shape--one {
    bottom: -140px;
  }
}
@media (max-width: 767px) {
  .donations-gift__shape--one {
    bottom: -150px;
  }
}
@media (max-width: 575px) {
  .donations-gift__shape--one {
    bottom: -175px;
    left: 20px;
  }
}
@media (max-width: 412px) {
  .donations-gift__shape--one {
    left: 0px;
  }
}
.donations-gift__shape--one::after {
  bottom: 0;
  left: -37px;
  animation: zumpX 1500ms infinite alternate;
}
.donations-gift__shape--two {
  top: -117px;
  right: 86px;
  animation: zumpXtwo 1500ms infinite alternate;
}
@media (max-width: 1799px) {
  .donations-gift__shape--two {
    top: -140px;
  }
}
@media (max-width: 767px) {
  .donations-gift__shape--two {
    top: -150px;
  }
}
@media (max-width: 575px) {
  .donations-gift__shape--two {
    right: 20px;
    top: -175px;
  }
}
@media (max-width: 412px) {
  .donations-gift__shape--two {
    right: 0px;
  }
}
.donations-gift__shape--two::after {
  top: 0;
  right: -37px;
  animation: zumpXtwo 1500ms infinite alternate;
}

.donations-three {
  position: relative;
}
.donations-three__top {
  margin-bottom: 65px;
}
.donations-three .sec-title {
  margin-bottom: 0;
}
.donations-three__carousel {
  position: relative;
}
.donations-three__carousel .owl-nav::before,
.donations-three__carousel .owl-nav::after {
  display: none;
}
@media (min-width: 1330px) {
  .donations-three__carousel .owl-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    margin-top: 0 !important;
    justify-content: space-between;
  }
  .donations-three__carousel .owl-nav .owl-prev,
  .donations-three__carousel .owl-nav .owl-next {
    position: relative;
  }
  .donations-three__carousel .owl-nav .owl-prev span,
  .donations-three__carousel .owl-nav .owl-next span {
    width: 60px;
    height: 60px;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0.7;
    background-color: transparent;
    color: var(--cleenhearts-white3, #d7d3cb);
    border: 1px solid var(--cleenhearts-white3, #d7d3cb);
  }
  .donations-three__carousel .owl-nav .owl-prev span:hover,
  .donations-three__carousel .owl-nav .owl-next span:hover {
    background-color: var(--cleenhearts-purple, #965995);
    color: var(--cleenhearts-white, #fff);
    border-color: var(--cleenhearts-purple, #965995);
  }
}
@media (min-width: 1330px) {
  .donations-three__carousel .owl-nav .owl-prev {
    left: -70.5px;
  }
}
@media (min-width: 1400px) {
  .donations-three__carousel .owl-nav .owl-prev {
    left: -105px;
  }
}
@media (min-width: 1330px) {
  .donations-three__carousel .owl-nav .owl-next {
    right: -70.5px;
  }
}
@media (min-width: 1400px) {
  .donations-three__carousel .owl-nav .owl-next {
    right: -105px;
  }
}
.donations-three__tabs-box {
  position: relative;
  display: block;
}
.donations-three__tabs-box .tab-buttons {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 34px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 12px;
}
@media (max-width: 991px) {
  .donations-three__tabs-box .tab-buttons {
    margin-top: 0;
    justify-content: flex-start;
  }
}
.donations-three__tabs-box .tab-buttons .tab-btn {
  cursor: pointer;
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  text-transform: capitalize;
  padding: 9px 9.5px;
  border-radius: 100px;
  display: inline-block;
  cursor: pointer;
  color: var(--cleenhearts-purple, #965995);
  border: 1px solid var(--cleenhearts-white2, #efece7);
  transition: all 400ms ease;
}
.donations-three__tabs-box .tab-buttons .tab-btn.active-btn,
.donations-three__tabs-box .tab-buttons .tab-btn:hover {
  color: var(--cleenhearts-white, #fff);
  background-color: var(--cleenhearts-purple, #965995);
  border-color: var(--cleenhearts-purple, #965995);
}
.donations-three__tabs-box .tabs-content {
  position: relative;
  display: block;
}
.donations-three__tabs-box .tabs-content .tab {
  position: relative;
  display: none;
  -webkit-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  transition: all 600ms ease;
  z-index: 10;
}
.donations-three__tabs-box .tabs-content .tab.active-tab {
  display: block;
}

/*--------------------------------------------------------------
# Donation Details
--------------------------------------------------------------*/
.donation-details .donation-card {
  padding: 0;
  background-color: var(--cleenhearts-white, #fff);
}
.donation-details .donation-card__image {
  margin-bottom: 15px;
}
.donation-details .donation-card__image::after,
.donation-details .donation-card__image::before {
  display: none;
}
.donation-details .donation-card:hover .donation-card__image img {
  transform: scale(1);
}
.donation-details__hall {
  padding: 10px;
  border-radius: 100px;
  position: absolute;
  top: 30px;
  left: 30px;
  background-color: var(--cleenhearts-secondary, #f6d469);
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  font-weight: 700;
  color: var(--cleenhearts-base, #351c42);
  text-transform: capitalize;
  line-height: 1;
  z-index: 11;
}
@media (max-width: 575px) {
  .donation-details__hall {
    top: 20px;
    left: 20px;
  }
}
@media (max-width: 425px) {
  .donation-details__hall {
    top: 15px;
    left: 15px;
  }
}
.donation-details__date {
  position: absolute;
  top: 30px;
  right: 30px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  font-size: 16px;
  color: var(--cleenhearts-base, #351c42);
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  background-color: var(--cleenhearts-white, #fff);
  gap: 4px;
  z-index: 11;
}
@media (max-width: 425px) {
  .donation-details__date {
    width: 54px;
    height: 54px;
    top: 15px;
    right: 15px;
  }
}
.donation-details__date span {
  color: inherit;
}
.donation-details .donation-card__progress {
  padding: 38px 30px 12px;
  background-color: transparent;
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  margin-bottom: 31px;
}
@media (max-width: 575px) {
  .donation-details .donation-card__progress {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.donation-details .donation-card__title {
  font-size: 24px;
  line-height: 1.541;
  letter-spacing: normal;
  margin-bottom: 18px;
}
@media (max-width: 575px) {
  .donation-details .donation-card__title {
    font-size: 20px;
  }
}
.donation-details .donation-card-three__text {
  margin-bottom: 0;
}
.donation-details .donation-card-three__text__inner:not(:first-child) {
  margin-top: 28px;
}
.donation-details__inner {
  margin-top: 49px;
}
@media (max-width: 575px) {
  .donation-details__inner {
    margin-top: 40px;
  }
}
.donation-details__inner__title {
  font-size: 24px;
  line-height: 1.541;
  margin-bottom: 30px;
  text-transform: capitalize;
}
@media (max-width: 575px) {
  .donation-details__inner__title {
    margin-bottom: 20px;
  }
}
.donation-details__inner__desc {
  text-transform: capitalize;
  margin-bottom: 26px;
}
.donation-details__inner__image img {
  width: 100%;
  display: block;
  border-radius: 20px;
}
.donation-details__inner__text {
  text-transform: capitalize;
  margin-top: 31px;
  margin-bottom: 26px;
}
.donation-details__inner__list {
  padding: 0;
  margin: 0;
  margin-left: 18px;
}
.donation-details__inner__list li + li {
  margin-top: 8.5px;
}
.donation-details__inner__list li p {
  text-transform: capitalize;
  margin-bottom: 0;
}
.donation-details__inner__bottom {
  margin-top: 40px;
  margin-bottom: 48px;
}
.donation-details__inner__bottom__text {
  margin-bottom: 0;
}
.donation-details__inner__bottom__text:not(:last-child) {
  margin-bottom: 29px;
}
.donation-details__donation {
  margin-top: 34px;
  padding: 41px 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  display: grid;
  grid-template-columns: auto auto 177px;
  align-items: center;
  gap: 40px 65px;
}
@media (max-width: 1199px) {
  .donation-details__donation {
    grid-template-columns: auto auto;
  }
}
@media (max-width: 767px) {
  .donation-details__donation {
    grid-template-columns: auto;
  }
}
@media (max-width: 575px) {
  .donation-details__donation {
    padding: 31px 30px;
  }
}
@media (max-width: 375px) {
  .donation-details__donation {
    padding: 26px 25px;
  }
}
.donation-details__donation__info {
  display: flex;
  align-items: center;
  gap: 20px;
}
.donation-details__donation__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: var(--cleenhearts-purple, #965995);
  border-radius: 10px;
  transition: all 400ms ease;
}
.donation-details__donation__icon span {
  font-size: 40px;
  color: var(--cleenhearts-white, #fff);
  transition: all 400ms ease;
}
.donation-details__donation__info:hover .donation-details__donation__icon {
  background-color: var(--cleenhearts-secondary, #f6d469);
}
.donation-details__donation__info:hover .donation-details__donation__icon span {
  color: var(--cleenhearts-base, #351c42);
}
.donation-details__donation__content {
  position: relative;
  top: 4px;
}
.donation-details__donation__title {
  font-size: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 4px;
}
.donation-details__donation__text {
  font-size: 24px;
  font-weight: 400;
  color: var(--cleenhearts-base, #351c42);
  line-height: 1.666;
  text-transform: capitalize;
  margin-bottom: 0;
}
.donation-details__donors {
  padding-top: 65px;
  padding-bottom: 66px;
  border-top: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  border-bottom: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
@media (max-width: 575px) {
  .donation-details__donors {
    padding-top: 55px;
    padding-bottom: 56px;
  }
}
.donation-details__donors__title {
  font-size: 24px;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 30px;
}
.donation-details__donors__single {
  text-align: center;
}
.donation-details__donors__image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 170px;
  height: 170px;
  border-radius: 50%;
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  margin-bottom: 21px;
  transition: all 400ms ease;
  margin-left: auto;
  margin-right: auto;
}
.donation-details__donors__image img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  transition: transform 400ms ease;
}
.donation-details__donors__single:hover .donation-details__donors__image {
  border-color: var(--cleenhearts-purple, #965995);
}
.donation-details__donors__single:hover .donation-details__donors__image img {
  transform: scale(1.1);
}
.donation-details__donors__name:hover {
  color: var(--cleenhearts-purple, #965995);
}
.donation-details__donors__name a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.donation-details__donors__name a:hover {
  background-size: 100% 1px;
}
.donation-details__donors__name,
.donation-details__donors__amount {
  font-size: 18px;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 10px;
}
.donation-details__donors__amount {
  color: var(--cleenhearts-purple, #965995);
  margin-bottom: 0;
}

.sidebar-donation__item {
  border-radius: 20px;
  background-color: var(--cleenhearts-white2, #efece7);
  padding: 30px;
}
@media (max-width: 1199px) {
  .sidebar-donation__item {
    padding: 30px 25px;
  }
}
.sidebar-donation__item + .sidebar-donation__item {
  margin-top: 30px;
}
.sidebar-donation__title {
  font-size: 20px;
  line-height: 1.3;
  margin-top: -3px;
  text-transform: capitalize;
  margin-bottom: 25px;
}
.sidebar-donation__organizer {
  padding-bottom: 24px;
}
.sidebar-donation__organizer__profile {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-bottom: 30px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--cleenhearts-border-color, #d7d3cb);
}
.sidebar-donation__organizer__image {
  flex-shrink: 0;
  width: 92px;
}
@media (max-width: 1199px) {
  .sidebar-donation__organizer__image {
    width: 75px;
  }
}
.sidebar-donation__organizer__image img {
  width: 92px;
  height: 92px;
  border-radius: 50%;
}
@media (max-width: 1199px) {
  .sidebar-donation__organizer__image img {
    width: 75px;
    height: 75px;
  }
}
.sidebar-donation__organizer__content {
  width: calc(100% - 92px);
}
@media (max-width: 1199px) {
  .sidebar-donation__organizer__content {
    width: calc(100% - 75px);
  }
}
.sidebar-donation__organizer__name {
  font-size: 18px;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 3px;
}
.sidebar-donation__organizer__name:hover {
  color: var(--cleenhearts-purple, #965995);
}
.sidebar-donation__organizer__name a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.sidebar-donation__organizer__name a:hover {
  background-size: 100% 1px;
}
.sidebar-donation__organizer__designation {
  font-size: 14px;
  font-weight: 500;
  color: var(--cleenhearts-purple, #965995);
  line-height: 16px;
  text-transform: capitalize;
}
.sidebar-donation__organizer__address {
  display: flex;
  align-items: center;
  gap: 10px;
}
.sidebar-donation__organizer__address__title {
  display: flex;
  align-items: center;
}
.sidebar-donation__organizer__address__icon {
  position: relative;
  top: 1px;
  font-size: 16px;
  color: var(--cleenhearts-purple, #965995);
  margin-right: 10px;
}
.sidebar-donation__organizer__address__text,
.sidebar-donation__organizer__address__address {
  font-size: 16px;
  font-weight: 700;
  color: var(--cleenhearts-base, #351c42);
  line-height: 1;
  text-transform: capitalize;
}
.sidebar-donation__organizer__address__address {
  font-weight: normal;
  margin-bottom: 0;
}
.sidebar-donation__campaings {
  padding: 29px 30px 29px;
}
@media (max-width: 1199px) {
  .sidebar-donation__campaings {
    padding-left: 25px;
    padding-right: 25px;
  }
}
.sidebar-donation__campaings__post {
  display: flex;
}
.sidebar-donation__campaings__post:not(:last-of-type) {
  margin-bottom: 30px;
}
.sidebar-donation__campaings__image {
  width: 80px;
  flex-shrink: 0;
  margin-right: 16px;
}
.sidebar-donation__campaings__image img {
  width: 100%;
  display: block;
  border-radius: 6px;
}
.sidebar-donation__campaings__meta {
  font-size: 14px;
  color: var(--cleenhearts-gray, #888888);
  font-weight: 500;
  line-height: 1.142;
  text-transform: capitalize;
  margin-bottom: 15px;
}
.sidebar-donation__campaings__content {
  width: calc(100% - 80px);
}
.sidebar-donation__campaings__title {
  margin: 0;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.555;
}
@media (max-width: 1199px) {
  .sidebar-donation__campaings__title {
    font-size: 15px;
  }
}
@media (max-width: 359px) {
  .sidebar-donation__campaings__title {
    font-size: 14px;
  }
}
.sidebar-donation__campaings__title a {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
}
.sidebar-donation__campaings__title a:hover {
  background-size: 100% 1px;
}

/*--------------------------------------------------------------
# Donate
--------------------------------------------------------------*/
.donate-page__notice {
  padding: 20.5px 21.37px;
  display: flex;
  align-items: center;
  background-color: var(--cleenhearts-white4, #fffaea);
  border-radius: 10px;
  margin-bottom: 30px;
}
.donate-page__notice i {
  font-size: 21.26;
  margin-right: 21.37px;
  color: #ffd54f;
}
.donate-page__notice__text {
  font-size: 16px;
  line-height: 1;
  text-transform: capitalize;
  margin-bottom: 0;
}
.donate-page__notice__text span {
  font-weight: normal;
}
.donate-page__form {
  padding: 29px;
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  border-radius: 10px;
}
@media (max-width: 425px) {
  .donate-page__form {
    padding: 25px;
  }
}
.donate-page__form__title {
  font-size: 24px;
  line-height: 1;
  letter-spacing: -0.72px;
  text-transform: capitalize;
  margin-bottom: 32px;
}
.donate-page__form__amount {
  margin-bottom: 65px;
}
.donate-page__form__amount__box {
  padding-left: 24px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: var(--cleenhearts-purple, #965995);
  height: 81px;
}
.donate-page__form__amount__sign {
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  font-size: 30px;
  color: var(--cleenhearts-white, #fff);
  font-weight: 700;
  line-height: 33px;
  letter-spacing: -0.9px;
  text-transform: capitalize;
}
.donate-page__form__amount__input,
.donate-page__form__amount__input::placeholder {
  width: calc(100% - 50px);
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  text-align: right;
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  font-size: 30px;
  color: var(--cleenhearts-white, #fff);
  font-weight: 700;
  line-height: 33px;
  letter-spacing: -0.9px;
  text-transform: capitalize;
}
.donate-page__form__amount__buttons {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.donate-page__form__amount__btn {
  min-width: 95px;
  text-align: center;
  padding: 18px 15px;
  border-radius: 10px;
  outline: 0;
  border: 0;
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  font-size: 24px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: -0.72px;
  text-transform: capitalize;
  background-color: var(--cleenhearts-white2, #efece7);
  transition: all 400ms ease;
}
.donate-page__form__amount__btn.active {
  color: var(--cleenhearts-white, #fff);
  background-color: var(--cleenhearts-purple, #965995);
}
@media (max-width: 1399px) {
  .donate-page__form__amount__btn {
    min-width: 94px;
    font-size: 22px;
  }
}
@media (max-width: 1199px) {
  .donate-page__form__amount__btn {
    font-size: 20px;
  }
}
@media (max-width: 767px) {
  .donate-page__form__amount__btn {
    min-width: 70px;
    font-size: 18px;
  }
}
.donate-page__form__amount__btn:hover {
  color: var(--cleenhearts-white, #fff);
  background-color: var(--cleenhearts-purple, #965995);
}
.donate-page__form__amount__btn:last-child {
  min-width: 185px;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.48px;
  text-transform: capitalize;
}
.donate-page__form__payment {
  margin-bottom: 36px;
}
.donate-page__form__payment__method {
  display: flex;
  align-items: center;
  gap: 20px 30px;
  flex-wrap: wrap;
}
.donate-page__form__info__title {
  margin-bottom: 34px;
}
.donate-page__form .form-one__control__input {
  border: 1px solid var(--cleenhearts-border-color, #d7d3cb);
  background-color: transparent;
  transition: all 0.4s ease-in-out;
}
.donate-page__form .form-one__control__input:focus {
  border-color: var(--cleenhearts-base, #351c42);
}
.donate-page__sidebar__item + .donate-page__sidebar__item {
  margin-top: 30px;
}

/*--------------------------------------------------------------
# Instagram
--------------------------------------------------------------*/
.instagram-one {
  position: relative;
  padding: 120px 0;
}
@media (max-width: 767px) {
  .instagram-one {
    padding: 80px 0;
  }
}
@media (min-width: 1400px) {
  .instagram-one .container {
    max-width: 1380px;
  }
}
.instagram-one__title {
  display: block;
  text-align: center;
  position: relative;
  font-size: 14px;
  color: var(--cleenhearts-text, #757277);
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  letter-spacing: 2.8px;
  margin: 0 auto 39px;
}
.instagram-one__title span {
  display: inline-block;
  background-color: var(--cleenhearts-white, #fff);
  position: relative;
  z-index: 2;
  padding: 0 28px;
}
@media (max-width: 550px) {
  .instagram-one__title span {
    padding: 0 18px;
  }
}
.instagram-one__title::after {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 9px;
  width: 518px;
  height: 1px;
  content: "";
  background-color: var(--cleenhearts-border-color, #d7d3cb);
}
@media (max-width: 550px) {
  .instagram-one__title::after {
    width: 100%;
  }
}
.instagram-one a {
  overflow: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
}
.instagram-one a::before {
  position: absolute;
  content: "\f16d";
  font-family: "Font Awesome 5 Brands";
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 55%;
  transform: translateY(-50%);
  z-index: 3;
  color: var(--cleenhearts-white, #fff);
  font-size: 30px;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
  transition-delay: 0s;
}
.instagram-one a::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  z-index: 1;
  opacity: 0;
  background: rgba(var(--cleenhearts-dark-rgb, 36, 16, 47), 0.5);
  visibility: hidden;
  transition: all ease 0.4s;
}
.instagram-one a:hover::after {
  height: 100%;
  opacity: 1;
  visibility: visible;
}
.instagram-one a:hover::before {
  top: 50%;
  visibility: visible;
  opacity: 1;
  transition-delay: 0.4s;
}
.instagram-one a img {
  width: 100%;
  height: auto;
  transition: all ease 0.3s;
}
.instagram-one a:hover img {
  transform: scale(1.1) rotate(1.5deg);
  -moz-transform: scale(1.1) rotate(1.5deg);
  -webkit-transform: scale(1.1) rotate(1.5deg);
  -ms-transform: scale(1.1) rotate(1.5deg);
  -o-transform: scale(1.1) rotate(1.5deg);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.instagram-two {
  position: relative;
  z-index: 2;
  padding: 0 0;
  margin-bottom: -120px;
}
.instagram-two .container {
  max-width: 1670px;
}
.instagram-two a {
  overflow: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
}
.instagram-two a::before {
  position: absolute;
  content: "\f16d";
  font-family: "Font Awesome 5 Brands";
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 55%;
  transform: translateY(-50%);
  z-index: 3;
  color: var(--cleenhearts-white, #fff);
  font-size: 40px;
  z-index: 2;
  opacity: 0;
  visibility: hidden;
  transition: all ease 0.4s;
  transition-delay: 0s;
}
.instagram-two a::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  z-index: 1;
  opacity: 0;
  background: rgba(var(--cleenhearts-base-rgb, 53, 28, 66), 0.9);
  visibility: hidden;
  transition: all ease 0.4s;
}
.instagram-two a:hover::after {
  height: 100%;
  opacity: 1;
  visibility: visible;
}
.instagram-two a:hover::before {
  top: 50%;
  visibility: visible;
  opacity: 1;
  transition-delay: 0.4s;
}
.instagram-two a img {
  width: 100%;
  height: auto;
  transition: all ease 0.3s;
}
.instagram-two a:hover img {
  transform: scale(1.1) rotate(1.5deg);
  -moz-transform: scale(1.1) rotate(1.5deg);
  -webkit-transform: scale(1.1) rotate(1.5deg);
  -ms-transform: scale(1.1) rotate(1.5deg);
  -o-transform: scale(1.1) rotate(1.5deg);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

/*--------------------------------------------------------------
# Opening Time
--------------------------------------------------------------*/
.opening {
  position: relative;
  margin-bottom: -85px;
}
.opening--home-three {
  margin-bottom: 0;
  padding-bottom: 120px;
}
@media (max-width: 767px) {
  .opening--home-three {
    padding-bottom: 80px;
  }
}
.opening__wrapper {
  background-color: var(--cleenhearts-base, #351c42);
  position: relative;
  z-index: 2;
  padding: 39px 0;
}
@media (max-width: 767px) {
  .opening__wrapper {
    padding: 30px 20px;
    text-align: center;
  }
}
.opening__wrapper::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 180px;
  height: 100%;
  content: "";
  opacity: 0.102;
  background-image: linear-gradient(
    90deg,
    rgba(var(--cleenhearts-dark-rgb, 36, 16, 47), 0) 0%,
    rgba(var(--cleenhearts-dark-rgb, 36, 16, 47), 1) 100%
  );
}
@media (max-width: 991px) {
  .opening__wrapper::after {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .opening__wrapper::after {
    width: 155px;
  }
}
.opening__icon {
  font-size: 65px;
  color: var(--cleenhearts-white, #fff);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 0 0;
  margin-right: -10px;
  position: relative;
  z-index: 2;
}
.opening__title {
  color: var(--cleenhearts-white, #fff);
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 700;
  margin: 27px -30px 0 33px;
  position: relative;
}
@media (max-width: 767px) {
  .opening__title {
    margin: 20px 0 0;
  }
}
.opening__info {
  font-size: 12px;
  line-height: 30px;
  color: var(--cleenhearts-white, #fff);
  text-transform: uppercase;
  padding: 12px 0 20px;
  margin: 0 0 0 -14px;
}
@media (max-width: 991px) {
  .opening__info {
    margin: 0 0 0;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .opening__info {
    margin: 0 0 0;
  }
}
.opening__info--last {
  margin-left: -45px;
  padding-left: 60px;
  border-left: 1px solid rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0.2);
}
@media (max-width: 991px) {
  .opening__info--last {
    margin: 0 0 0;
    padding: 0;
    border: none;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .opening__info--last {
    margin-left: -30px;
    padding-left: 20px;
  }
}
.opening__info__text {
  display: block;
  font-size: 20px;
  text-transform: none;
}

/*--------------------------------------------------------------
# Boxed Home
--------------------------------------------------------------*/
body.boxed-wrapper {
  position: relative;
}
body.boxed-wrapper .page-wrapper {
  max-width: 1530px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--cleenhearts-white, #fff);
  box-shadow: 0px 0px 100px 0px
    rgba(var(--cleenhearts-dark-rgb, 36, 16, 47), 0.08);
}
body.boxed-wrapper .main-header__btn {
  display: none;
}
body.boxed-wrapper .main-header__cart {
  margin-right: 0;
}

/*--------------------------------------------------------------
# Work Process
--------------------------------------------------------------*/
.work-process-one {
  position: relative;
  counter-reset: count;
  padding: 120px 0 90px;
}
@media (max-width: 767px) {
  .work-process-one {
    padding: 80px 0 50px;
  }
}
.work-process-one .sec-title {
  text-align: center;
}
.work-process-one__border {
  width: 100%;
  height: 2px;
  background-color: var(--cleenhearts-base, #351c42);
  top: 103px;
  position: relative;
}
@media (max-width: 767px) {
  .work-process-one__border {
    display: none;
  }
}
.work-process-one__border::after {
  position: absolute;
  left: 0;
  top: -5px;
  width: 11px;
  height: 11px;
  background-color: var(--cleenhearts-dark, #24102f);
  border-radius: 50%;
  content: "";
}
.work-process-one__border::before {
  position: absolute;
  right: 0;
  top: -5px;
  width: 11px;
  height: 11px;
  background-color: var(--cleenhearts-dark, #24102f);
  border-radius: 50%;
  content: "";
}
.work-process-one__gradiant-left {
  height: 100%;
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 5%;
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(
      -45deg,
      rgba(var(--cleenhearts-base-rgb, 53, 28, 66), 1) 0%,
      rgba(var(--cleenhearts-dark-rgb, 36, 16, 47), 1) 100%
    )
    1;
}
.work-process-one__gradiant-right {
  height: 100%;
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  width: 5%;
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(
      -45deg,
      rgba(var(--cleenhearts-dark-rgb, 36, 16, 47), 1) 0%,
      rgba(var(--cleenhearts-base-rgb, 53, 28, 66), 1) 100%
    )
    1;
}
.work-process-one__item {
  position: relative;
  counter-increment: count;
  margin-bottom: 30px;
}
.work-process-one__item__thumb {
  display: inline-block;
  width: 202px;
  height: 202px;
  border: 2px solid var(--cleenhearts-base, #351c42);
  background-color: var(--cleenhearts-white, #fff);
  border-radius: 50%;
  padding: 5px;
  position: relative;
  margin-bottom: 30px;
}
.work-process-one__item__thumb-wrap {
  background-color: var(--cleenhearts-dark, #24102f);
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}
.work-process-one__item__thumb-wrap::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 0;
  height: 0;
  background: rgba(var(--cleenhearts-white-rgb, 255, 255, 255), 0.2);
  border-radius: 50%;
  transition: all 500ms linear;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
  z-index: 2;
}
.work-process-one__item__thumb-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  opacity: 0.3;
}
.work-process-one__item__thumb__number {
  width: 67px;
  height: 67px;
  background-color: var(--cleenhearts-base, #351c42);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--cleenhearts-white, #fff);
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  font-size: 30px;
  font-weight: 700;
  line-height: 1;
  position: absolute;
  right: -9px;
  top: 5px;
  transition: all 500ms linear;
  transition-delay: 0s;
  transition-delay: 0s;
  transition-delay: 0.1s;
  transform: scale(1);
}
.work-process-one__item__thumb__number::before {
  content: counters(count, ".", decimal-leading-zero);
  position: absolute;
  top: -6px;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.work-process-one__item__thumb__icon {
  width: 60px;
  height: 60px;
  font-size: 60px;
  color: var(--cleenhearts-base, #351c42);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.work-process-one__item:hover .work-process-one__item__thumb-wrap::after {
  -webkit-animation: zoom-hover 0.95s;
  animation: zoom-hover 0.95s;
}
.work-process-one__item:hover .work-process-one__item__thumb__number {
  transform: scale(0.95);
}
.work-process-one__item__content {
  position: relative;
  box-shadow: 0px 0px 60px 0px
    rgba(var(--cleenhearts-dark3-rgb, 32, 12, 43), 0.07);
  background-color: var(--cleenhearts-white, #fff);
  border-radius: 100px;
  padding: 24px 20px 26px;
}
.work-process-one__item__content::after {
  position: absolute;
  left: 0;
  right: 0;
  top: -10px;
  content: "";
  margin: auto;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 25px 10px 25px;
  border-color: transparent transparent var(--cleenhearts-white, #fff)
    transparent;
}
.work-process-one__item__title {
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 5px;
}
.work-process-one__item__text {
  font-size: 15px;
  line-height: 26px;
  margin: 0;
}

/*--------------------------------------------------------------
# Events Gallery
--------------------------------------------------------------*/
.events-gallery .container-fluid {
  padding: 0;
}
.events-gallery__grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10;
}
@media (max-width: 1399px) {
  .events-gallery__grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 767px) {
  .events-gallery__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 575px) {
  .events-gallery__grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.events-gallery__image {
  overflow: hidden;
  position: relative;
}
.events-gallery__image::after {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background-color: rgba(var(--cleenhearts-dark2-rgb, 32, 12, 42), 0.7);
  transition: all 700ms ease;
  transform: translateY(-110%);
  z-index: 1;
}
.events-gallery__image__img {
  display: block;
  width: 100%;
}
.events-gallery__item:hover .events-gallery__image::after {
  transform: translateY(0);
}
.events-gallery__link {
  width: calc(100% - 97px);
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 200%);
  padding: 14px;
  background-color: var(--cleenhearts-white, #fff);
  border-radius: 100px;
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  font-size: 16px;
  color: var(--cleenhearts-base, #351c42);
  font-weight: 600;
  line-height: 1;
  text-transform: capitalize;
  transition: all 600ms ease;
  opacity: 0;
  visibility: hidden;
  z-index: 11;
}
@media (max-width: 1799px) {
  .events-gallery__link {
    width: calc(100% - 50px);
  }
}
@media (max-width: 1599px) {
  .events-gallery__link {
    width: calc(100% - 40px);
  }
}
.events-gallery__link:hover {
  color: var(--cleenhearts-purple, #965995);
}
.events-gallery__link img {
  width: 40px !important;
  height: 40px;
}
.events-gallery__link span {
  color: inherit;
  background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px
    95%/0px 1px no-repeat;
  transition: all 500ms ease;
  padding-bottom: 3px;
  margin-bottom: -2px;
}
.events-gallery__link span:hover {
  background-size: 100% 1px;
}
.events-gallery__item:hover .events-gallery__link {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -50%);
}

/*--------------------------------------------------------------
# Subscribe
--------------------------------------------------------------*/
.subscribe {
  overflow: hidden;
  padding-top: 66px;
  padding-bottom: 65px;
  position: relative;
  background-color: var(--cleenhearts-base, #351c42);
  z-index: 1;
}
@media (max-width: 991px) {
  .subscribe {
    padding-top: 61px;
  }
}
@media (max-width: 575px) {
  .subscribe {
    padding-top: 56px;
  }
}
.subscribe__bg {
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
.subscribe__bg::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    RGBA(var(--cleenhearts-base-rgb, 53, 28, 66), 0.8) 0%,
    RGBA(var(--cleenhearts-base-rgb, 53, 28, 66), 0.8) 100%
  );
  z-index: 1;
}
.subscribe .container {
  position: relative;
  z-index: 11;
}
.subscribe .row {
  align-items: center;
}
.subscribe__content {
  display: flex;
  align-items: center;
}
@media (max-width: 991px) {
  .subscribe__content {
    justify-content: center;
    margin-bottom: 30px;
  }
}
.subscribe__title-image {
  margin-right: 26px;
  font-size: 60px;
  color: var(--cleenhearts-white, #fff);
}
@media (max-width: 575px) {
  .subscribe__title-image {
    margin-right: 15px;
    font-size: 35px;
  }
}
.subscribe__title {
  font-family: var(--cleenhearts-heading-font, "Quicksand", sans-serif);
  color: var(--cleenhearts-white, #fff);
  font-weight: 700;
  font-size: 40px;
  line-height: 1.5;
  letter-spacing: -1.2px;
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .subscribe__title {
    font-size: 35px;
  }
}
.subscribe__form {
  display: flex;
  align-items: center;
}
@media (max-width: 500px) {
  .subscribe__form {
    flex-direction: column;
  }
}
.subscribe__form__input {
  padding: 0;
  border: none;
  outline: none;
  padding-left: 32px;
  width: calc(100% - 193px);
  height: 64px;
  border-radius: 100px 0 0 100px;
  background-color: transparent;
  border-left: 1px solid
    rgba(var(--cleenhearts-secondary-rgb, 246, 212, 105), 0.5);
  border-top: 1px solid
    rgba(var(--cleenhearts-secondary-rgb, 246, 212, 105), 0.5);
  border-bottom: 1px solid
    rgba(var(--cleenhearts-secondary-rgb, 246, 212, 105), 0.5);
  color: var(--cleenhearts-border-color, #d7d3cb);
  transition: all 0.4s ease-in-out;
}
@media (max-width: 500px) {
  .subscribe__form__input {
    width: 100%;
    border-radius: 100px;
  }
}
.subscribe__form__input::placeholder {
  color: var(--cleenhearts-white3, #d7d3cb);
}
.subscribe__form__input:focus {
  border-color: rgba(var(--cleenhearts-secondary-rgb, 246, 212, 105), 1);
}
.subscribe__form__btn {
  overflow: hidden;
  position: relative;
  z-index: 0;
  padding: 0;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 195px;
  height: 64px;
  border-radius: 0 100px 100px 0;
  background-color: var(--cleenhearts-secondary, #f6d469);
  color: var(--cleenhearts-base, #351c42);
  font-family: var(--cleenhearts-font, "DM Sans", sans-serif);
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  text-transform: capitalize;
  transition: all 0.4s ease-in-out;
}
.subscribe__form__btn:hover {
  background-color: var(--cleenhearts-white, #fff);
}
@media (max-width: 575px) {
  .subscribe__form__btn {
    font-size: 15px;
  }
}
@media (max-width: 500px) {
  .subscribe__form__btn {
    margin-top: 20px;
    width: 100%;
    border-radius: 100px;
  }
}
.subscribe__form__btn__text {
  position: relative;
  color: inherit;
  z-index: 1;
  transition: inherit;
}
.subscribe__form__btn__icon {
  position: relative;
  margin-left: 16px;
  font-size: 24px;
  color: inherit;
  z-index: 1;
  transition: inherit;
}
@media (max-width: 575px) {
  .subscribe__form__btn__icon {
    font-size: 18px;
  }
}
.subscribe__shape__one {
  position: relative;
  position: absolute;
  top: -75px;
  left: 54px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: var(--cleenhearts-secondary, #f6d469);
  z-index: 1;
  opacity: 0.3;
  animation: slowXmove 6s ease infinite;
}
.subscribe__shape__one::after {
  content: "";
  position: absolute;
  top: 0;
  left: 23px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 3px solid var(--cleenhearts-secondary, #f6d469);
  z-index: 1;
  opacity: 0.3;
  animation: slowXmove 6s ease infinite;
}
.subscribe__shape__two {
  position: relative;
  position: absolute;
  right: 42px;
  bottom: -141px;
  width: 282px;
  height: 282px;
  border-radius: 50%;
  background-color: var(--cleenhearts-gray, #888888);
  z-index: 1;
  opacity: 0.3;
  animation: zumpX 1500ms infinite alternate;
}
.subscribe__shape__two::after {
  content: "";
  position: absolute;
  top: 0;
  right: 44px;
  width: 282px;
  height: 282px;
  border-radius: 50%;
  border: 3px solid var(--cleenhearts-gray, #888888);
  z-index: 1;
  opacity: 0.3;
  animation: zumpX 1500ms infinite alternate;
}
